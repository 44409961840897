import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { DateTime } from 'luxon';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        backend: {
            loadPath: 'https://bjj.webstaginghub.com/api/translation/{{lng}}',
            parse: (data) => {
                const parsedData = JSON.parse(data);

                if (parsedData.success) {
                    return parsedData.data;
                }
                throw new Error('Failed to load translations from API');
            }
        },
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (value instanceof Date) {
                    return DateTime.fromJSDate(value).setLocale(lng)
                        .toLocaleString(DateTime[format]);
                }

                return value;
            }
        }
    });

export default i18n;
