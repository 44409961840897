import { createSlice } from "@reduxjs/toolkit";
import { getPaymentsCardFetch } from "../thunks/paymentsThunks";
const initialState = {
    cards: [],
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All
            .addCase(getPaymentsCardFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.cards = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getPaymentsCardFetch.pending, (state, action) => {
                state.isLoading = true;
                state.cards = [];
                state.pagination = {};
            })
            .addCase(getPaymentsCardFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.cards = [];
                state.pagination = {};
            })
    }
});

export const { } = paymentsSlice.actions;
export default paymentsSlice.reducer;
