import React, { useState } from "react";
import ModalTemplate from "../ModalTemplate";
import Button from "../../ui/buttons/Button";
import InputRadio from "../../ui/formElements/InputRadio";
import {
    Link, useLocation, useNavigate
} from "react-router-dom";
import CheckboxEl from "../../ui/formElements/CheckboxEl";
import { toast } from "react-toastify";

const SingUpModal = ({ open, handleClose, loginHandler }) => {
    const [formSelectetdValue, setFormSelectetdValue] = useState({ userType: "instructor" });
    const { search } = useLocation()

    const userTypesData = [
        {
            name: "userType",
            id: "instructor",
            value: "instructor",
            label: "I’m an Instructor",
            image: "poster-instructor.png"
        }, {
            name: "userType",
            id: "academy",
            value: "academy",
            label: "I’m an Academy",
            image: "poster-academy.png"
        }, {
            name: "userType",
            id: "student",
            value: "student",
            label: "I’m a Student",
            image: "poster-student.png"
        }
    ];

    const inputHandler = (event) => {
        const { name, value } = event.target;

        setFormSelectetdValue((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const navigate = useNavigate();

    const navigatePrivacy = () => {
        // handleClose();
        // navigate("/privacy-policy"); 
        const href = window.location.origin + "/privacy-policy";

        window.open(href);
    };

    const [agree, setAgree] = useState();

    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__header">
                <div className="logo">
                    <img
                        alt="logo"
                        src={require("../../../assets/img/logo-black.svg").default}
                    />
                </div>
            </div>

            <div className="modal__body">
                <form
                    className="form form--lg"
                    method="post"
                >
                    <div className="form-header">
                        <div className="heading-block">
                            <h3 className="heading">
                                How can we call you?
                            </h3>

                            <p className="text--md desk-heading">
                                Select one of options below to create account
                            </p>
                        </div>
                    </div>

                    <div className="form-body">
                        <div className="row gutters-form">
                            <div className="col-12">
                                <ul className="sign-list">
                                    {userTypesData.map((item, idx) => (
                                        <li
                                            className="sign-list--item"
                                            key={idx}
                                        >
                                            <InputRadio
                                                checked={item.value === formSelectetdValue.userType}
                                                id={item.id}
                                                name={item.name}
                                                onChange={inputHandler}
                                                value={item.value}
                                            >
                                                <div className="btn btn btn--xl btn-border justify-start text-left">
                                                    <div className="poster-check">
                                                        <img
                                                            src={require(`../../../assets/img/${item.image}`)}
                                                            alt="poster"
                                                        />
                                                    </div>

                                                    <div className="check-rounded" />

                                                    <p className="text--lg poster-text">
                                                        {item.label}
                                                    </p>
                                                </div>
                                            </InputRadio>
                                        </li>
                                    )



                                    )}
                                </ul>
                            </div>

                            <div className="flex">
                                <CheckboxEl
                                    checked={agree}
                                    onChange={(e) => setAgree(!agree)}
                                    wrapperClasses="mr-20"
                                />

                                <div className="flex flex-wrap">
                                    <span>
                                        I agree with
                                    </span>

                                    &nbsp;
                                    <button
                                        className="btn btn-link"
                                        onClick={navigatePrivacy}
                                        type="button"
                                    >
                                        {" "}

                                        Privacy and Cookies Policy
                                    </button>
                                </div>
                            </div>

                            <div className="col-12">
                                <button
                                    className="btn w-full btn--primary btn--lg radius"
                                    onClick={() => {
                                        if (!agree) {
                                            toast.error("You must agree with Privacy and Cookies Policy");

                                            return;
                                        }

                                        navigate(`/${formSelectetdValue.userType}-sing-up${search}`);
                                    }}
                                    type="button"
                                >
                                    <span>
                                        Create an Account
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="row gutters-form">
                            <div className="col-12 text-center">
                                <p className="text--md">
                                    Already have an account?
                                    &nbsp;
                                    <Button
                                        className="color-secondary btn-link"
                                        onClick={loginHandler}
                                    >
                                        <span className="--fontFamily text-normal--md">
                                            Login
                                        </span>
                                    </Button>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    );
};

export default SingUpModal;
