const base = "api/notifications";

export const notifications = (instance) => ({
    async getAllNotifications(query = "") {
        const { data } = await instance.get(`${base}?${query}`);

        return data;
    },
    async readNotifications({ body, readUnreadedAll = false }) {
        const { data } = await instance.post(`${base}/read?${readUnreadedAll ? 'readUnreadedAll=true' : ''}`, body);

        return data;
    },
    async reviewNotifications({ body, reviewAll = false }) {
        const { data } = await instance.post(`${base}/review?${reviewAll ? 'all=true' : ''}`, body);
        return data;
    },
    async deleteNotification(id) {
        const { data } = await instance.delete(`${base}/${id}`);

        return data;
    }
});
