import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
    return (
        <section className="empty-section">
            <div className="container">
                <div className="empty-section-body">
                    <div className="empty-section-body__title">
                        <h1 className="heading mb-16">
                            404
                        </h1>

                        <p>
                            Page Not Found
                        </p>
                    </div>

                    <div className="row-group gap--md justify-between">
                        <Link
                            className="btn btn--primary btn--sm"
                            to="/"
                        >
                            <span className="info">
                                Go back to Home
                            </span>

                            <span className="ico">
                                <svg
                                    fill="none"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    width="14"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.25 2.625L9.625 7L5.25 11.375"
                                        stroke="#02020A"
                                        strokeLinecap="square"
                                        strokeWidth="1.6"
                                    />
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NotFoundPage;
