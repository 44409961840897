import { createSlice } from "@reduxjs/toolkit";
import { getMyContractsFetch } from "../thunks/contractsThunks";
const initialState = {
    contracts: [],
    isLoading: false
};
const contractsSlice = createSlice({
    name: "contracts",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMyContractsFetch.fulfilled, (state, { payload }) => {
                state.contracts = payload?.data;
                state.isLoading = false;
            })
            .addCase(getMyContractsFetch.pending, (state, { payload }) => {
                state.contracts = [];
                state.isLoading = true;
            })
            .addCase(getMyContractsFetch.rejected, (state, { payload }) => {
                state.contracts = [];
                state.isLoading = false;
            });
    }
});

export const {} = contractsSlice.actions;
export const getMyContractsSlice = (state) => state.contracts.contracts;
export const getContractsIsLoading = (state) => state.contracts.isLoading;
export default contractsSlice.reducer;
