import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SingUpModal from '../../components/modals/singUpModals/SingUpModal'
import { setToken } from '../../redux/slices/applicationSlice'
import { getUserFetch } from '../../redux/thunks/applicationThunks'
import HomePage from '../HomePage'

const OAuthSignUp = () => {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const dispatch = useDispatch()

    useEffect(() => {
        const authToken = params.get("authToken")

        if (authToken) {
            localStorage.setItem("authToken", authToken)
            dispatch(setToken(authToken));
            dispatch(getUserFetch(authToken));
            navigate("/")
            setTimeout(() => {
                window.location.reload();
            }, 200);
        }
    }, [params])

    return (
        <>
            <HomePage />
            <SingUpModal open={true} handleClose={() => navigate("/")} />
        </>
    )
}

export default OAuthSignUp