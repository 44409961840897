import React from "react";

const InputRadio = ({
    checked, children, value, id, name, onChange
}) => {
    return (
        <label className="check-btn">
            <input
                checked={checked}
                hidden={true}
                id={id}
                name={name}
                onChange={onChange}
                type="radio"
                value={value}
            />

            {children}
        </label>
    );
};

export default InputRadio;
