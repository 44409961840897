import React, {
    useEffect, useRef, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { getImageSrc } from "../../helpers/utils";
import useClickOutside from "../../hooks/useClickOutside";
import useDebounce from "../../hooks/useDebaunce";
import { findInstructorsFetch } from "../../redux/thunks/instructorsThunks";
import ModalTemplate from "./ModalTemplate";
import { getUser } from "../../redux/slices/applicationSlice";
import Button from "../ui/buttons/Button";

const InviteInstructorModal = ({ open, handleClose, onSubmit, data = [] }) => {
    const dispatch = useDispatch();
    const [q, setQ] = useState("");
    const debouncedValue = useDebounce(q);
    const [instructors, setInstructors] = useState([]);
    const [foundedInstructors, setFoundedInstructors] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropRef = useRef();
    const user = useSelector(getUser);

    const handleInputChange = (e) => {
        setQ(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setInstructors([...instructors, ...foundedInstructors]);
        setQ("");
    };

    const handleRemoveInstructor = (index) => {
        const updatedInstructors = instructors.filter((_, i) => i !== index);

        setInstructors(updatedInstructors);
    };

    const handleSubmit = () => {
        onSubmit && onSubmit([...data, ...instructors]);
    };

    const findInstructor = async () => {
        try {
            const res = await dispatch(findInstructorsFetch(debouncedValue)).unwrap();
            console.log("res", res);

            // setFoundedInstructors(res.filter((item) => !data.concat(instructors, user?.profile).find((data) => data.id === item.id)));
            setFoundedInstructors(res);
            setShowDropdown(true);
        } catch (error) {
            console.error("Error finding instructor:", error);
        }
    };

    const handleSelectInstructor = (instructor) => {
        if (!instructors.find((item) => item.id === instructor.id)) {
            setInstructors([...instructors, instructor]);
        }

        setShowDropdown(false);
        setQ("");
    };

    useClickOutside(() => {
        setShowDropdown(false);
    }, dropRef);
    useEffect(() => {
        if (debouncedValue.length >= 1) {
            findInstructor();
        } else {
            setShowDropdown(false);
        }
    }, [debouncedValue]);

    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body">
                <form
                    className="form form--lg"
                    method="post"
                    onSubmit={handleFormSubmit}
                >
                    <div className="form-header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Invite instructors
                            </h3>
                        </div>
                    </div>

                    <div className="form-body">
                        <div className="row gy-32">
                            <div className="col-12">
                                <div className="flex items-center relative  gap-8">
                                    <div className="form-group input--lg w-full">
                                        <input
                                            className="input input--solid"
                                            onChange={handleInputChange}
                                            placeholder="Type instructors email or name..."
                                            type="text"
                                            value={q}
                                        />

                                        <div
                                            className={`dropdown__body dropdown-menu ${showDropdown
                                                ? "is-open"
                                                : ""}`}
                                            ref={dropRef}
                                            style={{
                                                top: 50,
                                                position: "absolute",
                                                width: "100%"
                                            }}
                                        >
                                            {
                                                foundedInstructors.length
                                                    ? (
                                                        <ul className="invite-instructors-list">
                                                            {foundedInstructors.map((instructor) => (
                                                                <li
                                                                    key={instructor.id}
                                                                    onClick={() => handleSelectInstructor(instructor)}
                                                                    className="invite-instructor__item"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <div className="invite-instructor__img">
                                                                        <img
                                                                            src={getImageSrc(instructor.photo)}
                                                                            alt={`${instructor.fullName}'s profile`}
                                                                        />
                                                                    </div>

                                                                    <div className="invite-instructor__group-info">
                                                                        <span className="invite-instructor__name">
                                                                            {instructor.fullName}
                                                                        </span>

                                                                        <span className="invite-instructor__email color-grey fs-14">
                                                                            {instructor?.user?.email}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            )



                                                            )}
                                                        </ul>
                                                    )


                                                    : (
                                                        <p className="text-center text-14 color-grey">
                                                            Not found
                                                        </p>
                                                    )


                                            }
                                        </div>
                                    </div>

                                    {/* <button type="submit" className="btn btn--primary btn--lg radius w-98">
                                        <span>Invite</span>
                                    </button> */}
                                </div>
                            </div>

                            <div className="col-12">
                                <ul className="invite-instructors-list mb-32">
                                    {instructors.map((instructor, index) => (
                                        <li
                                            key={index}
                                            className="invite-instructor__item"
                                        >
                                            <div className="invite-instructor__img">
                                                <img
                                                    src={getImageSrc(instructor.photo)}
                                                    alt={`${instructor.fullName}'s profile`}
                                                />
                                            </div>

                                            <div className="invite-instructor__group-info">
                                                <span className="invite-instructor__name">
                                                    {instructor.fullName}
                                                </span>

                                                <span className="invite-instructor__email color-grey fs-14">
                                                    {instructor?.user.email}
                                                </span>
                                            </div>

                                            <Button
                                                onClick={() => handleRemoveInstructor(index)}
                                                className="btn"
                                            >
                                                <svg
                                                    width="24"
                                                    height="25"
                                                    viewBox="0 0 24 25"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g>
                                                        <path
                                                            d="M18.75 5.75L5.25 19.25"
                                                            stroke="#BDBDBD"
                                                            strokeWidth="1.2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                        </path>

                                                        <path
                                                            d="M18.75 19.25L5.25 5.75"
                                                            stroke="#BDBDBD"
                                                            strokeWidth="1.2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                        </path>
                                                    </g>

                                                    <defs>
                                                        <clipPath>
                                                            <rect
                                                                width="24"
                                                                height="24"
                                                                fill="white"
                                                                transform="translate(0 0.5)"
                                                            >
                                                            </rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </Button>
                                        </li>
                                    )



                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="flex-group justify-end mt-auto">
                    <button
                        className="btn btn--sm"
                        data-close-modal=""
                        onClick={handleClose}
                    >
                        <span>
                            Cancel
                        </span>
                    </button>

                    <button
                        className="btn btn--sm btn--primary"
                        onClick={handleSubmit}
                        type="button"
                    >
                        <span>
                            Submit
                        </span>
                    </button>
                </div>
            </div >
        </ModalTemplate >
    );
};

export default InviteInstructorModal;
