import { Country } from "country-state-city";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import { continents } from "../../../constants";
import { EVENT_STEP, USER_ROLE } from "../../../constants/enums";
import { explanations } from "../../../constants/explanations";
import { getFullLocation } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import ButtonsGroup from "../parts/ButtonsGroup";
import DateRangeSelect from "../parts/DateRangeSelect";

import SelectMulty from "../../../components/ui/formElements/SelectMulty";

const TimeAndDate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tourId } = useParams();

    const user = useSelector(getUser);

    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();

    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [editedFields, setEditedFields] = useState([]);

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);
        console.log(formData);

        if (!errors.hasRequiredError) {
            navigate("seminar-info");
        }
    };

    useEffect(() => {
        setErrorConfig({
            exceptions: [
            ],
            requiredFields: [...user?.role === USER_ROLE.academy
                ? ["address", "location"]
                : [], ...user?.role === USER_ROLE.instructor
                    ? ["continent"]
                    : []
            ],
            validators: {
                availableDates: (value) => {
                    if (!value.length) return false;

                    let isValid = true;

                    value.forEach((item) => {
                        if (!item.date) {
                            isValid = false;
                        }
                    });

                    return isValid;
                }
            }
        });

    }, [formData, user]);

    const handleStepChange = (name, value) => {
        console.log(name, value);
        
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    useEffect(() => {
        const location = getFullLocation([
            Country.getCountryByCode(formData.country)?.name,
            formData.city,
            formData.address
        ]);
        handlePreviewChange("location", location);
    }, [formData])



    const optionAll = [
        {
            label: "All",
            value: "all"
        }
    ];

    const continentOptions = continents.map(item => ({
        label: item.continent,
        value: item.continent
    }))

    const countryOptions = formData.continent === "all"
        ? Country.getAllCountries().map(item => ({
            label: item.name,
            value: item.isoCode
        }))
        : continents
            ?.find(item => item.continent === formData.continent)?.countries
            ?.map(({ code, name }) => ({
                label: name,
                value: code
            })) || []


    return (
        <>
            <div className="row-group mb-36">
                <h6 className="heading">
                    SET DATE INTERVAL
                </h6>
                <TooltipInfo content={explanations.dateInterval} />
            </div>
            <DateRangeSelect
                handleChange={handleStepChange}
                errors={errors}
                isErrorsShown={isErrorsShown}
                startAt={formData.startAt}
                endAt={formData.endAt}
                minRange={7}

            />
            <hr className="hr mb-42" />

            <div className="row-group mb-36">
                <h6 className="heading">
                    SET YOUR LOCATION AVAILABILITY
                </h6>
            </div>
            <div className="row gx-16 gy-16 py-24 mb-10">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <SelectOne
                                error={isErrorsShown && errors?.continent}
                                inputClasses="input--solid"
                                label="Continent"
                                onChange={(option) => handleStepChange("continent", option?.value)}
                                options={[...optionAll, ...continentOptions]}
                                placeholder="Enter location here..."
                                value={formData?.continent}
                                wrapperClasses="select--outline select--outline-bg input--lg w-full"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group input--lg w-full">
                        <div className="label-row">
                            <label className="label">COUNTRY/STATE (OPTIONAL)</label>
                        </div>
                        <p className="text-14 color-text">You'll only receive requests from academies in the selected countries</p>
                        <div className="row">
                            <div className="col-12">
                                <SelectMulty
                                    disabled={!formData?.continent}
                                    error={isErrorsShown && errors?.countries}
                                    inputClasses="input--solid"
                                    onChange={(option) => handleStepChange("countries", option.map(item => item.value))}
                                    options={countryOptions}
                                    placeholder="Enter location here..."
                                    value={formData?.countries}
                                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr mb-42 mt-42" />

            <ButtonsGroup
                onSubmit={handleSubmit}
                eventId={tourId}
                step={EVENT_STEP.date}
            />
        </>
    );
};

export default TimeAndDate;
