import { configureStore } from "@reduxjs/toolkit";
import applicationSlice from "./slices/applicationSlice";
import academyGeneralInfoSlice from "./slices/academyGeneralInfoSlice";
import academySeminarsSlice from "./slices/academySeminarsSlice";
import seminarsSlice from "./slices/seminarsSlice";
import instructorsSlice from "./slices/instructorsSlice";
import editorSlice from "./slices/editorSlice";
import planningSlice from "./slices/planningSlice";
import contractsSlice from "./slices/contractsSlice";
import planCampsSlice from "./slices/planCampSlice";
import chatsSlice from "./slices/chatsSlice";
import socketChat from "./slices/socketChatSlice";
import historySlice from "./slices/historySlice";
import notificationsSlice from "./slices/notificationsSlice";
import trackingSlice from "./slices/trackingSlice";
import reviewsSlice from "./slices/reviewsSlice";
import paymentsSlice from "./slices/paymentsSlice";
import payoutMethodsSlice from "./slices/payoutMethodsSlice";
import ticketsSlice from "./slices/ticketsSlice";
import privateLessonsSlice from "./slices/privateLessonsSlice";
import toursSlice from "./slices/toursSlice";
export const store = configureStore({
    reducer: {
        application: applicationSlice,
        academyGeneralInfoApp: academyGeneralInfoSlice,
        academySeminars: academySeminarsSlice, // deprecated
        seminars: seminarsSlice,
        instructors: instructorsSlice,
        editor: editorSlice,
        planning: planningSlice,
        contracts: contractsSlice,
        chats: chatsSlice,
        planCamps: planCampsSlice,
        socketChat: socketChat,
        history: historySlice,
        notifications: notificationsSlice,
        tracking: trackingSlice,
        reviews: reviewsSlice,
        payments: paymentsSlice,
        payoutMethods: payoutMethodsSlice,
        tickets: ticketsSlice,
        privateLessons: privateLessonsSlice,
        tours: toursSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // Ignore these action types
            ignoredActions: ["planning/setSeminarFormData", "socketChat/setSocket"],
            // Ignore these field paths in all actions
            ignoredActionPaths: ["planning.setSeminarFormData", "meta.arg.body", "meta.arg"],
            // Ignore these paths in the state
            ignoredPaths: ["planning.seminarFormData.photos", "planning.seminarFormData.images", "planning.seminarFormData.videos", "socketChat.socket"]
        }
    })
});
