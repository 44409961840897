import classNames from "classnames";
import { Country } from "country-state-city";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import { ReactComponent as CompassIcon } from "../../assets/img/Compass.svg";
import { getImageSrc } from "../../helpers/utils";
import Button from "../ui/buttons/Button";
import Textarea from "../ui/formElements/Textarea";
import ModalTemplate from "./ModalTemplate";

const MIN_LENGTH = 3

const ConfirmComplateModal = ({ open, handleClose, data }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [formData, setFormData] = useState({
        "isReleased": true,
        "notes": null
    })

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async () => {
        try {
            if (!formData.isReleased && formData.notes.length < MIN_LENGTH) {
                setIsErrorsShown(true)
                return
            }

            setIsLoading(true)
            const response = await api.seminars.releaseSeminar({
                seminarId: data.id,
                body: formData
            });

            if (response?.success) {
                handleClose && handleClose()
            } else {
                toast.error(response?.message || "Something went wrong")
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <ModalTemplate
            modalSize="modal__dialog--xs"
            onClick={handleClose}
            open={open}
        >
            <div className="refund_body">
                <div>
                    <h2 className="refund_heading">Did the seminar take place</h2>
                    <div className="refund_body_container">
                        <img
                            className="refund_body_img"
                            alt={data?.seminarName || data?.name}
                            src={getImageSrc(data?.cover || data?.cover)}
                        />
                        <div>
                            <div className="refund_body_address mb-8">
                                <CompassIcon />
                                <p className="capitalize">
                                    {[
                                        data?.location,
                                        data?.address,
                                        data?.continent,
                                        Country.getCountryByCode(data?.country)?.name,
                                        data?.city
                                    ].filter(Boolean).join(", ") || "Empty"}
                                </p>
                            </div>
                            <p className="refund_body_seminar_name">{data?.seminarName || data?.name}</p>
                        </div>
                    </div>
                    <div className="row-group gap--sm mt-28">
                        <Button
                            className={classNames("btn--lg w-full", { "btn--default": formData.isReleased })}
                            onClick={() => handleChange("isReleased", true)}
                        >
                            Yes
                        </Button>
                        <Button
                            className={classNames("btn--lg w-full", { "btn--default": !formData.isReleased })}
                            onClick={() => handleChange("isReleased", false)}
                        >
                            No
                        </Button>
                    </div>
                    {!formData.isReleased && (
                        <>
                            <hr className="hr-horisontal mb-32 mt-28" />
                            <Textarea
                                label="Reason"
                                onChange={(e) => handleChange("notes", e.target.value)}
                                placeholder="Type the reason why you need the cancel event..."
                                style={{ minHeight: 140 }}
                                textareaClasses="input--solid"
                                value={formData.notes ?? ""}
                                error={isErrorsShown && formData.notes.length < MIN_LENGTH && `Reason is required, minimum length is ${MIN_LENGTH} symbols`}
                                wrapperClasses="input--lg"
                            />
                        </>
                    )}
                </div>
                <div className="ml-auto mt-28">
                    <Button
                        className=" btn--md"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn--primary btn--md"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>


                </div>
            </div>
        </ModalTemplate>
    );
};

export default ConfirmComplateModal;
