const urlBase = "api/guest";

export const guest = (instance) => ({
    async createGuest (payload) {
        try {
            const { data } = await instance.post(`${urlBase}`, payload);

            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getGuestUser (token = null) {
        const { data } = await instance.get(`${urlBase}/me`, token
            ? { headers: { Authorization: `Bearer ${token}` } }
            : {});

        return data;
    }
});
