import React from "react";

const SeminarOverviewPrices = ({ data }) => {
    const sharedProfit = data?.sharedProfit
    const totalRevenue = data?.ticketsPurchased * data?.minimalPriceTicket

    const instructorRevenue =
        sharedProfit
            ? totalRevenue * (sharedProfit / 100)
            : 0;

    const academyRevenue =
        sharedProfit
            ? totalRevenue - instructorRevenue
            : 0

    return (
        <div className="form-header">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="intro__col">
                        <div className="intro__row__item">
                            <div className="seo-board">
                                <div className="seo-board--item">
                                    <p className="seo-board--title">
                                        Instructor earned
                                    </p>

                                    <div className="seo-board-val">
                                        <p>
                                            ${sharedProfit ? instructorRevenue : data?.instructorPrice}
                                        </p>
                                    </div>
                                </div>

                                <div className="seo-board--item">
                                    <p className="seo-board--title">
                                        Academy earned
                                    </p>

                                    <div className="seo-board-val">
                                        <p>
                                            ${sharedProfit ? academyRevenue: data?.ticketsPurchased * data?.minimalPriceTicket - data?.instructorPrice}
                                        </p>
                                    </div>
                                </div>

                                <div className="seo-board--item">
                                    <p className="seo-board--title">
                                        Tickets sold
                                    </p>

                                    <div className="seo-board-val">
                                        <p>
                                            {data?.ticketsPurchased}
                                        </p>
                                    </div>
                                </div>

                                <div className="seo-board--item">
                                    <p className="seo-board--title">
                                        Total earned
                                    </p>

                                    <div className="seo-board-val">
                                        <p>
                                            ${totalRevenue}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeminarOverviewPrices;
