import {
    useEffect, useState
} from "react";
import { Country } from "country-state-city";
import { pick } from "lodash";
import moment from "moment";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import { convertObjectToFormData, getFullLocation } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { updateCampFetch } from "../../../redux/thunks/planCampThuncks";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { ReactComponent as InfoIcon } from "../../../assets/img/question-circle.svg";
import Input from "../../../components/ui/formElements/Input";
import { getAcademyGeneralInfo } from "../../../redux/slices/academyGeneralInfoSlice";
import Location from "../parts/Location";
import ButtonsGroup from "../parts/ButtonsGroup";
import { EVENT_STEP, EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import EventDatesSelect from "../parts/EventDatesSelect";
import SpecificDatesSelect from "../parts/SpecificDatesSelect";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import { explanations } from "../../../constants/explanations";
import DateRangeSelect from "../parts/DateRangeSelect";
import { format, isValid } from "date-fns";

const TimeAndDate = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const { chatId: paramsChatId, campId } = useParams();
    const chatId = paramsChatId === "null" ? null : paramsChatId;
    const [isInterval, setIsInterval] = useState(true);

    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [editedFields, setEditedFields] = useState([]);


    // const getIncludedDates = (date) => {
    //     if (!date) {
    //         return null;
    //     }

    //     const includedDates = new Set();
    //     const currentDate = new Date(date);

    //     for (let i = 0; i <= 7; i++) {
    //         if (i === 0) {
    //             continue;
    //         }

    //         const newDate = new Date(currentDate);

    //         newDate.setDate(currentDate.getDate() + i);
    //         includedDates.add(newDate);
    //     }

    //     return Array.from(includedDates);
    // };

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (!errors.hasRequiredError) {
            if (campId && chatId) {
                const body = new FormData();
                const dataToUpdate = pick({
                    ...formData,
                    startAt: format(formData.startAt, "yyyy-MM-dd"),
                    endAt: format(formData.endAt, "yyyy-MM-dd"),
                }, editedFields);

                convertObjectToFormData(dataToUpdate, body);

                const res = await dispatch(updateCampFetch({
                    campId,
                    body
                })).unwrap();

                if (res) {
                    navigate(`/chat/${chatId}`);
                }
            } else {
                navigate("seminar-info");
            }
        }
    };

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    useEffect(() => {
        setErrorConfig({

            // requiredFields: ["startAt", "endAt"],
            requiredFields: (!isInterval ? ["schedule"] : []),
            messages: {
                schedule: `Select at least 3 days`
            },
            validators: {
                ...(!isInterval ? {
                    schedule: (value) => {
                        if (value.length < 3) return false;
                        let isValid = true;

                        value.forEach((item) => {
                            if (!item.date) {
                                isValid = false;
                            }
                        });
                        return isValid;
                    }
                } : {})
            }
        });
    }, [formData, isInterval]);


    useEffect(() => {
        const location = getFullLocation([
            Country.getCountryByCode(formData.country)?.name,
            formData.city,
            formData.address
        ]);
        handlePreviewChange("location", location);
    }, [formData])

    // useEffect(() => {
    //     if (!isInterval && formData?.schedule?.length > 1) {
    //         const startAt = formData.schedule[0].date
    //         const endAt = formData.schedule[formData.schedule.length - 1].date
    //         if (isValid(new Date(startAt)) && isValid(new Date(endAt))) {
    //             handleChange("startAt", startAt)
    //             handleChange("endAt", endAt)
    //         }
    //     }
    // }, [isInterval, formData])


    return (
        <>
            <Location
                role={user?.role}
                formData={formData}
                onChange={handleStepChange}
                isErrorsShown={isErrorsShown}
                errors={errors}
                eventType={EVENT_TYPE.camp}
                isAcademyDefault
            />
            <hr className="hr mb-42" />
            {/* <div className="row-group mb-36">
                <CheckboxSwitch
                    checked={isInterval}
                    onChange={() => setIsInterval(!isInterval)}
                />
                <h6
                    className="heading"
                    style={{ marginLeft: 16 }}
                >
                    Set Date Interval
                </h6>

                <TooltipInfo content={explanations.dateInterval} />
            </div> */}
            <h6 className="heading mb-36 max-w-238">
                Camp DAte
            </h6>
            <DateRangeSelect
                disabled={!isInterval}
                // handleChange={(dates) => handleStepChange("availableDates", dates)}
                handleChange={handleStepChange}
                errors={errors}
                isErrorsShown={isErrorsShown}
                dates={formData.schedule}
                startAt={formData.startAt}
                endAt={formData.endAt}
                minRange={3}
            />


            {/* <div className="row-group mb-36 mt-42">
                <CheckboxSwitch
                    checked={!isInterval}
                    onChange={() => setIsInterval(!isInterval)}
                />
                <h6 className="heading" style={{ marginLeft: 16 }}>OR Set Specific Dates</h6>
                <TooltipInfo content={explanations.specificDates} />
            </div>
            <SpecificDatesSelect
                dates={formData.schedule}
                disabled={isInterval}
                errors={errors.schedule}
                handleChange={(dates) => handleStepChange("schedule", dates)}
                isErrorsShown={isErrorsShown}
                maxLength={10}
            /> */}

            <hr className="hr mb-42 mt-42" />

            <div className="option-group mb-36">
                <div className="row-group">
                    <CheckboxSwitch
                        checked={formData?.allowPrivateLessons ? 1 : 0}
                        onChange={() => handleStepChange("allowPrivateLessons", !formData?.allowPrivateLessons ? 1 : 0)}
                        text={user?.role === USER_ROLE.instructor
                            ? "I want to do private sessions before/after the seminar"
                            : "Is instructor allowed to do private sessions before/after the seminar?"}
                    />
                </div>
            </div>

            {
                chatId && campId && !!formData?.allowPrivateLessons && (
                    <EventDatesSelect
                        dates={formData.privateSessionDates}
                        handleChange={(dates) => handleStepChange("privateSessionDates", dates)}
                        errors={errors}
                        isErrorsShown={isErrorsShown}
                    />
                )
            }
            <ButtonsGroup
                chatId={chatId}
                onSubmit={handleSubmit}
                eventId={campId}
                step={EVENT_STEP.date}
            />
        </>
    );
};

export default TimeAndDate;
