import LoginForm from "../../parts/LoginForm";
import ModalTemplate from "../ModalTemplate";

const LoginModal = ({ open, handleClose, singUpHandler, forgotPassHandler }) => {
    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__header">
                <div className="logo">
                    <img
                        alt="logo"
                        src={require("../../../assets/img/logo-black.svg").default}
                    />
                </div>
            </div>

            <div className="modal__body">
                <LoginForm
                    forgotPassHandler={forgotPassHandler}
                    onAfterSubmit={handleClose}
                    singUpHandler={singUpHandler}
                />
            </div>
        </ModalTemplate>
    );
};

export default LoginModal;
