const base = "/api/cooperate-teams/";

export const cooperateTeam = (instance) => ({

    async getAllCooperateTeams() {
        const {data} = await instance.get(`${base}my`);

        return data;
    },
    async getMyOwnTeams() {
        const {data} = await instance.get(`${base}my-own`);

        return data;
    },
    async getAllCooperateTeamsSystem() {
        const {data} = await instance.get(`${base}all?no_limit=true`);

        return data;
    },

    async addCooperateTeam(payload) {
        const {data} = await instance.post(`${base}`, payload);

        return data;
    },

    async updateCooperateTeam(payload, id) {
        const {data} = await instance.put(`${base}${id}`, payload);

        return data;
    },

    async deleteCooperateTeam(id) {
        const {data} = await instance.delete(`${base}${id}`);

        return data;
    },

    async attachTeam(payload) {
        console.log("payload", payload)

        const {data} = await instance.post(`${base}attach-team`, payload);

        return data;
    },

    async detachTeam(id) {
        const {data} = await instance.delete(`${base}detach-team/${id}`);

        return data;
    }

});
