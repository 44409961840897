import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ChatIcon } from "../../../assets/img/chatDark.svg";
import ReschedulePrivateLessonModal from "../../../components/modals/ReschedulePrivateLessonModal";
import Button from "../../../components/ui/buttons/Button";

export const StudentProfileRequests = () => {
    const [reschedulePrivateLessonModalOpen, setReschedulePrivateLessonModalOpen] = useState(false);

    const handleSubmit = (dates) => {
        setReschedulePrivateLessonModalOpen(false);
        console.log(dates);
    };

    const handleDecline = () => {

    };

    const handleApprove = () => {

    };

    return (
        <>
            <div className="request-items pt-28">
                <div className="request-item">
                    <div className="request-item__user">
                        <div className="ico ico--md img-wrapper">
                            <img
                                alt=""
                                src={require("../../../assets/img/user-avatar-1.png")}
                            />
                        </div>

                        <div className="col-group gap-4">
                            <h6 className="heading">
                                Wade Warren
                            </h6>

                            <p className="color-text">
                                25 Feb - 04 Mar, 2024
                            </p>
                        </div>
                    </div>

                    <div className="request-item__details">
                        <div className="col-group gap--xs">
                            <span className="label">
                                Total Price
                            </span>

                            <p className="fw-500 text--sm">
                                $1,300
                            </p>
                        </div>

                        <div className="col-group gap--xs">
                            <span className="label">
                                People
                            </span>

                            <p className="fw-500 text--sm">
                                1
                            </p>
                        </div>

                        <div className="col-group gap--xs">
                            <span className="label">
                                teaching
                            </span>

                            <p className="fw-500 text--sm">
                                NoGi
                            </p>
                        </div>

                        <div className="col-group gap--xs">
                            <span className="label">
                                Location
                            </span>

                            <p className="fw-500 text--sm">
                                BJJ New Gym
                            </p>
                        </div>
                    </div>

                    <div className="request-item__action">
                        <button
                            className="btn btn--sm"
                            onClick={() => setReschedulePrivateLessonModalOpen(true)}
                        >
                            <span className="ico">
                                <svg
                                    fill="none"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    width="20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.3327 1.66602V4.99935"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M6.66667 1.66602V4.99935"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M2.5 7.50065H17.5"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        clipRule="evenodd"
                                        d="M15.8333 3.33398H4.16667C3.24583 3.33398 2.5 4.07982 2.5 5.00065V15.834C2.5 16.7548 3.24583 17.5007 4.16667 17.5007H15.8333C16.7542 17.5007 17.5 16.7548 17.5 15.834V5.00065C17.5 4.07982 16.7542 3.33398 15.8333 3.33398Z"
                                        fillRule="evenodd"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M5.84357 10.6068C5.72857 10.6068 5.63524 10.7001 5.63607 10.8151C5.63607 10.9301 5.7294 11.0234 5.8444 11.0234C5.9594 11.0234 6.05274 10.9301 6.05274 10.8151C6.05274 10.7001 5.9594 10.6068 5.84357 10.6068"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M10.0115 10.6068C9.89654 10.6068 9.80321 10.7001 9.80404 10.8151C9.80404 10.9301 9.89737 11.0234 10.0124 11.0234C10.1274 11.0234 10.2207 10.9301 10.2207 10.8151C10.2207 10.7001 10.1274 10.6068 10.0115 10.6068"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M14.1776 10.6068C14.0626 10.6068 13.9692 10.7001 13.9701 10.8151C13.9701 10.9301 14.0634 11.0234 14.1784 11.0234C14.2934 11.0234 14.3867 10.9301 14.3867 10.8151C14.3867 10.7001 14.2934 10.6068 14.1776 10.6068"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M5.84357 13.9408C5.72857 13.9408 5.63524 14.0341 5.63607 14.1491C5.63607 14.2641 5.7294 14.3574 5.8444 14.3574C5.9594 14.3574 6.05274 14.2641 6.05274 14.1491C6.05274 14.0341 5.9594 13.9408 5.84357 13.9408"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M10.0115 13.9408C9.89654 13.9408 9.80321 14.0341 9.80404 14.1491C9.80404 14.2641 9.89737 14.3574 10.0124 14.3574C10.1274 14.3574 10.2207 14.2641 10.2207 14.1491C10.2207 14.0341 10.1274 13.9408 10.0115 13.9408"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />
                                </svg>
                            </span>

                            Reschedule
                        </button>

                        <Button
                            className="btn btn--sm"
                            onClick={handleDecline}
                        >
                            Decline
                        </Button>

                        <Button
                            className="btn btn--sm btn--primary"
                            onClick={handleApprove}
                        >
                            Approve
                        </Button>

                        <Link
                            className="btn btn--sm btn--square notification"
                            count="1"
                            to="/chat/"
                        >
                            <span className="ico">
                                <ChatIcon />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>

            {
                reschedulePrivateLessonModalOpen && (
                    <ReschedulePrivateLessonModal
                        data={[]}
                        handleClose={() => setReschedulePrivateLessonModalOpen(false)}
                        onSubmit={handleSubmit}
                        open={reschedulePrivateLessonModalOpen}
                    />
                )}
        </>
    );
};
