import { capitalize } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import RequestRefundModal from "../../../components/modals/RequestRefundModal";
import Button from "../../../components/ui/buttons/Button";
import PageLoader from "../../../components/ui/PageLoader";
import { getBelt } from "../../../helpers/utils";
import { getAllMyTicketsFetch, getMyTicketInfoByIdFetch } from "../../../redux/thunks/ticketsThunks";

const MyTickets = () => {
    const dispatch = useDispatch()
    const { tickets, isLoading } = useSelector(state => state.tickets)




    useEffect(() => {
        dispatch(getAllMyTicketsFetch())
    }, [])

    if (isLoading) {
        return <PageLoader />
    }

    if (!tickets?.length) {
        return <h3 className="heading pt-28">There is no tickets yet</h3>
    }

    return (
        <div className="request-items pt-28">
            {
                tickets.map(item => (
                    <Ticket
                        key={item.id}
                        data={item}
                        update={() => dispatch(getAllMyTicketsFetch())}
                    />
                ))
            }
        </div>
    );
};


function Ticket({ data, update }) {
    const dispatch = useDispatch()
    const isVerified = data?.invoice?.status === "verified"

    const [returnTicketModalOpen, setReturnTicketModalOpen] = useState(false)

    const handlePay = async () => {
        const res = await dispatch(getMyTicketInfoByIdFetch(data.id)).unwrap();
        const paymentUrl = res?.data?.invoice?.session?.url

        if (paymentUrl) {
            window.open(paymentUrl)
        }
    }

    const handleClose = () => {
        setReturnTicketModalOpen(false)
        update && update()
    }

    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="col-group gap-4">
                    <h6 className="heading">
                        {data.fullName}
                    </h6>

                    <p className="color-text">
                        {moment(data.createdAt).format("DD MMM, yyyy")}
                    </p>
                </div>
            </div>

            <div className="request-item__details">

                <div className="col-group gap--xs">
                    <span className="label">
                        Belt
                    </span>

                    <p className="fw-500 text--sm">
                        {getBelt(data.belt)?.title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Status
                    </span>

                    <p className="fw-500 text--sm">
                        {capitalize(data.invoice?.status)}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Price
                    </span>

                    <p className="fw-500 text--sm">
                        ${data.invoice?.price}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Fee
                    </span>

                    <p className="fw-500 text--sm">
                        ${data.invoice?.fee * data.invoice?.price}
                    </p>
                </div>

                
                <div className="col-group gap--xs">
                    <span className="label">
                        Total Price
                    </span>

                    <p className="fw-500 text--sm">
                        ${data.invoice?.fee * data.invoice?.price + data.invoice?.price}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                {
                    isVerified && (
                        <Button
                            className="btn btn--sm btn--default"
                            onClick={() => setReturnTicketModalOpen(true)}
                        >
                            Return ticket
                        </Button>
                    )
                }
                <Link
                    className="btn btn--sm underline"
                    count="1"
                    to={`/${data?.eventType ? "seminars" : "camps"}/${data.eventId}`}
                >
                    Seminar details
                </Link>
                {
                    !isVerified && (
                        <Button
                            className="btn btn--sm btn--primary"
                            onClick={handlePay}
                        >
                            Pay
                        </Button>
                    )
                }
            </div>
            {returnTicketModalOpen && (
                <RequestRefundModal
                    data={data}
                    open={returnTicketModalOpen}
                    handleClose={handleClose}
                />
            )}
        </div>
    )
}

export default MyTickets
