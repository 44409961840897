import DefaultLayout from "../layouts/DefaultLayout";
import SecondaryLayout from "../layouts/SecondaryLayout";
import SingUpLayout from "../layouts/SingUpLayout";
import HomePage from "../pages/HomePage";
import {
    createBrowserRouter, createRoutesFromElements, Route
} from "react-router-dom";
import AcademySingUp from "../pages/singUpPages/AcademySingUp";
import InstructorSingUp from "../pages/singUpPages/InstructorSingUp";
import StudentSingUp from "../pages/singUpPages/StudentSingUp";
import BillingInfo from "../pages/profiles/studentProfilePages/BillingInfo";
import { MySeminarsLayout } from "../layouts/MySeminarsLayout";
import ProfileLayout from "../layouts/ProfileLayout";
import SocketProvider from "../layouts/SocketProvider";
import AboutUSNew from "../pages/AboutUSNew";
import AcademiesPage from "../pages/academies/AcademiesPage";
import AcademySingle from "../pages/academies/AcademySingle";
import BuySeminarPage from "../pages/booking/BuySeminarPage";
import { Chat } from "../pages/chat/Chat";
import ContactUs from "../pages/ContactUs";
import CookiesSettings from "../pages/CookiesSettings";
import TemplateEditor from "../pages/editor/TemplateEditor";
import { Instructors } from "../pages/instructors/Instructors";
import { InstructorSingle } from "../pages/instructors/InstructorSingle";
import InviteToSeminar from "../pages/instructors/InviteToSeminar";
import SuccessInvite from "../pages/instructors/SuccessInvite";
import NotFoundPage from "../pages/NotFoundPage";
import NotificationsPage from "../pages/NotificationsPage";
import ContractPage from "../pages/planning/ContractPage";
import PlanningSuccess from "../pages/planning/parts/PlanningSuccess";
import { PlanCamp } from "../pages/planning/planCamp";
import CampSummary from "../pages/planning/planCamp/CampSummary";
import { PlanSeminar } from "../pages/planning/planSeminar";
import SeminarSummary from "../pages/planning/planSeminar/SeminarSummary";
import PreviewTour from "../pages/planning/PreviewTour";
import { RequestPrivateSession } from "../pages/planning/requestPrivateSession";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import GeneralInfo from "../pages/profiles/GeneralInfo";
import LocationInstructr from "../pages/profiles/instructorProfilePages/LocationInstructr";
import PrivateSessionInstructor from "../pages/profiles/instructorProfilePages/PrivateSessionInstructor";
import { RequestedPtivateLessons } from "../pages/profiles/instructorProfilePages/RequestedPtivateLessons";
import MyProfile from "../pages/profiles/MyProfile";
import { MySeminars } from "../pages/profiles/MySeminars";
import PayuotMethods from "../pages/profiles/PayuotMethods";
import { ProfileRequests } from "../pages/profiles/ProfileRequests";
import Security from "../pages/profiles/Security";
import RefundPolicy from "../pages/RefundPolicy";
import { CampsMain } from "../pages/seminarsCamps/capms/CampsMain";
import { CampsPendingByAcademy } from "../pages/seminarsCamps/capms/CampsPendingByAcademy";
import { CampsPendingByInstructor } from "../pages/seminarsCamps/capms/CampsPendingByInstructor";
import { CampsSingle } from "../pages/seminarsCamps/capms/CampsSingle";
import SeminarOverview from "../pages/seminarsCamps/SeminarOverview";
import SeminarSingle from "../pages/seminarsCamps/seminars/SeminarSingle";
import { SeminarMain } from "../pages/seminarsCamps/seminars/SeminarsMain";
import SeminarsPendingByAcademy from "../pages/seminarsCamps/seminars/SeminarsPendingByAcademy";
import SeminarsPendingByInstructor from "../pages/seminarsCamps/seminars/SeminarsPendingByInstructor";
import TermsAndConditions from "../pages/TermsAndConditions";
import TermsOfUse from "../pages/TermsOfUse";
import OAuthSignUp from "../pages/singUpPages/OAuthSignUp";
import BuyCampPage from "../pages/booking/BuyCampPage";
import MyTickets from "../pages/profiles/studentProfilePages/MyTickets";
import TicketPage from "../pages/TicketPage";
import BreadcrumbsProvider from "../layouts/BreadcrumbsProvider";
import { PlanTour } from "../pages/planning/planTour";
import { ToursMain } from "../pages/seminarsCamps/tours/ToursMain";
import ToursPendingByInstructor from "../pages/seminarsCamps/tours/ToursPendingByInstructor";
import TourSingle from "../pages/seminarsCamps/tours/TourSingle";
import BuyPrivateLessonPage from "../pages/booking/BuyPrivateLessonPage";
import TourOverview from "../pages/seminarsCamps/TourOverview";

export const router = createBrowserRouter(createRoutesFromElements(
    <Route element={<BreadcrumbsProvider />}>
        <Route element={<SocketProvider />}>

            <Route element={<DefaultLayout />}>
                <Route element={<HomePage />} path="/" />

                <Route
                    element={<HomePage />}
                    path="/recovery-password/:restoreToken"
                />

                {/* <Route path="/about-us" element={<AboutUS />} />*/}
                <Route
                    element={<AboutUSNew />}
                    path="/about-us"
                />

                <Route
                    element={<ContactUs />}
                    path="/contact-us"
                />

                <Route
                    element={<Instructors />}
                    path="/instructors"
                />
            </Route>

            <Route
                element={(<DefaultLayout
                    footerHide={true}
                    headerClass="header--white"
                    mainClass="flex flex-col"
                    wrapperClass=" header-fixed"
                />)}
            >
                <Route
                    element={<AcademiesPage />}
                    path="/academies"
                />

                <Route
                    element={<Chat />}
                    path="/chat"
                />

                <Route
                    element={<Chat />}
                    path="/chat/:chatId"
                />

                <Route
                    element={<Chat />}
                    path="/chat/:chatId/:seminarId"
                />

                <Route
                    element={<NotificationsPage />}
                    path="/notifications"
                />

            </Route>

            <Route
                element={(<DefaultLayout
                    headerClass="header--white"
                    mainClass="flex flex-col user-page header-fixed"
                />)}
                path="/instructors/:id"
            >
                <Route
                    element={<InstructorSingle />}
                    index
                />

                <Route
                    element={<InviteToSeminar />}
                    path="invite/:tourId?"
                />

                <Route
                    element={<SuccessInvite />}
                    path="invite/success"
                />
            </Route>

            <Route
                element={(<DefaultLayout mainClass="current-seminars" />)}
                path="/planed-by-academy"
            >
                <Route
                    element={<SeminarsPendingByAcademy />}
                    index
                />
            </Route>

            <Route
                element={(<DefaultLayout mainClass="current-seminars" />)}
                path="/planed-by-insctructor"
            >
                <Route
                    element={<SeminarsPendingByInstructor />}
                    index
                />
            </Route>

            <Route
                element={(<DefaultLayout
                    headerClass="header--white"
                    mainClass="flex flex-col user-page"
                    wrapperClass=" header-fixed"
                />)}
            >
                <Route
                    element={<InstructorSingle />}
                    path="/instructors/:id"
                />

                <Route
                    element={<AcademySingle />}
                    path="/academies/:id"
                />
            </Route>

            <Route
                element={(<DefaultLayout
                    headerClass="header--white"
                    mainClass="flex flex-col"
                    wrapperClass=" header-fixed"
                />)}
            >
                <Route
                    element={<SeminarOverview eventType="camp" />}
                    path="/my-seminars/camps/:id"
                />
                <Route
                    element={<SeminarOverview eventType="seminar" />}
                    path="/my-seminars/:id"
                />
                <Route
                    element={<TourOverview />}
                    path="/my-seminars/tour-overview/:tourId"
                />

                <Route
                    element={<TicketPage />}
                    path="/pub-tickets/:hash"
                />
            </Route>

            <Route
                element={(<DefaultLayout
                    headerClass="header--white"
                    mainClass="flex flex-col my-seminars-page"
                    wrapperClass=" header-fixed"
                />)}
            >
                <Route element={<MySeminarsLayout />}>
                    <Route
                        element={<MySeminars />}
                        path="/my-seminars"
                    />

                    <Route
                        element={<MyTickets />}
                        path="/my-tickets"
                    />

                    <Route
                        element={<ProfileRequests />}
                        path="/requests"
                    />

                    <Route
                        element={<RequestedPtivateLessons />}
                        path="/requested-lessons"
                    />
                </Route>
            </Route>

            <Route element={(<DefaultLayout mainClass="current-seminars" />)}>
                <Route
                    element={<SeminarMain />}
                    path="/seminars"
                />

                <Route
                    element={<CampsMain />}
                    path="/camps"
                />
                <Route
                    element={<ToursMain />}
                    path="/tours"
                />

                <Route
                    element={<CampsPendingByAcademy />}
                    path="/planed-by-academy/camps"
                />

                <Route
                    element={<SeminarsPendingByAcademy />}
                    path="/planed-by-academy"
                />

                <Route
                    element={<SeminarsPendingByInstructor />}
                    path="/planed-by-insctructor"
                />

                <Route
                    element={<CampsPendingByInstructor />}
                    path="/planed-by-insctructor/camps"
                />
                <Route
                    element={<ToursPendingByInstructor />}
                    path="/planed-by-insctructor/tours"
                />
            </Route>

            <Route
                element={(<DefaultLayout
                    headerClass="header--white"
                    mainClass="current-seminars"
                    wrapperClass=" header-fixed"
                />)}
            >
                <Route
                    element={<SeminarSingle />}
                    path="/seminars/:id"
                />

                <Route
                    element={<CampsSingle />}
                    path="/camps/:id"
                />
                <Route
                    element={<CampsSingle />}
                    path="/tours/:id"
                />

                <Route
                    element={(<SeminarSingle pageTyleDefault="pending" />)}
                    path="/planed-by-insctructor/:id"
                />

                <Route
                    element={(<CampsSingle pageTyleDefault="pending" />)}
                    path="/planed-by-insctructor/camps/:id"
                />

                <Route
                    element={(<TourSingle pageTyleDefault="pending" />)}
                    path="/planed-by-insctructor/tours/:id"
                />

                <Route
                    element={<SeminarSingle pageTyleDefault="pending" />}
                    path="/planed-by-academy/:id"
                />

                <Route
                    element={<CampsSingle pageTyleDefault="pending" />}
                    path="/planed-by-academy/camps/:id"
                />
            </Route>

            {/* sign up */}
            <Route element={<DefaultLayout />}>
                <Route
                    element={<OAuthSignUp />}
                    path="/sign-in"
                />
            </Route>

            <Route element={<SingUpLayout headerTitle="STUDENT ACCOUNT" />}>
                <Route
                    element={<StudentSingUp />}
                    path="/student-sing-up"
                />
            </Route>

            <Route element={<SingUpLayout headerTitle="ACADEMY ACCOUNT" />}>
                <Route
                    element={<AcademySingUp />}
                    path="/academy-sing-up"
                />
            </Route>

            <Route element={<SingUpLayout headerTitle="INSTRUCTOR ACCOUNT" />}>
                <Route
                    element={<InstructorSingUp />}
                    path="/instructor-sing-up"
                />
            </Route>

            <Route element={<ProfileLayout />}>
                <Route
                    element={<MyProfile />}
                    path="/settings/my-profile"
                />

                <Route
                    element={<Security />}
                    path="/settings/security"
                />

                <Route
                    element={<GeneralInfo />}
                    path="/settings/general-information"
                />

                <Route
                    element={<PayuotMethods />}
                    path="/settings/payout-methods"
                />

                <Route
                    element={<LocationInstructr />}
                    path="/settings/location-availability"
                />

                <Route
                    element={<PrivateSessionInstructor />}
                    path="/settings/private-session"
                />

                <Route
                    element={<BillingInfo />}
                    path="/settings/billing"
                />
            </Route>

            {/* planning */}
            <Route
                element={<TemplateEditor />}
                path="/template-editor"
            />

            <Route element={<SecondaryLayout />}>
                <Route
                    element={<PlanSeminar.PlanSeminarWrapper type="edit" />}
                    path="/edit-seminar/:chatId/:seminarId/:requestId"
                >
                    <Route
                        element={<PlanSeminar.TimeAndDate />}
                        index
                    />

                    <Route
                        element={<PlanSeminar.SeminarInformation />}
                        path="seminar-info"
                    />

                    <Route
                        element={<PlanSeminar.DesignText />}
                        path="design-text"
                    />

                    <Route
                        element={<PlanSeminar.BookingTerms />}
                        path="booking"
                    />

                    <Route
                        element={<PlanningSuccess />}
                        path="success"
                    />
                </Route>

                <Route
                    element={<ContractPage />}
                    path="/edit-seminar/:chatId/:seminarId/contract"
                />

                <Route
                    element={<SeminarSummary />}
                    path="/edit-seminar/:chatId/:seminarId/:requestId/summary"
                />

                <Route
                    element={<PlanSeminar.PlanSeminarWrapper />}
                    path="/plan-seminar/:tourId?"
                >
                    <Route
                        element={<PlanSeminar.TimeAndDate />}
                        index
                    />

                    <Route
                        element={<PlanSeminar.SeminarInformation />}
                        path="seminar-info"
                    />

                    <Route
                        element={<PlanSeminar.DesignText />}
                        path="design-text"
                    />

                    <Route
                        element={<PlanningSuccess />}
                        path="success"
                    />
                </Route>

                <Route
                    element={<PlanTour.PlanTourWrapper />}
                    path="/plan-tour/:tourId?"
                >
                    <Route
                        element={<PlanTour.TimeAndDate />}
                        index
                    />

                    <Route
                        element={<PlanTour.SeminarInformation />}
                        path="seminar-info"
                    />

                    <Route
                        element={<PlanTour.DesignText />}
                        path="design-text"
                    />

                    <Route
                        element={<PlanningSuccess />}
                        path="success"
                    />
                </Route>

                <Route
                    element={<PreviewTour />}
                    path="/plan-tour/preview-tour/:tourId"
                />


                <Route
                    element={<PlanCamp.PlanCampWrapper />}
                    path="/plan-camp"
                >
                    <Route
                        element={<PlanCamp.TimeAndDate />}
                        index
                    />

                    <Route
                        element={<PlanCamp.SeminarInformation />}
                        path="seminar-info"
                    />

                    <Route
                        element={<PlanCamp.DesignText />}
                        path="design-text"
                    />

                    <Route
                        element={<PlanningSuccess />}
                        path="success"
                    />
                </Route>

                <Route
                    element={<PlanCamp.PlanCampWrapper type="edit" />}
                    path="/edit-camp/:chatId/:campId/:requestId"
                >
                    <Route
                        element={<PlanCamp.TimeAndDate />}
                        index
                    />

                    <Route
                        element={<PlanCamp.SeminarInformation />}
                        path="seminar-info"
                    />

                    <Route
                        element={<PlanCamp.DesignText />}
                        path="design-text"
                    />

                    <Route
                        element={<PlanCamp.BookingTerms />}
                        path="booking"
                    />

                    <Route
                        element={<PlanningSuccess />}
                        path="success"
                    />
                </Route>

                {/* <Route path="/edit-camp/:chatId/:campId/contract" element={<ContractPage />} /> */}
                <Route
                    element={<CampSummary />}
                    path="/edit-camp/:chatId/:campId/:requestId/summary"
                />

                <Route
                    element={<RequestPrivateSession.RequestPrivateSessionWrapper />}
                    path="/request-private-session/:instructorId"
                >
                    <Route
                        element={<RequestPrivateSession.TimeAndDate />}
                        index
                    />
                </Route>

                <Route
                    element={<RequestPrivateSession.Summary />}
                    path="/request-private-session/:instructorId/:chatId/summary"
                />

                <Route
                    element={<BuySeminarPage />}
                    path="/book/:seminarId"
                />
                <Route
                    element={<BuyCampPage />}
                    path="/book/camp/:campId"
                />
                <Route
                    element={<BuyPrivateLessonPage />}
                    path="/book-lesson/:eventType/:eventId"
                />

                <Route
                    element={<TermsAndConditions />}
                    path="terms-of-service"
                />

                <Route
                    element={<TermsOfUse />}
                    path="terms-of-use"
                />

                <Route
                    element={<PrivacyPolicy />}
                    path="privacy-policy"
                />

                <Route
                    element={<RefundPolicy />}
                    path="refund-policy"
                />

                <Route
                    element={<CookiesSettings />}
                    path="cookies-settings"
                />

                <Route
                    element={<NotFoundPage />}
                    path="*"
                />
            </Route>
        </Route>
    </Route>
), { basename: "/" });
