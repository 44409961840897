import { createSlice } from "@reduxjs/toolkit";
import { getAllMyTicketsFetch, getTicketByEventTypeAndEventIdFetch } from "../thunks/ticketsThunks";
import { getAllTracksFetch } from "../thunks/trackingThunks";
const initialState = {
    tickets: [],
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const ticketsSlice = createSlice({
    name: "tickets",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllMyTicketsFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tickets = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllMyTicketsFetch.pending, (state, action) => {
                state.isLoading = true;
                state.tickets = [];
                state.pagination = {};
            })
            .addCase(getAllMyTicketsFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.tickets = [];
                state.pagination = {};
            })
            .addCase(getTicketByEventTypeAndEventIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tickets = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getTicketByEventTypeAndEventIdFetch.pending, (state, action) => {
                state.isLoading = true;
                state.tickets = [];
                state.pagination = {};
            })
            .addCase(getTicketByEventTypeAndEventIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.tickets = [];
                state.pagination = {};
            })
    }
});

export const {} = ticketsSlice.actions;
export default ticketsSlice.reducer;
