import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { setAuthModalOpen } from "../../../redux/slices/applicationSlice";
import ForgotPass from "./ForgotPass";
import GuestModal from "./GuestModal";
import LoginModal from "./LoginModal";
import RestorePass from "./RestorePass";
import SingUpModal from "./SingUpModal";
export const AuthModals = {
    login: "login",
    singUp: "singUp",
    fogrotPassword: "fogrotPassword",
    resetPassword: "resetPassword",
    guest: "guest"
};

const AuthProvider = () => {
    const dispatch = useDispatch();
    const openModal = useSelector((state) => state.application.authModalOpen);
    const isLoginOpen = openModal === AuthModals.login;
    const isSingUpOpen = openModal === AuthModals.singUp;
    const isFogrotPasswordOpen = openModal === AuthModals.fogrotPassword;
    const isResetPasswordOpen = openModal === AuthModals.resetPassword;
    const isGuestOpen = openModal === AuthModals.guest;

    const handleOpen = (name) => {
        dispatch(setAuthModalOpen(name));
    };

    const handleClose = () => {
        dispatch(setAuthModalOpen(null));
    };

    return (
        <>
            {isLoginOpen && (
                <LoginModal
                    forgotPassHandler={() => {
                        handleOpen(AuthModals.fogrotPassword);
                    }}
                    handleClose={handleClose}
                    open={isLoginOpen}
                    singUpHandler={() => {
                        handleOpen(AuthModals.singUp);
                    }}
                />
            )}

            {isSingUpOpen && (
                <SingUpModal
                    handleClose={handleClose}
                    loginHandler={() => {
                        handleOpen(AuthModals.login);
                    }}
                    open={isSingUpOpen}
                />
            )}

            {isFogrotPasswordOpen && (
                <ForgotPass
                    handleClose={handleClose}
                    loginHandler={() => {
                        handleOpen(AuthModals.login);
                    }}
                    open={isFogrotPasswordOpen}
                />
            )}

            {isResetPasswordOpen && (
                <RestorePass
                    handleClose={handleClose}
                    loginHandler={handleClose}
                    open={isResetPasswordOpen}
                />
            )}

            {isGuestOpen && (
                <GuestModal
                    handleClose={handleClose}
                    loginHandler={handleClose}
                    open={isGuestOpen}
                />
            )}
        </>
    );
};

export default AuthProvider;
