import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../api";
import { belts } from "../../../constants";
import {
    setAuthModalOpen, setToken
} from "../../../redux/slices/applicationSlice";
import { getGuestUserFetch } from "../../../redux/thunks/applicationThunks";
import Button from "../../ui/buttons/Button";
import Input from "../../ui/formElements/Input";
import SelectOne from "../../ui/formElements/SelectOne";
import ModalTemplate from "../ModalTemplate";
import { AuthModals } from "./AuthProvider";

const GuestModal = ({ open, handleClose, loginHandler }) => {
    const dispatch = useDispatch();
    const [isSendedCode, setIsSendedCode] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({
        fullName: "",
        email: "",
        belt: ""
    });

    const handleChange = (name, value) => {
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        if (data.password !== data.confirm) {
            toast.error("\"Confirm Password\" is not equal to \"Password\"");

            return;
        }

        setIsLoading(true)
        const response = await api.guest.createGuest(data);

        if (response?.success) {
            toast.success(response.message);
            localStorage.setItem("authToken", response.data.token);
            dispatch(setToken(response.data.token));
            setTimeout(() => {
                dispatch(getGuestUserFetch(response.data.token));
                handleClose && handleClose();
            }, 200);
        } else if (response?.data?.isSendedCode) {
            setIsSendedCode(true)
            toast.success(response.message || "New authorization code send to your email")
        } else {
            toast.error(response.message || "Something went wrong");
        }

        setIsLoading(false)
    };

    const specificBeltOptions = belts.map(({ key, title }) => ({
        value: key,
        label: title
    }));

    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body">
                <form
                    className="form form--lg"
                    method="post"
                >
                    <div className="form-header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Continue as Guest
                            </h3>

                            <p className="text--md desk-heading">
                                Fill in the form below and continue as guest.
                            </p>
                        </div>
                    </div>

                    <div className="form-body">
                        <div className="row gutters-form">
                            <div className="col-12 form form--md">
                                <Input
                                    inputClasses="input--solid"
                                    label="Full Name"
                                    onChange={(e) => handleChange("fullName", e.target.value)}
                                    placeholder="Type your full name..."
                                    type="text"
                                    value={data.fullName}
                                    wrapperClasses="form-group flex-auto input--lg"
                                />

                                <Input
                                    inputClasses="input--solid"
                                    label="Email"
                                    onInput={(e) => handleChange("email", e.target.value)}
                                    placeholder="Type your email..."
                                    value={data.email}
                                    wrapperClasses="form-group flex-auto input--lg"
                                />
                                {
                                    isSendedCode && (
                                        <Input
                                            inputClasses="input--solid"
                                            label="Code"
                                            onInput={(e) => handleChange("code", e.target.value)}
                                            placeholder="Type your code..."
                                            value={data.code}
                                            wrapperClasses="form-group flex-auto input--lg"
                                        />
                                    )
                                }

                                <SelectOne
                                    label="Belt Type"
                                    onChange={(option) => handleChange("belt", option.value)}
                                    options={specificBeltOptions}
                                    placeholder="Select your belt"
                                    value={data.belt}
                                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                />
                            </div>

                            <div className="col-12">
                                <Button
                                    className="w-full btn--primary btn--lg radius fs-20"
                                    onClick={handleSubmit}
                                    type="button"
                                    isLoading={isLoading}
                                >
                                    <span>
                                        Continue as Guest
                                    </span>
                                </Button>
                            </div>

                            <div className="col-12 text-center">
                                <Button
                                    className="w-full btn--default btn--lg radius fs-20"
                                    onClick={() => dispatch(setAuthModalOpen(AuthModals.login))}
                                >
                                    <span>
                                        Go Back
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    );
};

export default GuestModal;
