import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../components/ui/buttons/Button";
import PageLoader from "../../components/ui/PageLoader";
import { addPayoutMethodsFetch, createStripeAccountConnectionLinkFetch, generateStripeAccountLinkFetch, getMyPayoutMethodsFetch } from "../../redux/thunks/payoutMethodsThunks";

const PayoutMethodCard = ({ method, data }) => {
    const methods = {
        stripe: {
            logo: require("../../assets/img/ico-payout-stripe.svg").default,
            name: "Stripe"
        },
        paypal: {
            logo: require("../../assets/img/ico-payout-paypal.svg").default,
            name: "PayPAl"
        }
    };

    const stepsMap = {
        continue_write_details: "continue",
        account_completed: "to account"
    }
    const step = data?.step
    const error = data?.stripe?.requirements?.errors?.[0]?.reason
    const isPending = step === "continue_write_details"

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleSetup = async () => {
        setIsLoading(true)
        let res;

        if (data && data.step === "continue_write_details") {
            res = await dispatch(createStripeAccountConnectionLinkFetch(data.id)).unwrap()
        } else if (data) {
            res = await dispatch(generateStripeAccountLinkFetch(data.id)).unwrap()
        } else {
            res = await dispatch(addPayoutMethodsFetch({
                method
            })).unwrap()
        }

        if (res?.success && res?.data) {
            window.open(res.data.url)
            setTimeout(() => setIsLoading(false), 200)
        } else {
            setIsLoading(false)
        }
    }

    return (
        <div className="card-bordered card-row payout-method-card">
            <div className="card-row--item justify-between flex-auto overflow--x-hidden">
                <div className="card-row-group overflow--x-hidden">
                    <div className="flex flex-col justify-center">
                        <div className="payout-logo">
                            <img
                                alt={methods[method.name]}
                                src={methods[method].logo}
                            />
                        </div>
                    </div>

                    <div className="heading-block heading-nowrap flex-auto justify-center">
                        <h6 className="heading">
                            {methods[method].name}
                        </h6>

                        {
                            error ? (
                                <p className="desk-heading --sm-heading" style={{ whiteSpace: "normal" }}>{error}</p>
                            ) : isPending || !data ? (
                                <p className="desk-heading --sm-heading" style={{ whiteSpace: "normal" }}>
                                    Please, connect your
                                    {" "}

                                    {method}

                                    {" "}

                                    account to accept payments.
                                </p>
                            ) : null
                        }
                    </div>
                </div>
            </div>

            <div className="flex-group justify-end flex-group--xs flex-nowrap">
                <Button
                    isLoading={isLoading}
                    onClick={handleSetup}
                    className="btn btn--primary btn--sm radius"
                >
                    <span>
                        {data ? stepsMap[step] : "Setup"}
                    </span>
                </Button>
            </div>
        </div >
    );
};

const PayuotMethods = () => {
    const dispatch = useDispatch()
    const { payoutMethods, isLoading } = useSelector(state => state.payoutMethods)

    useEffect(() => {
        dispatch(getMyPayoutMethodsFetch())
    }, [])

    if (isLoading) {
        return <PageLoader />
    }

    const stripeData = payoutMethods?.find(item => item.method === 'stripe')

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    Payout Methods
                </h3>
            </div>

            <form
                className="form form-settings"
                method="post"
            >
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <ul className="settings-list">
                                        <li className="settings-list--item">
                                            <PayoutMethodCard method="stripe" data={stripeData} />
                                        </li>

                                        <li
                                            className="settings-list--item"
                                            style={{
                                                opacity: 0.4,
                                                pointerEvents: "none"
                                            }}
                                        >
                                            <PayoutMethodCard method="paypal" />
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default PayuotMethods;
