const base = "api/payments";

export const payments = (instance) => ({
    async getPaymentsCard(payload) {
        const { data } = await instance.get(`${base}/cards`);

        return data;
    },
    async addPaymentCard(payload) {
        // {
        //     "payment_method": "pm_1Q2w0oLbpvHSYVhLivmlZpEl",
        //     "isDefault": true
        // }
        const { data } = await instance.post(`${base}/cards`, payload);

        return data;
    },
    async updatePaymentCard({ id, body }) {
        const { data } = await instance.put(`${base}/cards/${id}`, body);

        return data;
    },
    async deletePaymentCard(id) {
        const { data } = await instance.delete(`${base}/cards/${id}`);

        return data;
    },
    async setupIntents(payload) {
        const { data } = await instance.get(`${base}/setup-intents`);

        return data;
    },
});
