import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api";

export const getMyPayoutMethodsFetch = createAsyncThunk(
    "/getMyPayoutMethodsFetch", async (payload, { rejectWithValue }) => {
        try {
            const res = await api.payoutMethods.getMyPayoutMethods(payload);

            if (res.success) {
                toast.success(res?.message);

                return res;
            } else {
                toast.error(res?.message);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || "Failed to fetch payout methods");

            return rejectWithValue(err?.response?.data);
        }
    }
);

export const createStripeAccountConnectionLinkFetch = createAsyncThunk(
    "/createStripeAccountConnectionLinkFetch", async (paymentMethodId, { rejectWithValue }) => {
        try {
            const res = await api.payoutMethods.createStripeAccountConnectionLink(paymentMethodId);

            if (res.success) {
                toast.success(res?.message);

                return res;
            } else {
                toast.error(res?.message);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || "Failed to create Stripe account link");

            return rejectWithValue(err?.response?.data);
        }
    }
);

export const generateStripeAccountLinkFetch = createAsyncThunk(
    "/generateStripeAccountLinkFetch", async (paymentMethodId, { rejectWithValue }) => {
        try {
            const res = await api.payoutMethods.generateStripeAccountLink(paymentMethodId);

            if (res.success) {
                toast.success(res?.message);

                return res;
            } else {
                toast.error(res?.message);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || "Failed to generate Stripe account link");

            return rejectWithValue(err?.response?.data);
        }
    }
);

export const addPayoutMethodsFetch = createAsyncThunk(
    "/addPayoutMethodsFetch", async (payload, { rejectWithValue }) => {
        try {
            const res = await api.payoutMethods.addPayoutMethod(payload);

            if (res.success) {
                toast.success(res?.message);

                return res;
            } else {
                toast.error(res?.message);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || "Failed to add payout method");

            return rejectWithValue(err?.response?.data);
        }
    }
);
