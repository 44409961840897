import {
    useEffect, useState
} from "react";
import { useDispatch } from "react-redux";
import {
    Link, useNavigate
} from "react-router-dom";
import api from "../../../api";
import { ReactComponent as CalendarIcon } from "../../../assets/img/calendar.svg";
import { ReactComponent as ChatIcon } from "../../../assets/img/chatDark.svg";
import ReschedulePrivateLessonModal from "../../../components/modals/ReschedulePrivateLessonModal";
import Button from "../../../components/ui/buttons/Button";
import PageLoader from "../../../components/ui/PageLoader";
import { getDateRange, getImageSrc, getTrainingStyle } from "../../../helpers/utils";
import {
    acceptRequestsPrivateLessonsFetch, rejectRequestsPrivateLessonFetch
} from "../../../redux/thunks/privateLessonsThunks";

export const RequestedPtivateLessons = () => {
    // const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getRequests = async () => {
        setIsLoading(true);
        const res = await api.requests.getRequestsPrivateLessons();

        setRequests(res?.data || []);
        setIsLoading(false);
    };

    useEffect(() => {
        getRequests();
    }, []);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!requests?.length) {
        return (
            <h2 className="heading text-center mt-28">
                There is no items now
            </h2>
        );
    }

    return (
        <>
            <div className="request-items pt-28">
                {
                    requests?.map((item) => (
                        <RequestItem
                            key={item.id}
                            data={item}
                            update={getRequests}
                        />
                    ))
                }
            </div>
            {/* 
            <ReschedulePrivateLessonModal
                data={[new Date()]}
                handleClose={() => setOpenRescheduleModal(false)}
                open={openRescheduleModal}
            /> */}
        </>
    );
};

function RequestItem({ data, update }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);

    const handleApprove = async (id) => {
        setIsApproving(true);
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(data.id)).unwrap();

        if (res?.success) {
            navigate(`/chat/${res.data?.chat?.id}`);
            update && update();
        }

        setIsApproving(false);
    };

    const handleReject = async (id) => {
        setIsRejecting(true);
        const res = await dispatch(rejectRequestsPrivateLessonFetch(data.id)).unwrap();

        if (res?.success) {
            update && update();
        }

        setIsRejecting(false);
    };

    const studentProfile = data.student?.studentProfile;

    return (
        <>
            <div className="request-item">
                <div className="request-item__user">
                    <div className="ico ico--md img-wrapper rounded-full">
                        <img
                            alt="student"
                            src={getImageSrc(studentProfile?.photo)}
                        />
                    </div>

                    <div className="col-group gap-4">
                        <h6 className="heading">
                            {studentProfile?.fullName}
                        </h6>

                        <p className="color-text">
                            {getDateRange(data?.dates)}
                        </p>
                    </div>
                </div>

                <div className="request-item__details">
                    <div className="col-group gap--xs">
                        <span className="label">
                            Total Price
                        </span>

                        <p className="fw-500 text--sm">
                            ${data?.priceInHour}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            People
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.peopleAttending}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            teaching
                        </span>

                        <p className="fw-500 text--sm">
                            {getTrainingStyle(data?.style)?.[0]?.title}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            Location
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.address}
                        </p>
                    </div>
                </div>

                <div className="request-item__action">
                    <button
                        className="btn btn--sm"
                        onClick={() => setOpenRescheduleModal(true)}
                        type="button"
                    >
                        <span className="ico">
                            <CalendarIcon />
                        </span>

                        RESCHEDULE
                    </button>

                    {/* <a href="#" className="btn btn--sm">Show Details</a>*/}

                    <button
                        className="btn btn--sm "
                        isLoading={isRejecting}
                        onClick={handleReject}
                    >
                        Decline
                    </button>

                    <Button
                        className="btn btn--sm btn--primary"
                        isLoading={isApproving}
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>

                    <Link
                        className="btn btn--sm btn--square notification"
                        count="1"
                        to={`/chat/${data?.chat?.id}`}
                    >
                        <span className="ico">
                            <ChatIcon />
                        </span>
                    </Link>
                </div>
            </div>

            <ReschedulePrivateLessonModal
                data={data.dates}
                handleClose={() => setOpenRescheduleModal(false)}
                id={data.id}
                open={openRescheduleModal}
            />
        </>
    );
}
