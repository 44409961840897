import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import ButtonBack from "../../../components/parts/ButtonBack";
import { EVENT_STEP, EVENT_TYPE, STEPS_ORDER } from "../../../constants/enums";
const icons = {
    date: (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 1.5V4.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M6.5 1.5V4.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M2.75 6.75H16.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                clipRule="evenodd"
                d="M14.75 3H4.25C3.42125 3 2.75 3.67125 2.75 4.5V14.25C2.75 15.0788 3.42125 15.75 4.25 15.75H14.75C15.5788 15.75 16.25 15.0788 16.25 14.25V4.5C16.25 3.67125 15.5788 3 14.75 3Z"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M5.75902 9.54675C5.65552 9.54675 5.57152 9.63075 5.57227 9.73425C5.57227 9.83775 5.65627 9.92175 5.75977 9.92175C5.86327 9.92175 5.94727 9.83775 5.94727 9.73425C5.94727 9.63075 5.86327 9.54675 5.75902 9.54675"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.50902 9.54675C9.40552 9.54675 9.32152 9.63075 9.32227 9.73425C9.32227 9.83775 9.40627 9.92175 9.50977 9.92175C9.61327 9.92175 9.69727 9.83775 9.69727 9.73425C9.69727 9.63075 9.61327 9.54675 9.50902 9.54675"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M13.259 9.54675C13.1555 9.54675 13.0715 9.63075 13.0723 9.73425C13.0723 9.83775 13.1563 9.92175 13.2598 9.92175C13.3633 9.92175 13.4473 9.83775 13.4473 9.73425C13.4473 9.63075 13.3633 9.54675 13.259 9.54675"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M5.75902 12.5467C5.65552 12.5467 5.57152 12.6307 5.57227 12.7342C5.57227 12.8377 5.65627 12.9217 5.75977 12.9217C5.86327 12.9217 5.94727 12.8377 5.94727 12.7342C5.94727 12.6307 5.86327 12.5467 5.75902 12.5467"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.50902 12.5467C9.40552 12.5467 9.32152 12.6307 9.32227 12.7342C9.32227 12.8377 9.40627 12.9217 9.50977 12.9217C9.61327 12.9217 9.69727 12.8377 9.69727 12.7342C9.69727 12.6307 9.61327 12.5467 9.50902 12.5467"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )



    ,


    info: (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="9.49891"
                cy="9"
                r="6.75281"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M8.75 11.6261H10.4827"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.61872 11.6261V8.43727H8.75586"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.57445 6.18427C9.57445 6.28787 9.49047 6.37185 9.38687 6.37185C9.28328 6.37185 9.1993 6.28787 9.1993 6.18427C9.1993 6.08068 9.28328 5.99669 9.38687 5.99669"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.38684 5.9967C9.49043 5.9967 9.57441 6.08068 9.57441 6.18427"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )



    ,


    pay: (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.1123 13.041C5.3468 14.6805 7.30205 15.75 9.5003 15.75C13.2128 15.75 16.2503 12.7125 16.2503 9C16.2503 5.2875 13.2128 2.25 9.5003 2.25"
                stroke="#02020A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M4.112 13.041C3.2615 11.9115 2.75 10.5143 2.75 9C2.75 5.2875 5.7875 2.25 9.5 2.25"
                stroke="#02020A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.3125 5.25V6.1875"
                stroke="#02020A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.3125 12.75V11.8125"
                stroke="#02020A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M11.1778 7.5C11.129 6.768 10.5253 6.1875 9.78125 6.1875H8.75375C8.02625 6.1875 7.4375 6.777 7.4375 7.50375C7.4375 8.1075 7.8485 8.634 8.4335 8.781L10.1908 9.222C10.7765 9.369 11.1868 9.8955 11.1868 10.4993C11.1868 11.2268 10.5975 11.8155 9.87075 11.8155H8.75375C8.00975 11.8155 7.40625 11.235 7.3575 10.503"
                stroke="#02020A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )



    ,


    approval: (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="9.5"
                cy="9"
                r="8.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.5 5.25V9H11.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )



    ,


    contract: (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.24808 15.75H12.7481C14.551 15.75 15.7481 14.3597 15.7481 12.75V5.25C15.7481 3.64025 14.551 2.25 12.7481 2.25H6.24808C4.44517 2.25 3.24808 3.64025 3.24808 5.25V12.75C3.24808 14.3597 4.44517 15.75 6.24808 15.75Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M6.49219 6.24121H6.49996"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M6.49219 10.4912H6.49996"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.49219 6.24121H12.4922"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.49219 10.4912H12.4922"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M6.49219 8.36621H12.4922"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )



    ,


    design: (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.00391 6.50368L7.00391 3.50368"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M9.713 6.213L6.18934 2.68934C5.90804 2.40804 5.5265 2.25 5.12868 2.25C4.73085 2.25 4.34932 2.40804 4.06802 2.68934L3.18934 3.56802C2.90804 3.84932 2.75 4.23086 2.75 4.62868C2.75 5.02651 2.90804 5.40804 3.18934 5.68934L6.869 9.369"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M12.2868 8.78666L14.4666 10.9665C14.796 11.2958 15.0441 11.6973 15.1914 12.1391L16.2302 15.2558C16.2752 15.3905 16.2401 15.5391 16.1397 15.6395C16.0392 15.74 15.8906 15.775 15.7559 15.7301L12.6393 14.6912C12.1974 14.544 11.796 14.2958 11.4666 13.9665L9.13086 11.6307"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M5.58398 11.28C6.62424 9.5584 7.88484 7.98 9.33382 6.58483C12.3569 3.56175 15.3852 1.68872 16.0977 2.40126C16.8103 3.1138 14.9372 6.14211 11.914 9.165C10.5189 10.6141 8.9406 11.8748 7.21898 12.915"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                clipRule="evenodd"
                d="M5.7235 15.2161L3.682 15.7265C3.4264 15.7904 3.15601 15.7155 2.96971 15.5292C2.7834 15.3429 2.70851 15.0725 2.77241 14.8169L3.28404 12.7704C3.50788 11.8751 4.31323 11.2476 5.23612 11.2496H5.24133C6.3514 11.2519 7.2499 12.1527 7.24936 13.2628V13.2628C7.24893 14.1868 6.61992 14.992 5.7235 15.2161Z"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )



    ,


    booking: (
        <svg
            fill="none"
            height="18"
            viewBox="0 0 19 18"
            width="19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_310_25898)">
                <path
                    d="M14.5625 15.75H4.4375C4.28832 15.75 4.14524 15.6907 4.03975 15.5852C3.93426 15.4798 3.875 15.3367 3.875 15.1875V2.8125C3.875 2.66332 3.93426 2.52024 4.03975 2.41475C4.14524 2.30926 4.28832 2.25 4.4375 2.25H11.1875L15.125 6.1875V15.1875C15.125 15.3367 15.0657 15.4798 14.9602 15.5852C14.8548 15.6907 14.7117 15.75 14.5625 15.75Z"
                    stroke="#02020A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />

                <path
                    d="M11.1875 2.25V6.1875H15.125"
                    stroke="#02020A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />

                <path
                    d="M7.25 9.5625H11.75"
                    stroke="#02020A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />

                <path
                    d="M7.25 11.8125H11.75"
                    stroke="#02020A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>

            <defs>
                <clipPath id="clip0_310_25898">
                    <rect
                        fill="white"
                        height="18"
                        transform="translate(0.5)"
                        width="18"
                    />
                </clipPath>
            </defs>
        </svg>
    )
};
const PlanningSteps = ({ isActive, isCreated, step, chatId, eventType = EVENT_TYPE.seminar }) => {
    const eventLabels = {
        [EVENT_TYPE.seminar]: "Seminar",
        [EVENT_TYPE.camp]: "Camp",
        [EVENT_TYPE.tour]: "Tour",
    }
    const menuItems = [
        {
            title: "Time & Date",
            to: "",
            icon: "date",
            id: EVENT_STEP.date
        }, {
            title: `${eventLabels[eventType]} Information`,
            to: "seminar-info",
            icon: "info",
            id: EVENT_STEP.details
        }, {
            title: "Design & Text",
            to: "design-text",
            icon: "design",
            editableWhenActive: true,
            id: EVENT_STEP.design
        }, {
            title: "Booking Specific Terms",
            to: "booking",
            icon: "booking",
            id: EVENT_STEP.terms,
            hideOnCreation: true
        }
    ];
    const disabledStyles = {
        pointerEvents: "none",
        opacity: 0.7,
    }
    const currentStepIndex = STEPS_ORDER.indexOf(step);

    return (
        <section className="page-menu-section">
            <div className="container relative">
                <ButtonBack />
                <div className="container overflow-auto page-menu-border">
                    <ul className="page-menu-list">
                        {
                            menuItems.filter(({ editableWhenActive, hideOnCreation }) => {
                                if (isActive) return editableWhenActive;
                                if (!chatId) return !hideOnCreation;
                                return true
                            })
                                .map((item, index) => (
                                    <li
                                        key={item.title}
                                        className="page-menu-list__item"
                                    >
                                        <NavLink
                                            className={({ isActive }) => classNames("page-menu-list__link", { "active": isActive })}
                                            end
                                            onClick={e => chatId && currentStepIndex < index ? e.preventDefault() : {}}
                                            style={chatId && currentStepIndex < index ? disabledStyles : {}}
                                            to={item.to}
                                        >
                                            <span className="ico">
                                                {icons[item.icon]}
                                            </span>

                                            <span className="page-menu-list__text">
                                                {item.title}
                                            </span>
                                        </NavLink>
                                    </li>
                                ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default PlanningSteps;
