import classNames from "classnames";
import { addDays } from "date-fns";
import moment from "moment";
import DatePicker from "../../../components/ui/formElements/DatePicker";

const DateRangeSelect = ({ handleChange, disabled, isErrorsShown, errors, dates, startAt, endAt, minRange = 1 }) => {
    const handleStartDateChange = (date) => {
        const isoDate = new Date(date).toISOString();
        handleChange("startAt", isoDate);

        const newMinEndDate = addDays(new Date(date), minRange).toISOString();
        if (!endAt || new Date(endAt) < new Date(newMinEndDate)) {
            handleChange("endAt", newMinEndDate);
        }
    };

    return (
        <div className={classNames("schedule-group", { "block-disabled": disabled })}>
            <div className="input-date-time-group">
                <div className="flex-1">
                    <DatePicker
                        label="Start Date"
                        minDate={moment().toDate()}
                        placeholderText="Select Start Date"
                        selected={startAt}
                        onChange={handleStartDateChange}
                        error={!disabled && isErrorsShown && errors?.startAt && !startAt}
                    />
                </div>

                <span className="input-delimiter" style={{ marginTop: 26 }} />

                <div className="flex-1">
                    <DatePicker
                        label="End Date"
                        minDate={startAt ? addDays(new Date(startAt), minRange) : moment().toDate()}
                        placeholderText="Select End Date"
                        selected={endAt}
                        onChange={(date) => handleChange("endAt", new Date(date).toISOString())}
                        error={!disabled && isErrorsShown && errors?.endAt && !endAt}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateRangeSelect;
