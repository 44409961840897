import { createSlice, current } from "@reduxjs/toolkit";
import {
    getSeminarsMy,
    getSeminarsById,
    createSeminar,
    uploadSeminarPhotos,
    deleteSeminarPhoto,
    addDatesToSeminar,
    updateSeminarDate,
    deleteSeminarDate,
    getSeminarsMyAvailableToInstructorFetch,
    getAllSeminarsWithFiltersFetch,
    updateSeminarFetch,
    getSeminarContractFetch,
    getActiveSeminarsByInstructorIdFetch
} from "../thunks/seminarsThunks";
const initialState = {
    seminars: [],
    seminarDetails: null,
    isLoading: false,
    error: null,
    contract: {
        content: null,
        sign_instructor: null,
        sign_academy: null
    },
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const seminarsSlice = createSlice({
    name: "seminars",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSeminarsMy.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getSeminarsMy.fulfilled, (state, action) => {
                state.isLoading = false;
                state.seminars = action.payload.data;
                state.pagination = action.payload.pagination;
            })
            .addCase(getSeminarsMy.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getSeminarsMyAvailableToInstructorFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getSeminarsMyAvailableToInstructorFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.seminars = action.payload;
            })
            .addCase(getSeminarsMyAvailableToInstructorFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getActiveSeminarsByInstructorIdFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getActiveSeminarsByInstructorIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.seminars = action.payload;
            })
            .addCase(getActiveSeminarsByInstructorIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getSeminarsById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.seminarDetails = null;
            })
            .addCase(getSeminarsById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.seminarDetails = action.payload;
            })
            .addCase(getSeminarsById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.seminarDetails = null;
            })
            .addCase(createSeminar.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createSeminar.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.seminars.push(action.payload);
            })
            .addCase(createSeminar.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSeminarFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateSeminarFetch.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                const index = state.seminars?.findIndex((seminar) => seminar?.id === payload?.id);
                if (index > -1) {
                    state.seminars[index] = payload;
                    state.seminarDetails = payload;
                }
            })
            .addCase(updateSeminarFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(uploadSeminarPhotos.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(uploadSeminarPhotos.fulfilled, (state, action) => {
                state.isLoading = false;

                if (state.seminarDetails && state.seminarDetails.id === action.meta.arg.seminarId) {
                    state.seminarDetails.photos = [...state.seminarDetails.photos, ...action.payload];
                }
            })
            .addCase(uploadSeminarPhotos.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteSeminarPhoto.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteSeminarPhoto.fulfilled, (state, action) => {
                state.isLoading = false;

                if (state.seminarDetails) {
                    state.seminarDetails.photos = state.seminarDetails.photos.filter((photo) => photo.id !== action.meta.arg);
                }
            })
            .addCase(deleteSeminarPhoto.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addDatesToSeminar.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addDatesToSeminar.fulfilled, (state, action) => {
                state.isLoading = false;

                if (state.seminarDetails) {
                    state.seminarDetails.dates = [...state.seminarDetails.dates, ...action.payload];
                }
            })
            .addCase(addDatesToSeminar.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSeminarDate.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateSeminarDate.fulfilled, (state, action) => {
                state.isLoading = false;

                if (state.seminarDetails) {
                    state.seminarDetails.dates = state.seminarDetails.dates.map((date) => (date.id === action.meta.arg.dateId
                        ? action.payload
                        : date));
                }
            })
            .addCase(updateSeminarDate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteSeminarDate.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteSeminarDate.fulfilled, (state, action) => {
                state.isLoading = false;

                if (state.seminarDetails) {
                    state.seminarDetails.dates = state.seminarDetails.dates.filter((date) => date.id !== action.meta.arg.dateId);
                }
            })
            .addCase(deleteSeminarDate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getAllSeminarsWithFiltersFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.seminars = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllSeminarsWithFiltersFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.seminars = [];
                state.pagination = {};
            })
            .addCase(getAllSeminarsWithFiltersFetch.pending, (state, action) => {
                state.isLoading = true;
                state.seminars = [];
                state.pagination = {};
            })
            .addCase(getSeminarContractFetch.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.contract = payload;
            })
            .addCase(getSeminarContractFetch.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getSeminarContractFetch.pending, (state, action) => {
                state.isLoading = true;
            });
    }
});

export const getSeminarDetailes = (state) => state.seminars.seminarDetails;
export const getSeminars = (state) => state.seminars.seminars;
export default seminarsSlice.reducer;
