import Color from "color";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { toggle } from "../../../helpers/utils";
import RangeSlider from "./RangeSlider";
const backgroundRanges = [
    {
        label: "Padding Top",
        name: "paddingTop",
        step: 1,
        min: 0,
        max: 100,
        postfix: "px",
        defaultValue: 0
    },
    {
        label: "Padding Left",
        name: "paddingLeft",
        step: 1,
        min: 0,
        max: 100,
        postfix: "px",
        defaultValue: 0
    },
    {
        label: "Padding Right",
        name: "paddingRight",
        step: 1,
        min: 0,
        max: 200,
        postfix: "px",
        defaultValue: 0
    },
    {
        label: "Padding Bottom",
        name: "paddingBottom",
        step: 1,
        min: 0,
        max: 200,
        postfix: "px",
        defaultValue: 0
    },
    {
        label: "Margin Top",
        name: "marginTop",
        step: 1,
        min: 0,
        max: 100,
        postfix: "px",
        defaultValue: 0
    },
    {
        label: "Margin Left",
        name: "marginLeft",
        step: 1,
        min: 0,
        max: 100,
        postfix: "px",
        defaultValue: 0
    },
    {
        label: "Margin Right",
        name: "marginRight",
        step: 1,
        min: 0,
        max: 200,
        postfix: "px",
        defaultValue: 0
    },
    {
        label: "Margin Bottom",
        name: "marginBottom",
        step: 1,
        min: 0,
        max: 200,
        postfix: "px",
        defaultValue: 0
    }
];

const BackgroundEditor = ({ currentStyles, handleChange }) => {
    const getBackgroundOpacity = (color) => {
        return Color(color).alpha();
    };

    const adjustOpacity = (color, opacity) => {
        return Color(color).alpha(opacity)
            .hsl()
            .string();
    };

    const rgbaToHex = (rgba) => {
        return Color(rgba).hex();
    };

    return (
        <div>
            <label className="label-row">
                <label className="label">
                    Background
                </label>

                <CheckboxSwitch
                    checked={!!currentStyles?.backgroundColor}
                    onChange={({ target }) => handleChange("backgroundColor", toggle(currentStyles?.backgroundColor, target.value))}
                    value="#ffffff"
                />
            </label>

            {currentStyles?.backgroundColor && (
                <div className="py-4 border-bottom">
                    <div className="row gutters-form-7">
                        <div className="col-12">
                            <div className="flex gap-12">
                                <label className="picker-custom btn btn--square btn--sm">
                                    <input
                                        onChange={({ target }) => handleChange("backgroundColor", target.value)}
                                        type="color"
                                        value={rgbaToHex(currentStyles?.backgroundColor) || "#ffffff"}
                                    />
                                </label>

                                <RangeSlider
                                    defaultValue={0}
                                    label="Opacity"
                                    max={1}
                                    min={0}
                                    onChange={(value) => handleChange("backgroundColor", adjustOpacity(currentStyles?.backgroundColor, value))}
                                    step={0.01}
                                    value={getBackgroundOpacity(currentStyles?.backgroundColor)}
                                />
                            </div>
                        </div>

                        {backgroundRanges.map((range) => (
                            <div
                                className={"col-12"}
                                key={range.name}
                            >
                                <RangeSlider
                                    label={range.label}
                                    value={currentStyles?.[range.name]}
                                    onChange={(value) => handleChange(range.name, value)}
                                    min={range.min}
                                    max={range.max}
                                    step={range.step}
                                    defaultValue={range?.defaultValue}
                                    postfix={range?.postfix}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BackgroundEditor;
