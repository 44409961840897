import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Link, NavLink, useNavigate
} from "react-router-dom";
import { ReactComponent as BellIcon } from "../../assets/img/bell-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/img/chat-icon.svg";
import { USER_ROLE } from "../../constants/enums";
import { getImageSrc } from "../../helpers/utils";
import { getUnreadMessages } from "../../redux/slices/chatsSlice";
import { getUnreadNotificationsCount, getUnreviewdNotificationsCount, setUnreviewedNotificationsCount } from "../../redux/slices/notificationsSlice";
import { getAllNotificationsFetch, readNotificationsFetch, reviewNotificationsFetch } from "../../redux/thunks/notificationsThunks";
import PlanningModal from "../modals/PlanningModal";
import Button from "../ui/buttons/Button";
import DropDown from "../ui/DropDown";

export const UserPart = ({ handelLoginModal, handelSingUp, user, handleLogout }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'header' });
    const [planningModalOpen, setPlanningModalOpen] = useState(false);
    const unreadedMessages = useSelector(getUnreadMessages);

    const role = user?.role || "";
    const userIs = {
        instructor: user?.role === USER_ROLE.instructor,
        academy: user?.role === USER_ROLE.academy,
        student: user?.role === USER_ROLE.student,
        guest: user?.role === USER_ROLE.guest
    };
    const hasProfile = userIs.academy || userIs.instructor;
    const hasAccount = userIs.academy || userIs.instructor || userIs.student;

    const profileLink = userIs.instructor
        ? `/instructors/${user.id}`
        : userIs.academy
            ? `/academies/${user.id}`
            : "#";




    return (
        <>
            {hasAccount && (
                <>
                    <NavLink
                        className="btn btn--square btn--md relative"
                        to="/chat"
                    >
                        <ChatIcon />

                        {unreadedMessages && unreadedMessages.length > 0 && <div className="chat-notification" />}
                    </NavLink>
                    <NotificationsDropdown />

                    {hasProfile && (
                        <button
                            className="btn btn--md btn--primary ml-12 hidden lg-flex"
                            onClick={() => {
                                setPlanningModalOpen(true);
                            }}
                        >
                            <span>
                                {t('plan_seminar')}
                            </span>
                        </button>
                    )}

                    <DropDown
                        buttonClasses="header__avatar"
                        closeYourSelf={true}
                        dropDownClasses="hidden xl-flex"
                        dropDownListClasses="dropdown-menu"
                        labelButton={<img
                            alt={user?.profile?.fullName || "User"}
                            className="ico ico--md"
                            src={getImageSrc(user?.profile?.photo, require("../../assets/img/avatar-placeholder.svg").default)}
                            style={{
                                borderRadius: "50%",
                                overflow: "hidden",
                                background: "#F5F5F5"
                            }}
                        />}
                        position="right"
                    >
                        <div className="dropdown-menu__header">
                            <Link to={profileLink}>
                                <h6 className="heading">
                                    {user?.profile?.fullName || user?.profile?.name || user?.fullName || "User"}
                                </h6>
                            </Link>

                            <p className="desc-heading">
                                {user?.email || "your@email.com"}
                            </p>
                        </div>

                        {
                            role && role !== USER_ROLE.guest && (
                                <div className="dropdown-menu__body">
                                    {hasProfile && (
                                        // <div className="dropdown-menu__body--item">
                                        //     <button
                                        //         className="btn btn--md btn--primary justify-between"
                                        //         onClick={() => {
                                        //             setPlanningModalOpen(true);
                                        //         }}
                                        //     >
                                        //         <span>
                                        //             {t('plan_seminar_camp')}
                                        //         </span>

                                        //         <span className="ico">
                                        //             <svg
                                        //                 fill="none"
                                        //                 height="14"
                                        //                 viewBox="0 0 14 14"
                                        //                 width="14"
                                        //                 xmlns="http://www.w3.org/2000/svg"
                                        //             >
                                        //                 <path
                                        //                     d="M5.25 2.625L9.625 7L5.25 11.375"
                                        //                     stroke="#02020A"
                                        //                     strokeLinecap="square"
                                        //                     strokeWidth="1.6"
                                        //                 />
                                        //             </svg>
                                        //         </span>
                                        //     </button>
                                        // </div>
                                        <Link
                                            to={profileLink}
                                            className="btn btn--md btn--primary justify-between"
                                        >
                                            <span className="info">View My Profile</span>
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    width="14"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M5.25 2.625L9.625 7L5.25 11.375"
                                                        stroke="#02020A"
                                                        strokeLinecap="square"
                                                        strokeWidth="1.6"
                                                    />
                                                </svg>
                                            </span>

                                        </Link>
                                    )}

                                    <div className="dropdown-menu__body--item">
                                        <NavLink
                                            className="btn btn--md btn--thin"
                                            to="/my-seminars"
                                        >
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    width="22"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        clipRule="evenodd"
                                                        d="M2.75 2.75H9.16667V9.16667H2.75V2.75Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M12.8334 2.75H19.25V9.16667H12.8334V2.75Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M2.75 12.833H9.16667V19.2497H2.75V12.833Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M12.8334 12.833H19.25V19.2497H12.8334V12.833Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>
                                            </span>

                                            <span>
                                                {t('my_seminars_camps')}
                                            </span>
                                        </NavLink>
                                        {!userIs.student && <NavLink
                                            className="btn btn--md btn--thin"
                                            to="/requests"
                                        >
                                            <span className="ico">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={18} viewBox="0 0 512 512">
                                                    <path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75l-8.7 0-32 0-96 0c-35.3 0-64 28.7-64 64l0 96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-128 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4L480 32zm-64 76.7L416 240l0 131.3C357.2 317.8 280.5 288 200.7 288l-8.7 0 0-96 8.7 0c79.8 0 156.5-29.8 215.3-83.3z" stroke="currentColor" fill="currentColor" />
                                                </svg>
                                            </span>

                                            <span>
                                                Requests and Invitations
                                            </span>
                                        </NavLink>}

                                        <NavLink
                                            className="btn btn--md btn--thin"
                                            to="/settings/my-profile"
                                        >
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    width="22"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.7501 9.2503C13.7167 10.2169 13.7167 11.7839 12.7501 12.7505C11.7836 13.717 10.2165 13.717 9.24994 12.7505C8.28339 11.7839 8.28339 10.2169 9.24994 9.2503C10.2165 8.28375 11.7836 8.28375 12.7501 9.2503"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M4.81246 11C4.81246 11.2723 4.83721 11.5445 4.87021 11.8085L3.41455 12.947C3.09188 13.2 3.00388 13.6519 3.20921 14.0067L4.50355 16.2461C4.70796 16.6008 5.14246 16.7503 5.52288 16.5981L6.82638 16.0747C7.08396 15.9711 7.37088 16.0123 7.60188 16.1654C7.80355 16.2993 8.01255 16.4221 8.22888 16.5321C8.47638 16.6577 8.65605 16.8823 8.69546 17.1573L8.89438 18.5442C8.95213 18.9493 9.29955 19.25 9.70838 19.25H12.2906C12.6995 19.25 13.0469 18.9493 13.1046 18.5442L13.3035 17.1582C13.343 16.8832 13.5245 16.6568 13.7729 16.5321C13.9883 16.4239 14.1964 16.302 14.3971 16.1691C14.63 16.0151 14.9178 15.9711 15.1763 16.0756L16.477 16.5981C16.8565 16.7503 17.291 16.6008 17.4964 16.2461L18.7907 14.0067C18.996 13.6519 18.908 13.1991 18.5854 12.947L17.1297 11.8085C17.1627 11.5445 17.1875 11.2723 17.1875 11C17.1875 10.7278 17.1627 10.4555 17.1297 10.1915L18.5854 9.053C18.908 8.8 18.996 8.34808 18.7907 7.99333L17.4964 5.75392C17.292 5.39917 16.8575 5.24975 16.477 5.40192L15.1763 5.92442C14.9178 6.028 14.63 5.98492 14.3971 5.83092C14.1964 5.698 13.9883 5.57608 13.7729 5.46792C13.5245 5.34325 13.343 5.11683 13.3035 4.84183L13.1055 3.45583C13.0478 3.05067 12.7004 2.75 12.2915 2.75H9.7093C9.30046 2.75 8.95305 3.05067 8.8953 3.45583L8.69546 4.84367C8.65605 5.11775 8.47546 5.34325 8.22888 5.46883C8.01255 5.57883 7.80355 5.70258 7.60188 5.8355C7.36996 5.98767 7.08305 6.02892 6.82546 5.92533L5.52288 5.40192C5.14246 5.24975 4.70796 5.39917 4.50355 5.75392L3.20921 7.99333C3.00388 8.34808 3.09188 8.80092 3.41455 9.053L4.87021 10.1915C4.83721 10.4555 4.81246 10.7278 4.81246 11V11Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>
                                            </span>

                                            <span>
                                                {t('account_settings')}
                                            </span>
                                        </NavLink>
                                    </div>
                                </div>
                            )}

                        <div className="dropdown-menu__footer">
                            <button
                                className="btn btn--md btn--thin"
                                onClick={handleLogout}
                            >
                                <span className="ico">
                                    <svg
                                        fill="none"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        width="22"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.9167 11.0003H2.75"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />

                                        <path
                                            d="M5.95833 7.79199L2.75 11.0003L5.95833 14.2087"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />

                                        <path
                                            d="M11 2.75C15.5563 2.75 19.25 6.44365 19.25 11C19.25 15.5563 15.5563 19.25 11 19.25"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />
                                    </svg>
                                </span>

                                <span>
                                    {t('log_out')}
                                </span>
                            </button>
                        </div>
                    </DropDown>
                </>
            )}

            {
                planningModalOpen && (
                    <PlanningModal
                        handleClose={() => setPlanningModalOpen(false)}
                        open={planningModalOpen}
                    />
                )}
        </>
    );
};


function NotificationsDropdown() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const unreviewedCount = useSelector(getUnreviewdNotificationsCount);
    const unreadedCount = useSelector(getUnreadNotificationsCount);

    const { notifications, isLoading, pagination } = useSelector((state) => state.notifications);

    const handleRead = (id) => {
        if (!id) return;
        dispatch(readNotificationsFetch({ body: { ids: [id] } }));
    };

    const handleReadAll = () => {
        dispatch(readNotificationsFetch({ readUnreadedAll: true }));
    };

    const handleReviewAll = () => {
        dispatch(reviewNotificationsFetch({
            reviewAll: true
        }))
        dispatch(setUnreviewedNotificationsCount(0))
    }

    const requestTypes = ['request_seminar', 'request_plancamp', 'request_private_lesson']


    const handleClick = (notification) => {
        const id = notification.id
        if (!id) return
        let link;
        if (requestTypes.includes(notification?.type) && notification?.payload?.chatId) {
            link = `/chat/${notification?.payload?.chatId}`
        } else if (notification?.payload?.instructorId) {
            link = `/instructors/${notification?.payload?.instructorId}`
        } else {
            link = `/requests`
        }
        handleRead(id)
        navigate(link);
    }

    const handleOpen = () => {
        handleReviewAll()
        dispatch(getAllNotificationsFetch(new URLSearchParams({
            // sort_by: "isReaded",
            sort_as: "asc",
            limit: 4
        }).toString()));
    }
    return (
        <DropDown
            buttonClasses="btn btn--square btn--md"
            dropDownClasses="hidden xl-flex"
            dropDownListClasses="dropdown-menu dropdown-menu-notes"
            onOpen={handleOpen}
            closeYourSelf={false}
            labelButton={<>
                <BellIcon />
                {unreviewedCount > 0 && <div className="chat-notification" />}
            </>}
            position="right"
            isLoading={isLoading}
        >
            <div className="dropdown-menu__header dropdown-menu__header-row">
                <h6 className="heading">
                    Notifications
                </h6>
                {
                    !!notifications.length && !!unreadedCount && (
                        <Button
                            className="btn underline max-w-max btn-size-13"
                            onClick={handleReadAll}
                        >
                            Read all
                        </Button>
                    )
                }
            </div>
            <div className="dropdown-menu__body">
                {notifications?.length ? (
                    <ul className="notification-drop-list my-3">
                        {notifications.map((note, index) => (
                            <li
                                onClick={() => handleClick(note)}
                                style={{
                                    cursor: "pointer"
                                }}
                                key={index}
                            >
                                <div className="row-group gap--sm">
                                    <div className="row-group gap--md ">
                                        <div className="image-wrapper avatar">
                                            <img
                                                alt="instructor"
                                                src={getImageSrc(note?.payload?.photo || note?.payload?.user?.photo)}
                                            />
                                        </div>
                                        <div className="col-group gap--xs btn-size-13">
                                            <h6>
                                                {note?.payload?.fullName || note?.payload?.user?.fullName}
                                            </h6>
                                            <p className="color-text">
                                                {note.message}
                                            </p>
                                        </div>
                                    </div>
                                    <span className="blue-indicator" style={{ opacity: note.isReaded ? "0" : "1" }}></span>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="color-text text-14 px-3 text-center">There is no new notifications</p>
                )}
            </div>
            <div className="dropdown-menu__footer">
                <Link
                    to="/notifications"
                    className="btn btn--primary btn--sm  btn-size-13"
                >
                    <span className="w-full"> See all Notifications</span>
                </Link>
            </div>
        </DropDown>
    )
}