import React from "react";
import CardSeminar from "./cards/CardSeminar";
import { useTranslation } from "react-i18next";

const ToursSeminarsList = ({
    data = [], sectionTitle, sectionClasses = "pb-160", swiperClasses, isMobile = false
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });

    return (
        <section className={`${sectionClasses} swiper-mobile-custom`}>
            <div className="container">

                <div className="flex items-center justify-between flex-wrap mb-32">
                    <h4 className="heading">
                        {sectionTitle}
                    </h4>
                </div>

                {
                    data?.length
                        ? (
                            <div className="row gx-24 gy-70 mb-70">
                                {
                                    data.map((item, index) => (
                                        <div
                                            key={item.id}
                                            className="col-8 col-lg-4"
                                        >
                                            <CardSeminar data={item} />
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        : (
                            <h4 className="mb-24">
                                {t('seminars.no_data')}
                            </h4>
                        )


                }
            </div>
        </section>
    );
};

export default ToursSeminarsList;
