import React, { useState } from "react";
import Button from "../buttons/Button";

const InputPass = ({
    label,
    placeholder,
    forgotPass = true,
    forgotPassClick,
    inputClass,
    formGroupClass,
    value,
    ...props
}) => {
    const [inputType, setInputType] = useState("password");

    const showPassHandler = () => {
        setInputType((prev) => {
            if (prev === "password") return "text";

            if (prev === "text") return "password";
        });
    };

    return (
        <div className={`form-group ${formGroupClass}`}>
            {(forgotPass || label) && (
                <div className="label-row">
                    {label && (
                        <label className="label">
                            {label}
                        </label>
                    )}

                    {forgotPass && (
                        <Button
                            className="color-secondary btn-link"
                            onClick={forgotPassClick}
                        >
                            <span className="text--xs">
                                Forgot Password?
                            </span>
                        </Button>
                    )}
                </div>
            )}

            <div className="input-wrapper">
                <Button
                    className="ico"
                    onClick={showPassHandler}
                    type="button"
                >
                    {inputType === "text" && (
                        <span className="btn-ico">
                            <img src={require("../../../assets/img/eye-false.svg").default} />
                        </span>
                    )}

                    {inputType === "password" && (
                        <span className="btn-ico">
                            <img src={require("../../../assets/img/eye-true.svg").default} />
                        </span>
                    )}
                </Button>

                <input
                    className={`input ${inputClass}`}
                    placeholder={placeholder}
                    type={inputType}
                    value={value}
                    {...props}
                />
            </div>
        </div>
    );
};

export default InputPass;
