import React, {
    useState, useEffect
} from "react";
import FQA from "../components/parts/FQA";
import WhyChoseUs from "../components/parts/WhyChoseUs";
import TopInstructors from "../components/parts/TopInstructors";
import SeminarsSliderSection from "../components/parts/SeminarsSliderSection";
import HowPlatformWork from "../components/parts/HowPlatformWork";
import api from "../api";
import PageLoader from "../components/ui/PageLoader";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    getAuthorized, getUser, setAuthModalOpen
} from "../redux/slices/applicationSlice";
import { getImageSrc } from "../helpers/utils";
import SingUpModal from "../components/modals/singUpModals/SingUpModal";
import { ReactComponent as ChevronNextIcon } from "../assets/img/ico-chevron--next.svg";
import AuthProvider, { AuthModals } from "../components/modals/singUpModals/AuthProvider";
import PlanningModal from "../components/modals/PlanningModal";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import findValue from "../helpers/findValue";

const lngs = {
    en: { nativeName: 'English' },
    de: { nativeName: 'Deutsch' }
};

const HomePage = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'home' });
    const [pageData, setPageData] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [planModalOpen, setPlanModalOpen] = useState(false);
    const user = useSelector(getUser);
    const isAuthorized = useSelector(getAuthorized);

    const getPageData = async (lang) => {
        const res = await api.pages.getPagesByPath("/", lang);

        setPageData(res.data);
    };

    useEffect(() => {
        getPageData(i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);
    const getValue = (key) => findValue(pageData?.blocks, key);

    const headText = () => {
        if (!isAuthorized) {
            // return t("title");
            return (
                <Trans
                    i18nKey="home.title"
                    components={{
                        1: <span style={{ color: '#00F4F1' }} />,
                    }}
                />
            );
        }

        if (user && user.role !== "student") {
            return (
                <Trans
                    i18nKey="home.title"
                    components={{
                        1: <span style={{ color: '#00F4F1' }} />,
                    }}
                />
            )
        } else {
            return <Trans
                i18nKey="home.check_out"
                components={{
                    1: <span style={{ color: '#00F4F1' }} />,
                }}
            />
        }
    };

    const buttonActionText = () => {
        if (!isAuthorized) {
            return "Sign Up";
        }

        if (user && user.role !== "student") {
            return "Plan Seminar";
        } else {
            return "Upcoming Seminars";
        }
    };

    const heroButtonData = (() => {
        if (!isAuthorized) {
            return {
                action: () => dispatch(setAuthModalOpen(AuthModals.singUp)),
                text: t('call_to_action_button_not_logged_in')
            }
        } 
        if (user && user.role !== "student") {
            return {
                action: () => setPlanModalOpen(true),
                text: t('plan_seminar')
            };
        } else {
            return {
                action: () => navigate("/my-seminars"),
                text: t('call_to_action_button_student')
            };
        }
    })();

    return (
        pageData
            ? (
                <>
                    <section className="first-screen first-screen-home">
                        <div className="firts-screen-bg">
                            <img
                                alt="hero"
                                src={getImageSrc(getValue('header_background_image'), require("../assets/img/first-screen-bg-1.jpg"))}
                            />

                            <div className="home-bg-gradient" />

                            <div className="firts-screen-bg-effect" />
                        </div>

                        <div className="container">
                            <div className="first-screen-body mw-600">

                                <h1
                                    className="heading mb-42 max-sm-text-center"
                                // dangerouslySetInnerHTML={{ __html: headText() || "Book Your <br /><span className=\"color-primary\">Seminar</span> Today" }}
                                >
                                    {headText()}
                                </h1>



                                <p
                                    className="first-screen-body__description mb-60"
                                >
                                    {t('description')}
                                </p>



                                <button
                                    className="btn btn--primary btn--lg max-md-w-full"
                                    onClick={heroButtonData.action}
                                >
                                    {heroButtonData.text}

                                    <span className="ico ico-14">
                                        <ChevronNextIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </section>

                    <HowPlatformWork pageData={pageData} />

                    <SeminarsSliderSection sectionTitle={t('upcoming_seminars')} />

                    <TopInstructors pageData={pageData} />

                    <WhyChoseUs pageData={pageData} />

                    <FQA pageData={pageData} />

                    <AuthProvider />

                    {
                        planModalOpen && (
                            <PlanningModal
                                handleClose={() => setPlanModalOpen(false)}
                                open={planModalOpen}
                            />
                        )}
                </>
            )


            : <PageLoader />
    );
};

export default HomePage;
