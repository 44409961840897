import { Country } from "country-state-city";
import React, {
    useEffect, useState
} from "react";
import AccordeonTypeArrow from "../../components/ui/accordeons/AccordeonTypeArrow";
import SelectOne from "../../components/ui/formElements/SelectOne";
import {
    useDispatch, useSelector
} from "react-redux";
import PageLoader from "../../components/ui/PageLoader";
import { getAllAcademiesFetch } from "../../redux/thunks/academyGeneralInfoThunks";
import { Link } from "react-router-dom";
import MapBox from "../../components/parts/mapBox/MapBox";
import { ReactComponent as ListIcon } from "../../assets/img/list.svg";
import { ReactComponent as MapIcon } from "../../assets/img/map.svg";

const AcademiesPage = () => {
    const dispatch = useDispatch();
    const { academies, isLoading } = useSelector((state) => state.academyGeneralInfoApp);
    const [country, setCountry] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const allCountries = Country.getAllCountries();
    const countries = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    // console.log(allCountries)
    const [activeAcademy, setActiveAcademy] = useState(null);
    const [academiesData, setAcademiesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const data = academies.map((academy) => ({
            id: academy.id,
            addressLine: academy?.info?.addressLine,
            title: academy?.name,
            coordinates: [academy?.info?.longitude, academy?.info?.latitude]
        })) || [];

        setAcademiesData(data);
        setFilteredData(data);
    }, [academies]);
    useEffect(() => {
        dispatch(getAllAcademiesFetch(`append_info=true&${country
            ? "country=" + country
            : ""}`));
    }, [country]);
    const [isMap, setIsMap] = useState(true);

    if (isLoading) {
        return <PageLoader />;
    }

    const mainPoints = activeAcademy
        ? [{ coordinates: activeAcademy.coordinates }]
        : [];
    const secondaryPoints = academiesData.map((el) => {
        if (el.id === activeAcademy?.id) {
            return false;
        } else {
            return {
                coordinates: [...el.coordinates],
                data: el
            };
        }
    }).filter((el) => el);
    const newCenter = activeAcademy
        ? { coordinates: activeAcademy.coordinates }
        : null;

    const handleSearch = (e) => {
        const q = e.target.value;

        setSearchTerm(q);
        setFilteredData(academiesData.filter(({ title }) => title?.toLowerCase()?.includes(q.toLowerCase())));
    };

    const handleMarkerClick = (data) => {
        setActiveAcademy(data);

        if (window.innerWidth < 992) {
            setIsMap(false);
        }
    };

    return (
        <div className="academies-page">
            <aside className="academies-page__sidebar academies-sidebar">
                <div className="academies-sidebar__header">
                    <div className="academies-sidebar__header__box">
                        <h3 className="heading mb-36">
                            Explore Academies
                        </h3>

                        <button
                            className="lg-hidden"
                            onClick={() => setIsMap(!isMap)}
                        >
                            {isMap
                                ? <ListIcon />
                                : <MapIcon />}
                        </button>
                    </div>

                    <div className="col-group gap--xl">

                        <SelectOne
                            onChange={(option) => setCountry(option.value)}
                            options={countries}
                            placeholder="All countries"
                            showAllLabel={true}
                            value={country}
                            wrapperClasses="select--outline input--md"
                        />

                        <div className="form-group input--md input--icon-left">
                            <div className="input-wrapper">
                                <span className="ico">
                                    <svg
                                        fill="none"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        width="22"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="10.137"
                                            cy="10.137"
                                            r="6.47345"
                                            stroke="#999999"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                        />

                                        <path
                                            d="M18.3365 18.3365L14.7141 14.7141"
                                            stroke="#999999"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                        />
                                    </svg>
                                </span>

                                <input
                                    className="input input--solid"
                                    onChange={handleSearch}
                                    placeholder="Search academies..."
                                    type="text"
                                    value={searchTerm}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="academies-sidebar__body mobile">
                    {
                        filteredData.length
                            ? filteredData.map((academy) => (
                                <AccordeonTypeArrow
                                    isActive={activeAcademy?.id === academy.id}
                                    key={academy.id}
                                    objData={academy}
                                    onClick={(val, isActive) => {
                                        if (isActive) {
                                            setActiveAcademy(val);
                                        } else {
                                            setActiveAcademy(null);
                                        }
                                    }}
                                    title={academy?.title}
                                >
                                    <p className="color-grey">
                                        {academy.addressLine}
                                    </p>

                                    <Link
                                        to={`${academy?.id}`}
                                        className="btn btn--md btn--primary"
                                    >
                                        <span>
                                            Go to Profile
                                        </span>
                                    </Link>
                                </AccordeonTypeArrow>
                            )



                            )
                            : (
                                <h1>
                                    No found
                                </h1>
                            )


                    }
                </div>
            </aside>

            <div
                className="academies-page__map map"
                style={{ display: isMap
                    ? "block"
                    : "none" }}
            >
                <MapBox
                    mainPoints={mainPoints}
                    newCenter={newCenter?.coordinates}
                    onMarkerClick={handleMarkerClick}
                    secondaryPoints={secondaryPoints}
                />
            </div>

            <div
                className="academies-sidebar__body lg-hidden"
                style={{ display: isMap
                    ? "none"
                    : "block" }}
            >
                {
                    filteredData.length
                        ? filteredData.map((academy) => (
                            <AccordeonTypeArrow
                                isActive={activeAcademy?.id === academy.id}
                                key={academy.id}
                                objData={academy}
                                onClick={(val, isActive) => {
                                    if (isActive) {
                                        setActiveAcademy(val);
                                    } else {
                                        setActiveAcademy(null);
                                    }
                                }}
                                title={academy?.title}
                            >
                                <p className="color-grey">
                                    {academy.addressLine}
                                </p>

                                <Link
                                    to={`${academy?.id}`}
                                    className="btn btn--md btn--primary"
                                >
                                    <span>
                                        Go to Profile
                                    </span>
                                </Link>
                            </AccordeonTypeArrow>
                        )



                        )
                        : (
                            <h1>
                                No found
                            </h1>
                        )


                }
            </div>
        </div>
    );
};

export default AcademiesPage;
