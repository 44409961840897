import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/img/search-loupe.svg";

export const FirstScreenSearchSection = ({
    imageBg, description, placeholder, title, searchValue, onChange
}) => {
    return (
        <section className="first-screen">
            <div className="firts-screen-bg">
                <img
                    alt=""
                    src={imageBg}
                />

                <div className="firts-screen-bg-effect" />
            </div>

            <div className="container">
                <div className="first-screen-body first-screen-body--center">
                    <div className="section-heading">
                        <h1 className="heading fs-48">
                            {title}
                        </h1>

                        <p className="small-paragraph">
                            {description}
                        </p>
                    </div>

                    <div className="first-screen-search">
                        <div className="form-group input--lg input--icon-left">
                            <div className="input-wrapper">
                                <span className="ico">
                                    <SearchIcon />
                                </span>

                                <input
                                    className="input input--solid"
                                    onChange={onChange}
                                    placeholder={placeholder}
                                    type="text"
                                    value={searchValue}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
