import React from "react";
import { useTranslation } from "react-i18next";

const HotelsPart = ({ data = [] }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    return (
        <div className="border-bottom mb-42 pb-42">
            <h4 className="heading mb-32">
                {t('hotels_nearby')}
            </h4>

            {
                data?.length
                    ? data.map((item, index) => (
                        <a
                            key={index}
                            href={item}
                            className="color-secondary text--lg underline"
                        >
                            {item}
                        </a>
                    )



                    )
                    : (
                        <p>
                            {t('no_hotels_nearby')}
                        </p>
                    )


            }
        </div>
    );
};

export default HotelsPart;
