import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api"; 
import { toast } from "react-toastify";

export const buyTicketFetch = createAsyncThunk("/buyTicketFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tickets.buyTicket(payload);

        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const getAllMyTicketsFetch = createAsyncThunk("/getAllMyTicketsFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tickets.getAllMyTickets(payload);

        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const getMyTicketInfoByIdFetch = createAsyncThunk("/getMyTicketInfoByIdFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tickets.getMyTicketInfoById(payload);

        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const getTicketByEventTypeAndEventIdFetch = createAsyncThunk("/getTicketByEventTypeAndEventIdFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tickets.getTicketByEventTypeAndEventId(payload);

        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});

