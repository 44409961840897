import { useNavigate } from 'react-router-dom';
import { EVENT_STEP } from '../../../constants/enums';


const steps = [
    {
        name: EVENT_STEP.date,
        path: ".."
    },
    {
        name: EVENT_STEP.details,
        path: "seminar-info"
    },
    {
        name: EVENT_STEP.design,
        path: "design"
    },
    {
        name: EVENT_STEP.terms,
        path: "terms"
    },
];

const ButtonsGroup = ({ onSubmit, eventId, chatId, step = EVENT_STEP.date }) => {
    const navigate = useNavigate();

    const handleCancel = () => {
        if (chatId) {
            navigate(`/chat/${chatId}`);
            return;
        }

        const currentStepIndex = steps.findIndex((s) => s.name === step);
        console.log(currentStepIndex);
        if (currentStepIndex === 0) {
            navigate('/');
        } else {
            
            const previousStep = steps[currentStepIndex - 1];
            navigate(previousStep.path, { replace: true });
        }
    };

    return (
        <div className="page-action-group mobile-actions">
            <button
                className="btn btn--default btn--sm"
                onClick={handleCancel}
            >
                Cancel
            </button>

            <button
                className="btn btn--primary btn--sm"
                onClick={onSubmit}
            >
                {eventId && chatId ? (
                    <span className="info">Apply Changes</span>
                ) : (
                    <>
                        <span className="info">Continue</span>
                        <span className="ico">
                            <svg
                                fill="none"
                                height="14"
                                viewBox="0 0 14 14"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.25 2.625L9.625 7L5.25 11.375"
                                    stroke="#02020A"
                                    strokeLinecap="square"
                                    strokeWidth="1.6"
                                />
                            </svg>
                        </span>
                    </>
                )}
            </button>
        </div>
    );
};

export default ButtonsGroup;
