import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api";

export const getPaymentsCardFetch = createAsyncThunk("/getPaymentsCardFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.payments.getPaymentsCard(payload);

        if (res?.success) {
            toast.success(res.message);

            return res;
        } else {
            toast.error(res?.message);
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const addPaymentCardFetch = createAsyncThunk("/addPaymentCardFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.payments.addPaymentCard(payload);

        if (res?.success) {
            toast.success(res.message);

            return res;
        } else {
            toast.error(res?.message);
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const updatePaymentCardFetch = createAsyncThunk("/updatePaymentCardFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.payments.updatePaymentCard(payload);

        if (res?.success) {
            toast.success(res.message);

            return res;
        } else {
            toast.error(res?.message);
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const deletePaymentCardFetch = createAsyncThunk("/deletePaymentCardFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.payments.deletePaymentCard(payload);

        if (res?.success) {
            toast.success(res.message);

            return res;
        } else {
            toast.error(res?.message);
        }
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});

export const setupIntentsFetch = createAsyncThunk("/setupIntentsFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.payments.setupIntents(payload);

        return res;
    } catch (err) {
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
