import React, {
    useRef, useEffect
} from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox ({ delegate = "[data-fancybox]", options = {}, children, wrapperClasses }) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        NativeFancybox.bind(container, delegate, options);

        return () => {
            NativeFancybox.unbind(container);
            NativeFancybox.close();
        };
    });

    return (
        <div
            className={wrapperClasses}
            ref={containerRef}
        >
            {children}
        </div>
    );
}

export default Fancybox;
