import { createSlice } from "@reduxjs/toolkit";
import { getPrivateLessonsByEventIdAndEventTypeFetch } from "../thunks/privateLessonsThunks";
const initialState = { 
    privateLessons: [],
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const privateLessonsSlice = createSlice({
    name: "privateLessons",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPrivateLessonsByEventIdAndEventTypeFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.privateLessons = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getPrivateLessonsByEventIdAndEventTypeFetch.pending, (state, action) => {
                state.isLoading = true;
                state.privateLessons = [];
                state.pagination = {};
            })
            .addCase(getPrivateLessonsByEventIdAndEventTypeFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.privateLessons = [];
                state.pagination = {};
            })
    }
});

export const {} = privateLessonsSlice.actions;
export default privateLessonsSlice.reducer;
