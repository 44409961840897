import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routers/routers";
import "air-datepicker/air-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/about-us-new.css";
import "./assets/css/custom.css";
import "./assets/css/customMobile.css";
import "./assets/css/media-bravo.css";
import "./assets/css/react-styles.css";
import "./assets/fonts/OSans-Condensed/stylesheet.css";
import { ToastContainer } from "react-toastify";
// import { getUser } from "./redux/slices/applicationSlice.js";
import { useDispatch } from "react-redux";
import CookiesBanner from "./components/parts/CookiesBanner";
import { getUserFetch } from "./redux/thunks/applicationThunks";
import SelectSportModal from "./components/modals/SelectSportModal";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem("authToken");

        const req = async () => {
            await dispatch(getUserFetch());
        };

        if (token) {
            req();
        }
    }, []);

    return (
        <div className="App">
            <RouterProvider router={router} />

            <ToastContainer />

            <CookiesBanner />
            <SelectSportModal />
        </div>
    );
}

export default App;
