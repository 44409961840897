import classNames from "classnames";
import React from "react";
import { ReactComponent as CheckMark } from "../../../assets/img/check-white.svg"
const CheckboxEl = ({ wrapperClasses, defaultChecked, label, reverse = true, ...props }) => {

    return (
        <label className={classNames("check-btn", wrapperClasses)}>
            <input
                defaultChecked={defaultChecked}
                hidden={true}
                type="checkbox"
                {...props}
            />

            <span className={classNames("check", { "order-1": reverse })}>
                {props.checked && <CheckMark />}
            </span>

            {label && (
                <p className="check-text">
                    {label}
                </p>
            )}
        </label>
    );
};

export default CheckboxEl;
