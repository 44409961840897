import { useState } from "react";
import { useDispatch } from "react-redux";
import { deletePaymentCardFetch } from "../../redux/thunks/paymentsThunks";
import Button from "../ui/buttons/Button";
import ModalTemplate from "./ModalTemplate";


const DeletePaymentCardModal = ({ open, handleClose, data, onSubmit }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await dispatch(deletePaymentCardFetch(data.id)).unwrap()

        if (res?.success) {
            onSubmit && onSubmit()
            handleClose && handleClose()
        }

        setIsLoading(false)
    };

    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <form onSubmit={handleSubmit} className="modal__body p-0">
                <div className="form">
                    <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Delete Payment Card
                            </h3>

                            <p className="text--md desk-heading">
                                Are you sure you want to delete the card ending in {data?.last4}?
                                <br />
                                This action cannot be undone.
                            </p>
                        </div>
                    </div>
                    <div className="modal__footer justify-between mt-24">
                        <button
                            className="btn btn--lg btn--primary"
                            onClick={handleClose}
                            type="button"
                        >
                            <span>
                                Cancel
                            </span>
                        </button>
                        <Button
                            type="submit"
                            isLoading={isLoading}
                            className="btn btn--lg btn--danger"
                        >
                            <span>
                                Delete
                            </span>
                        </Button>
                    </div>
                </div>
            </form>
        </ModalTemplate >
    );
};

export default DeletePaymentCardModal;
