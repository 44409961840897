import { pick } from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EVENT_STEP, USER_ROLE } from "../../../constants/enums";
import { calculateMinAttendance, convertObjectToFormData } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks";
import ButtonsGroup from "../parts/ButtonsGroup";
import EventDetails from "../parts/EventDetails";
import InviteInstructors from "../parts/InviteInstructors";

const SeminarInformation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { chatId: paramsChatId, seminarId } = useParams();
    const chatId = paramsChatId === "null" ? null : paramsChatId
    const user = useSelector(getUser);

    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [editedFields, setEditedFields] = useState([]);

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
        if (["minimalPriceTicket", "instructorPrice"].includes(name)) {
            handleChange("minAttendanceRequirement", calculateMinAttendance(formData.minimalPriceTicket, formData.instructorPrice))
        }
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (!errors?.hasRequiredError) {
            if (chatId && seminarId) {
                const body = new FormData();
                const dataToUpdate = pick(formData, editedFields);

                convertObjectToFormData(dataToUpdate, body);
                const res = await dispatch(updateSeminarFetch({
                    seminarId,
                    body
                })).unwrap();

                if (res) {
                    navigate(`/chat/${chatId}`);
                }
            } else {
                navigate("../design-text");
            }
        }
    };

    useEffect(() => {
        setErrorConfig({
            validators: {
                maxGymCapacity: (value) => !isNaN(parseInt(value)) && value > 0,
            },
            exceptions: [
                "minAttendanceRequirement",
                ...(formData.sharedProfit || seminarId ? ["seminarMinPrice", "seminarMaxPrice"] : []),
                !seminarId && "instructorPrice",
                ...(user?.role === USER_ROLE.instructor ? ["maxGymCapacity"] : []),
            ].filter(Boolean),
            requiredFields: [
                "traningStyle",
                "targetAudience",
                "maxGymCapacity",
                ...(chatId && seminarId ? ["minimalPriceTicket", "instructorPrice"] : []),
                ...(user?.role === USER_ROLE.academy && !chatId ? ["specificBelt"] : []),
                ...(formData.sharedProfit
                    ? seminarId && chatId
                        ? ["minAttendance"]
                        : ["sharedProfit"]
                    : ["seminarMinPrice", "seminarMaxPrice"]),
            ],
        });


    }, [formData, user]);


    return (
        <>
            <div className="row gx-16 py-24 mb-42">
                <EventDetails
                    chatId={chatId}
                    eventId={seminarId}
                    isErrorsShown={isErrorsShown}
                    errors={errors}
                    onChange={handleStepChange}
                    role={user?.role}
                    formData={formData}
                />

                {
                    user?.role === USER_ROLE.instructor && (
                        <InviteInstructors
                            onChange={handleStepChange}
                            preview={preview}
                            onPreviewChange={handlePreviewChange}
                        />
                    )

                }
            </div>
            <ButtonsGroup
                chatId={chatId}
                onSubmit={handleSubmit}
                eventId={seminarId}
                step={EVENT_STEP.details}

            />
        
        </>
    );
};

export default SeminarInformation;
