const urlBase = "api";

export const general = (instance) => ({ async contactUsSend (payload) {
    try {
        const { data } = await instance.post(`${urlBase}/contact-us/send`, payload);

        return data;
    } catch (e) {
        return e.response.data;
    }
} });
