import { createSlice } from "@reduxjs/toolkit";
import {
    getAcademyByIdFetch, getAcademyGeneralInfoFetch, getAllAcademiesFetch
} from "../thunks/academyGeneralInfoThunks";
const initialState = {
    academyGeneralInfo: null,
    academies: [],
    academy: null,
    isLoading: false

};
const academyGeneralInfoSlice = createSlice({
    name: "academyGeneralInfoApp",
    initialState,
    reducers: { setAcademyGeneralInfo: (state, action) => {
        state.academyGeneralInfo = action.payload;
    } },

    extraReducers (builder) {
        builder.addCase(getAcademyGeneralInfoFetch.fulfilled, (state, action) => {
            state.academyGeneralInfo = action.payload;
            state.isLoading = false;
        });
        builder.addCase(getAcademyGeneralInfoFetch.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAcademyGeneralInfoFetch.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getAllAcademiesFetch.fulfilled, (state, { payload }) => {
            state.academies = payload.data;
            state.pagination = payload.pagination;
            state.isLoading = false;
        });
        builder.addCase(getAllAcademiesFetch.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAllAcademiesFetch.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getAcademyByIdFetch.fulfilled, (state, { payload }) => {
            state.academy = payload;
            state.isLoading = false;
        });
        builder.addCase(getAcademyByIdFetch.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAcademyByIdFetch.rejected, (state, action) => {
            state.isLoading = false;
        });
    }
});

export const { setAcademyGeneralInfo } = academyGeneralInfoSlice.actions;
export const getAcademyGeneralInfo = (state) => state.academyGeneralInfoApp.academyGeneralInfo;
export default academyGeneralInfoSlice.reducer;
