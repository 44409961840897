import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import { toggle } from "../../../helpers/utils";
import RangeSlider from "./RangeSlider";

const OutlineEditor = ({ currentStyles, handleChange }) => {
    const outlineStyles = [
        {
            label: "Hidden",
            value: "hidden"
        },
        {
            label: "Dotted",
            value: "dotted"
        },
        {
            label: "Dashed",
            value: "dashed"
        },
        {
            label: "Solid",
            value: "solid"
        },
        {
            label: "Double",
            value: "double"
        },
        {
            label: "Groove",
            value: "groove"
        },
        {
            label: "Ridge",
            value: "ridge"
        },
        {
            label: "Inset",
            value: "inset"
        },
        {
            label: "Outset",
            value: "outset"
        }
    ];

    return (
        <div>
            <label className="label-row">
                <label className="label">
                    Outline
                </label>

                <CheckboxSwitch
                    checked={!!currentStyles?.outlineWidth}
                    onChange={({ target }) => handleChange("outlineWidth", toggle(currentStyles?.outlineWidth, target.value))}
                    value="3px"
                />
            </label>

            {currentStyles?.outlineWidth && (
                <div className="py-4 border-bottom">
                    <div className="row gutters-form-7">
                        <div className="col-12">
                            <div className="flex gap-12">
                                <label className="picker-custom btn btn--square btn--sm">
                                    <input
                                        onChange={({ target }) => handleChange("outlineColor", target.value)}
                                        type="color"
                                        value={currentStyles?.outlineColor || currentStyles.color || "#ffffff"}
                                    />
                                </label>

                                <SelectOne
                                    onChange={(option) => handleChange("outlineStyle", option.value)}
                                    options={outlineStyles}
                                    value={currentStyles?.outlineStyle}
                                    wrapperClasses="select--outline select--outline-bg select--outline-2 input--sm flex-auto"
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <RangeSlider
                                label="Width"
                                max={10}
                                min={0}
                                onChange={(value) => handleChange("outlineWidth", value)}
                                postfix="px"
                                step={1}
                                value={currentStyles?.outlineWidth}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OutlineEditor;
