import { createSlice } from "@reduxjs/toolkit";
import { getMyPayoutMethodsFetch } from "../thunks/payoutMethodsThunks";
const initialState = {
    payoutMethods: [],
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const payoutMethodsSlice = createSlice({
    name: "payoutMethods",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All
            .addCase(getMyPayoutMethodsFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.payoutMethods = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getMyPayoutMethodsFetch.pending, (state, action) => {
                state.isLoading = true;
                state.payoutMethods = [];
                state.pagination = {};
            })
            .addCase(getMyPayoutMethodsFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.payoutMethods = [];
                state.pagination = {};
            })
    }
});

export const { } = payoutMethodsSlice.actions;
export default payoutMethodsSlice.reducer;
