import React, { useEffect, useState } from "react";
import {
    NavLink, Outlet,
    useLocation
} from "react-router-dom";
import { getUser } from "../redux/slices/applicationSlice";
import { useSelector } from "react-redux";
import api from "../api";

export const MySeminarsLayout = () => {
    const user = useSelector(getUser);
    const { pathname } = useLocation()

    const [counts, setCounts] = useState({
        "upcomingSeminarsCount": 0,
        "upcomingPlancampsCount": 0,
        "plannedSeminars": 0,
        "plannedPlancamps": 0,
        "inProgressSeminars": 0,
        "inProgressPlancamps": 0,
        "previousSeminars": 0,
        "previousPlancamps": 0,
        "newRequestsSeminarCount": 0,
        "newRequestsPlancampCount": 0,
        "seminarRequestsCount": 0,
        "plancampRequestsCount": 0,
        "countMyOwnEvents": 0,
        countMySeminarEvents: 0,
        countMyPlancampEvents: 0,
        countPrivateLessons: 0
    });

    const getCounts = async () => {
        const res = await api.requests.getCounts()
        if (res?.success) {
            setCounts(res.data)
        }
    }

    useEffect(() => {
        getCounts()
    }, [])

    const newRequests = counts.newRequestsPlancampCount + counts.newRequestsSeminarCount;

    return (
        <section>
            <div className="container">
                <div className="my-seminars-page__header">
                    <div className="heading-links">

                        {pathname.includes('my-seminars') &&
                            <NavLink
                                className="heading"
                                to="/my-seminars"
                                style={{pointerEvents: 'none'}}
                            >
                            <h3 className="heading">
                                My Seminars
                            </h3>
                            </NavLink>
                        }
                        {
                            pathname.includes('requests') && user && ["instructor", "academy"].includes(user.role) && (
                                <NavLink
                                    className="heading relative"
                                    to="/requests"
                                    style={{pointerEvents: 'none'}}
                                >
                                    <h3 className="heading">Requests and Invitations</h3>
                                    {
                                        !!newRequests && (
                                            <div className="notification-count">{newRequests}</div>
                                        )
                                    }
                                </NavLink>
                            )
                        }
                    </div>
                </div>

                <Outlet />
            </div>
        </section>
    );
};
