import {
    createSlice, current
} from "@reduxjs/toolkit";
const initialState = { 
    eventType: "seminar",
    seminarFormData: {} 
};
const planningSlice = createSlice({
    name: "planning",
    initialState,
    reducers: {
        setSeminarFormData (state, { payload }) {
            const { name, value } = payload;
            const keys = name.split(".");
            let currentState = state.seminarFormData;

            for (let i = 0; i < keys.length - 1; i++) {
                currentState = currentState[keys[i]];
            }

            currentState[keys[keys.length - 1]] = value;
        },
        resetSeminarFormData (state, { payload }) {
            state.seminarFormData = payload
                ? payload
                : initialState.seminarFormData;
        },
        resetSeminarFormDataFields (state, { payload }) {
            payload.forEach((field) => {
                state.seminarFormData[field] = initialState.seminarFormData[field];
            });
        },
        setEventType (state, { payload }) {
            state.eventType = payload
        }
    },
    extraReducers: (builder) => {
    }
});

export const {
    setSeminarFormData,
    resetSeminarFormData,
    resetSeminarFormDataFields,
    setEventType
} = planningSlice.actions;
export const getSeminarFormData = (state) => state.planning.seminarFormData;
export const getEventType = (state) => state.planning.eventType;
export default planningSlice.reducer;
