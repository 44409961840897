import { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePaymentCardFetch } from "../../redux/thunks/paymentsThunks";
import Button from "../ui/buttons/Button";
import CheckboxSwitch from "../ui/formElements/CheckboxSwitch";
import Input from "../ui/formElements/Input";
import ModalTemplate from "./ModalTemplate";


const UpdatePaymentCardModal = ({ open, handleClose, data, onSubmit }) => {
    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body p-0">
                <div className="form">
                    <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Update Payment Card
                            </h3>

                            {/* <p className="text--md desk-heading">Write a Review</p> */}
                        </div>
                    </div>
                    <CheckoutForm
                        onClose={handleClose}
                        data={data}
                        onSubmit={onSubmit}
                    />
                </div>
            </div>
        </ModalTemplate >
    );
};

function CheckoutForm({ onClose, data, onSubmit }) {
    const dispatch = useDispatch()
    const { exp_year, exp_month, isDefault } = data
    const [formData, setFormData] = useState({
        exp_year,
        exp_month,
        isDefault
    })
    const [isErrorsShown, setIsErrorShown] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value 
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await dispatch(updatePaymentCardFetch({
            id: data.id,
            body: formData 
        })).unwrap()

        if (res?.success) {
            onSubmit && onSubmit()
            onClose && onClose()
        }

        setIsLoading(false)
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-body cards-templates-container modal__body scroll-custom" style={{ height: 260 }}>
                <div className="row gx-16 py-24">
                    <div className="col-6">
                        <Input
                            error={isErrorsShown && errors?.exp_month}
                            inputClasses="input--solid"
                            label="Expiration month"
                            onChange={(e) => handleChange("exp_month", e.target.value)}
                            placeholder="Please write expiration month here..."
                            type="number"
                            min={1}
                            max={12}
                            value={formData.exp_month}
                            wrapperClasses="input--lg"
                        />
                    </div>
                    <div className="col-6">
                        <Input
                            error={isErrorsShown && errors?.exp_year}
                            inputClasses="input--solid"
                            label="Expiration year"
                            onChange={(e) => handleChange("exp_year", e.target.value)}
                            placeholder="Please write expiration year here..."
                            type="number"
                            min={new Date().getFullYear()}
                            value={formData.exp_year}
                            wrapperClasses="input--lg"
                        />
                    </div>
                    <div className="col-12">
                        <CheckboxSwitch
                            checked={formData.isDefault}
                            onChange={() => handleChange("isDefault", !formData.isDefault)}
                            text="Make this card the default"
                        />
                    </div>
                </div>
            </div>
            <div className="modal__footer justify-between mt-24">
                <button
                    className="btn btn--lg"
                    onClick={onClose}
                    type="button"
                >
                    <span>
                        Cancel
                    </span>
                </button>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    className="btn btn--lg btn--primary"
                >
                    <span>
                        Submit
                    </span>
                </Button>
            </div>

        </form>
    );
}

;




export default UpdatePaymentCardModal;
