import {
    useRef, useEffect
} from "react";

const useClickOutside = (handler, refNode = null) => {
    const domNode = useRef();

    useEffect(() => {
        const eventHandler = (event) => {
            if (domNode.current && !domNode.current.contains(event.target)) {
                if (refNode && refNode.current && refNode.current.contains(event.target)) {
                    return;
                }

                handler();
            }
        };

        document.addEventListener("mousedown", eventHandler);

        return () => {
            document.removeEventListener("mousedown", eventHandler);
        };
    }, [handler, refNode]);

    return domNode;
};

export default useClickOutside;
