import React from "react";
import { Link } from "react-router-dom";

const BreadCrumds = ({ breadcrumbs }) => {
    return (
        <ul className="breadcrumb mb-28">
            {breadcrumbs.map((item, index) => (
                <li
                    className={`breadcrumb-item ${index === breadcrumbs.length - 1
                        ? "active"
                        : ""}`}
                    key={index}
                >
                    {index === breadcrumbs.length - 1
                        ? item.label
                        : (
                            <Link to={item.link}>
                                { item.label}
                            </Link>
                        )}
                </li>
            )



            ) }
        </ul>
    );
};

export default BreadCrumds;
