import DesignText from "./DesignText";
import PlanTourWrapper from "./PlanTourWrapper";
import SeminarInformation from "./SeminarInformation";
import TimeAndDate from "./TimeAndDate";
import BookingTerms from "./BookingTerms";

export const PlanTour = {
    PlanTourWrapper,
    TimeAndDate,
    SeminarInformation,
    DesignText,
    BookingTerms
};
