import React from "react";
import HeaderDefault from "../components/headers/HeaderDefault";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import ProfileSidebar from "../components/parts/profileSidebars/ProfileSidebar";

const ProfileLayout = () => {
    return (
        <>
            <HeaderDefault className="header--white" />

            <div className="wrapper header-fixed">
                <main className="content flex flex-col">
                    <section className="section-settings">
                        <div className="container">
                            <div className="settings-row overflow-initial">
                                <ProfileSidebar />

                                <div className="settings-col overflow-initial">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                </main>
            </div>
        </>
    );
};

export default ProfileLayout;
