import { Country } from "country-state-city";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ReactComponent as ShareIcon } from "../../../assets/img/icon-share.svg";
import RequestToApplyEventModal from "../../../components/modals/RequestToApplyEventModal";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import Button from "../../../components/ui/buttons/Button";
import { explanations } from "../../../constants/explanations";
import { getDateRange, getDateRangeBetween, getFullLocation, getTrainingStyle } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { ReactComponent as ChevronRignt } from "../../../assets/img/ico-chevron--next.svg";
import { EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { Link } from "react-router-dom";
import { EventHelper } from "../../../helpers/EventHelper";

const TourPendingAside = ({ pageData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const user = useSelector(getUser);
    const [requestEventModalOpen, setRequestEventModalOpen] = useState(false)

    const requestChatId = pageData?._eventRequest?.chatId

    return (
        <>

            <div className="card-shadow px-36 py-32">
                <div className="flex items-center justify-between gap-12 mb-28">
                    <div className="price-group">
                        <span className="price-group__price">
                            {EventHelper.dateRange(pageData)}
                        </span>
                    </div>
                </div>
                <>

                    {
                        requestChatId && user && user?.role === USER_ROLE.academy ? (
                            <Link to={`/chat/${requestChatId}`} className="btn btn--primary border-bottom mb-24 btn--lg w-full">Open Discussion</Link>
                        ) : (
                            <>
                                <Link
                                    to={`/instructors/${pageData?.user?.id}/invite/${pageData?.id}`}
                                    className="btn btn--primary border-bottom mb-24 btn--lg w-full"
                                    disabled={!pageData.isAvailableLocation && user && user?.role !== USER_ROLE.academy}
                                >
                                    <span className="info text-20">
                                        {t('send_proposal')}
                                    </span>

                                    <span className="ico">
                                        <ChevronRignt />
                                    </span>
                                </Link>
                                <p className="mb-24 color-text">Only academies within the selected area can apply to an instructor's pending seminar or camp</p>
                            </>
                        )
                    }

                </>

                <button
                    className="btn color-secondary gap-10 w-full mb-28 border-bottom pb-28"
                >
                    <ShareIcon />

                    <span className="underline not-italic fw-500 tt-none">
                        {t('share')}
                    </span>
                </button>
                <div className="ticket-info-body ticket-info-body--no-border">
                    <div className="ticket-info-body__item">
                        <span className="color-text">
                            Price range
                            <TooltipInfo content={pageData.sharedProfit ? explanations.sharedProfit : explanations.priceRangePendingInstructor} />
                            {" "}
                        </span>

                        <span className="fw-500">
                            {EventHelper.priceRange(pageData)}
                        </span>
                    </div>
                    <div className="ticket-info-body__item">
                        <span className="color-text">
                            Style
                        </span>

                        <span className="fw-500">
                            {EventHelper.style(pageData)?.title}
                        </span>
                    </div>
                    <div className="ticket-info-body__item">
                        <span className="color-text">
                            Location
                        </span>

                        <span className="fw-500">
                            {EventHelper.location(pageData)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};


export default TourPendingAside;
