import { createSlice } from "@reduxjs/toolkit";
import { createReviewFetch, getReviewsFetch } from "../thunks/reviewsThunks";
const initialState = {
    reviews: [],
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const reviewsSlice = createSlice({
    name: "reviews",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All
            .addCase(getReviewsFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reviews = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getReviewsFetch.pending, (state, action) => {
                state.isLoading = true;
                state.reviews = [];
                state.pagination = {};
            })
            .addCase(getReviewsFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.reviews = [];
                state.pagination = {};
            })
            // .addCase(createReviewFetch.fulfilled, (state, action) => {
            //     state.isLoading = false;
            //     state.reviews.push(action.payload.data)
            //     state.pagination = action.payload?.pagination;
            // })
    }
});

export const { } = reviewsSlice.actions;
export const getReviews = (state) => state.reviews.reviews;
export default reviewsSlice.reducer;
