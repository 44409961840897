import { pick } from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EVENT_STEP, EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { calculateMinAttendance, convertObjectToFormData } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks";
import ButtonsGroup from "../parts/ButtonsGroup";
import EventDetails from "../parts/EventDetails";
import InviteInstructors from "../parts/InviteInstructors";

const SeminarInformation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tourId } = useParams();
    const user = useSelector(getUser);

    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [editedFields, setEditedFields] = useState([]);

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (!errors?.hasRequiredError) {
            navigate("../design-text");
        }
    };

    useEffect(() => {
        setErrorConfig({
            validators: {
                maxGymCapacity: (value) => !isNaN(parseInt(value)) && value > 0,
            },
            exceptions: [
                ...(formData.sharedProfit ? ["seminarMinPrice", "seminarMaxPrice"] : []),
                ...(user?.role === USER_ROLE.instructor ? ["maxGymCapacity"] : []),
            ].filter(Boolean),
            requiredFields: [
                "traningStyle",
                "targetAudience",
                "maxGymCapacity",
                ...(formData.sharedProfit ? ["sharedProfit"] : ["seminarMinPrice", "seminarMaxPrice"]),
            ],
        });


    }, [formData, user]);


    return (
        <>
            <div className="row gx-16 py-24 mb-42">
                <EventDetails
                    eventId={tourId}
                    isErrorsShown={isErrorsShown}
                    errors={errors}
                    onChange={handleStepChange}
                    role={user?.role}
                    formData={formData}
                    eventType={EVENT_TYPE.tour}
                />

                {
                    user?.role === USER_ROLE.instructor && (
                        <InviteInstructors
                            onChange={handleStepChange}
                            preview={preview}
                            onPreviewChange={handlePreviewChange}
                        />
                    )

                }
            </div>
            <ButtonsGroup
                onSubmit={handleSubmit}
                eventId={tourId}
                step={EVENT_STEP.details}

            />

        </>
    );
};

export default SeminarInformation;
