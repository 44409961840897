import React from "react";
import {
    Link, NavLink
} from "react-router-dom";

function HeaderSingUp ({ title }) {
    return (
        <header className="header header--white header-sign-up">
            <div className="container">
                <div className="header__body">
                    <Link
                        className="header__logo"
                        to="/"
                    >
                        <img
                            alt=""
                            className="logo-white"
                            src={require("../../assets/img/logo.svg").default}
                        />

                        <img
                            alt=""
                            className="logo-black"
                            src={require("../../assets/img/logo-black.svg").default}
                        />
                    </Link>

                    <p className="header__title">
                        {title}
                    </p>

                    <div className="header__actions header__actions--desktop">
                        <p className="header__info">
                            Already have an account?
                        </p>

                        <NavLink
                            className="btn btn--primary btn--md"
                            to="/?is-login=true"
                        >
                            Login
                        </NavLink>
                    </div>
                </div>

                <div className="mobile-sign-up-banner header__actions">
                    <p className="header__info">
                        Already have an account?
                    </p>

                    <NavLink
                        className="btn btn--primary btn--md"
                        to="/?is-login=true"
                    >
                        Login
                    </NavLink>
                </div>
            </div>

        </header>
    );
}

export default HeaderSingUp;
