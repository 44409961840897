import React from "react";
import Congratulations from "../../../components/parts/Congratulations";
import {
    Link, useLocation,
    useSearchParams
} from "react-router-dom";
import { getUser } from "../../../redux/slices/applicationSlice";
import { useSelector } from "react-redux";
import { USER_ROLE } from "../../../constants/enums";

const PlanningSuccess = () => {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams()
    const user = useSelector(getUser);
    const isPrivate = searchParams.get('isPrivate') === 'true'
    return (
        <>
            {
                pathname.includes("plan-seminar") &&
                    user?.role === USER_ROLE.academy
                    ? (
                        <Congratulations
                            text={
                                <>
                                    {"Your pending seminar has been created, and published "}

                                    <br />

                                    {" "}

                                    in the

                                    {" "}


                                    {!isPrivate ?
                                        <Link
                                            className="color-secondary underline"
                                            to="/planed-by-academy"
                                        >
                                            Match And Apply
                                            {/* Planed By Academy */}
                                        </Link>
                                        :
                                        <Link
                                            className="color-secondary underline"
                                            to="/my-seminars"
                                        >
                                            My seminars
                                            {/* Planed By Insctructor */}
                                        </Link>
                                    }
                                    {" "}

                                    tab.
                                </>
                            }
                            additionalLink={{
                                to: "/instructors",
                                title: "Invite Instructor(s)"
                            }}
                        />
                    )


                    : null
            }

            {
                pathname.includes("plan-seminar") &&
                    user?.role === "instructor"
                    ? (
                        <Congratulations
                            text={
                                <>
                                    {"Your pending seminar has been created, and published "}

                                    <br />

                                    {" "}

                                    in the

                                    {" "}

                                    {!isPrivate ?
                                        <Link
                                            className="color-secondary underline"
                                            to="/planed-by-insctructor"
                                        >
                                            Match And Apply
                                            {/* Planed By Insctructor */}
                                        </Link>
                                        :
                                        <Link
                                            className="color-secondary underline"
                                            to="/my-seminars"
                                        >
                                            My seminars
                                            {/* Planed By Insctructor */}
                                        </Link>
                                    }

                                    {" "}

                                    tab.
                                </>
                            }
                            additionalLink={{
                                to: "/instructors",
                                title: "Invite Instructor(s)"
                            }}
                        />
                    )


                    : null
            }

            {
                pathname.includes("plan-camp") &&
                    user?.role === USER_ROLE.academy
                    ? (
                        <Congratulations
                            text="Your pending camp has been created. Do you want invite insturctor(s) to your camp?."
                            additionalLink={{
                                to: "/instructors",
                                title: "Invite Instructor(s)"
                            }}
                        />
                    )


                    : null
            }

            {
                pathname.includes("plan-camp") &&
                    user?.role === "instructor"
                    ? (
                        <Congratulations text="Your pending camp has been created." />
                    )


                    : null
            }

            {
                pathname.includes("plan-tour") && (
                    <Congratulations text="Your pending tour has been created."
                        additionalLink={{
                            to: "/planed-by-academy?sort_as=desc",
                            title: "Invite Academies"
                        }}
                    />
                )
            }
        </>
    );
};

export default PlanningSuccess;
