const base = "api/tours";

export const tours = (instance) => ({
    async createTour(body) {
        const { data } = await instance.post(`${base}`, body);
        return data;
    },
    async updateTour({ id, body }) {
        const { data } = await instance.put(`${base}/${id}`, body);
        return data;
    },
    async getAllTours(query = "") {
        const { data } = await instance.get(`${base}?${query}`);
        return data;
    },
    async getAvailableSeminarsForTour({ tourId, query = "no_limit=true" }) {
        const { data } = await instance.get(`${base}/available-seminars/${tourId}?${query}`);
        return data;
    },
    async getTourById({ tourId }) {
        const { data } = await instance.get(`${base}/${tourId}`);
        return data;
    },
    async deleteTour({ tourId }) {
        const { data } = await instance.delete(`${base}/${tourId}`);
        return data;
    },
});
