import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams
} from "react-router-dom";
import Button from "../../components/ui/buttons/Button";
import CheckboxSwitch from "../../components/ui/formElements/CheckboxSwitch";
import Input from "../../components/ui/formElements/Input";
import SelectOne from "../../components/ui/formElements/SelectOne";
import PageLoader from "../../components/ui/PageLoader";
import { belts } from "../../constants";
import {
    checkErrors, getDateRange, getImageSrc
} from "../../helpers/utils";
import { getUser } from "../../redux/slices/applicationSlice";
import { getSeminarsById } from "../../redux/thunks/seminarsThunks";
import { ReactComponent as LocationIcon } from "../../assets/img/icon-decor-city.svg";
import SelectionImg from "../../assets/img/section.png";
import { Country } from "country-state-city";
import { trainingStyle as trainingStyles } from "../../constants";
import LoginForm from "../../components/parts/LoginForm";
import { buyTicketFetch } from "../../redux/thunks/ticketsThunks";
import api from "../../api";
import classNames from "classnames";
import { format } from "date-fns";


const BuyPrivateLessonPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [agree, setAgree] = useState(true);
    const [isBuying, setIsBuying] = useState(false)

    const { eventType, eventId } = useParams();

    const initialFormData = {
        eventType,
        eventId,
        instructorId: null,
        dateId: null
    }

    const user = useSelector(getUser);
    const [isLoading, setIsLoading] = useState(true)
    const [lessonsData, setLessonsData] = useState([])
    const [formData, setFormData] = useState(initialFormData);

    const [errors, setErrors] = useState({});
    const [isErrorsShown, setIsErrorsShown] = useState(false);

    const handleChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        console.log(formData);
        if (errors?.hasRequiredError) return;

        setIsBuying(true)

        const res = await api.privateLessons.buyPrivateLessonOnEvent(formData)

        const paymentUrl = res?.data?.invoice?.session?.url
        
        if (paymentUrl) {
            window.location.href = paymentUrl
        }

        setTimeout(() => setIsBuying(false), 100)
    };


    useEffect(() => {
        setErrors(checkErrors(formData, {
            exceptions: [],
            requiredFields: ["eventType", "eventId", "instructorId", "dateId"]
        }));
    }, [formData]);


    useEffect(() => {
        setFormData(initialFormData);
    }, [user]);

    const fetchLessons = async () => {
        setIsLoading(true)

        const res = await api.privateLessons.getAllPrivateLessonsByEvent({
            eventId, eventType
        })
        if (res && res.data && res.success) {
            setLessonsData(res.data)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        if (!eventId || !eventType) return
        fetchLessons()
    }, [eventId, eventType])

    if (isLoading) {
        return <PageLoader />;
    }


    const instructorsOptions = lessonsData?.instructors?.length ?
        lessonsData?.instructors?.map(item => ({ label: item?.user?.instructorProfile?.fullName, value: item?.id }))
        : []

    const datesOptions = lessonsData?.schedules?.length ?
        lessonsData?.schedules.map(item => ({ label: `${format(item.date, 'dd MMM, yyyy')} ${item.start} - ${item.end}`, value: item.id }))
        : []
    return (
        <div className="section-with-image">
            <img src={SelectionImg} className="section-with-image__img" alt="section" />
            <div className="container">
                <div className="double-section full-height no-border flex">
                    <div className="double-section__content withBg pt-42">
                        {
                            user
                                ? (
                                    <>
                                        <div className="col-group gap--sm">
                                            <h6 className="heading">
                                                Private lesson
                                            </h6>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="row gx-32 gy-32">
                                            {
                                                lessonsData?.instructors?.length && lessonsData?.instructors.map((instructor, index) => (
                                                    <div
                                                        key={instructor.id}
                                                        className={classNames("col-12",
                                                            { 'pb-24 border-bottom': index < lessonsData?.instructors?.length - 1 })}
                                                    >
                                                        <div className="row-group gap--lg">
                                                            <div className="seminar-instructor__img img-wrapper flex-shrink-0">
                                                                <img
                                                                    alt={instructor?.user?.instructorProfile?.fullName}
                                                                    src={getImageSrc(instructor?.user?.instructorProfile?.photo)}
                                                                />
                                                            </div>
                                                            <div className="col-group gap--sm">
                                                                <h5>{instructor?.user?.instructorProfile?.fullName}</h5>
                                                                <p className="color-text">{instructor?.user?.instructorProfile?.bio}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="col-group gap-30">
                                            <p className="color-text text-sm">
                                                Ticket and receipt are sent to the info below:
                                            </p>

                                            <div className="row gx-16 gutters-form">
                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <SelectOne
                                                        error={isErrorsShown && errors?.instructorId}
                                                        label="Instructor"
                                                        onChange={(option) => handleChange("instructorId", option.value)}
                                                        options={instructorsOptions}
                                                        placeholder="Instructor"
                                                        value={formData.instructorId}
                                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                                    />
                                                </div>

                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <SelectOne
                                                        error={isErrorsShown && errors?.dateId}
                                                        label="Date"
                                                        onChange={(option) => handleChange("dateId", option.value)}
                                                        options={datesOptions}
                                                        placeholder="Select your belt"
                                                        value={formData.dateId}
                                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="hr-horisontal" />

                                        <div className="option-group">
                                            <CheckboxSwitch
                                                checked={agree}
                                                onChange={() => setAgree(!agree)}
                                                text="I want to receive newsletters"
                                            />
                                        </div>

                                        <div className="w-full text-center">
                                            <Button
                                                isLoading={isBuying}
                                                className="btn btn--primary btn--lg mb-20 w-full"
                                                onClick={handleSubmit}
                                            >
                                                <span className="info">
                                                    Continue
                                                </span>
                                            </Button>

                                            <p className="color-text text-14">
                                                When you click “Continue” you accept our

                                                {" "}

                                                <Link
                                                    className="color-secondary"
                                                    to="/terms"
                                                >
                                                    terms
                                                </Link>

                                                {" "}

                                                and

                                                {" "}

                                                <Link
                                                    className="color-secondary"
                                                    to="/privacy"
                                                >
                                                    privacy policy
                                                </Link>
                                            </p>
                                        </div>
                                    </>
                                )


                                : (
                                    <LoginForm
                                        isPreBooking
                                        wrapperClasses="form-login"
                                        description="Login or sign up to purchase on Global Seminars"
                                    />
                                )


                        }
                    </div>

                    <div className="double-section__aside withBackground">
                        {/* <OrderSummary data={seminarDetails} seminar={true} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

function OrderSummary({ data, seminar = false }) {
    const user = useSelector(getUser);

    const getLocation = (data) => {
        if (!data?.location) {
            return "Location not specified";
        }

        const location = data?.location
            ? Country.getCountryByCode(data?.location)?.name
            : data?.location;

        return `${location}, ${data?.address} `;
    };

    const traningStyle = trainingStyles.find(({ title }) => title?.toLowerCase() === data?.traningStyle?.toLowerCase())?.title;

    return (
        <div className="card-shadow px-36 py-32 mb-16">
            <h6 className="heading mb-32">
                Order Summary
            </h6>

            <div className="row-group gap--xxl pb-24 border-bottom mb-24 flex-lg-nowrap flex-wrap">
                <div className="image-wrapper --medium-thumb">
                    <img
                        alt={data?.seminarName}
                        src={getImageSrc(data?.photos?.find((photo) => photo.id === data.coverId)?.url)}
                    />
                </div>

                <div className="col-group gap--xxl justify-between">
                    {data.adress}

                    <div className="row-group color-secondary fw-500 text-13 gap-4">
                        <div className="ico">
                            <LocationIcon />
                        </div>

                        <span className="truncate">
                            {getLocation(data)}
                        </span>
                    </div>

                    <div className="seminar-label-info">
                        <h6 className="heading">
                            {data?.seminarName}
                        </h6>
                    </div>
                </div>
            </div>

            <div className="ticket-info-body ticket-info-body--no-border mb-32">
                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Date:
                    </span>

                    <span className="fw-500">
                        {getDateRange(data?.seminarDates)}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Style:
                    </span>

                    <span className="fw-500">
                        {traningStyle}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Academy:
                    </span>

                    <span className="fw-500">
                        {data.userAcademy?.academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Instructors:
                    </span>

                    <span className="fw-500">
                        {data.instructors?.map((item) => item?.user?.instructorProfile?.fullName).join(", ")}
                    </span>
                </div>
            </div>

            {
                false && (
                    <Button className="btn--default btn--md w-full mb-32">
                        Apply Coupon
                    </Button>
                )}

            <hr className="hr-horisontal mb-32" />
            {seminar && <div className="row-group justify-between gap--md">
                <div className="price-group">
                    <span className="color-grey mr-8">
                        Fee
                    </span>
                </div>

                <span className="price-group__price">
                    ${data.minimalPriceTicket * data?._ticketFee}
                </span>
            </div>}
            <div className="row-group justify-between gap--md">
                <div className="price-group">
                    <span className="color-grey mr-8">
                        Ticket
                    </span>

                    <span className="price-group__amount">
                        {(data?.maxGymCapacity || 0) - (data?.ticketsPurchased || 0)} left
                    </span>
                </div>

                <span className="price-group__price">
                    ${data?.minimalPriceTicket}
                </span>
            </div>
        </div>
    );
}

export default BuyPrivateLessonPage;
