import Button from "../ui/buttons/Button";
import ModalTemplate from "./ModalTemplate";

const ConfirmModal = ({ open, onClose, title, description, onSubmit }) => {
    return (
        <ModalTemplate
            modalSize="modal__dialog--xs"
            onClick={onClose}
            open={open}
        >
            <div className="refund_confirm_body">
                {title && <h2 className="refund_confirm_heading">{title}</h2>}
                {description && <p className="refund_confirm_text" dangerouslySetInnerHTML={{__html: description}} />}
                <div className="m-auto">
                    <Button
                        className=" btn--md"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn--primary btn--md"
                        onClick={onSubmit}
                    >
                        Yes, Proceed
                    </Button>


                </div>
            </div>
        </ModalTemplate>
    );
};

export default ConfirmModal;
