import React, {
    useEffect, useState
} from "react";
import moment from "moment/moment";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import TimePicker from "../../../components/ui/formElements/TimePicker";
import {
    checkErrors, convertDatesToTimeSlot, uuidv4
} from "../../../helpers/utils";
import { ReactComponent as PlusIcon } from "../../../assets/img/ico-plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";

export const covertDateFormat = ({ date, startTime, endTime }) => {
    return {
        date: date
            ? moment(date).format("yyyy-MM-DD")
            : null,
        startTime: startTime
            ? moment(startTime).format("HH:mm")
            : null,
        endTime: endTime
            ? moment(endTime).format("HH:mm")
            : null
    };
};

export const convertDatesToFormData = (eventsArray) => {
    return eventsArray.reduce((convertedArray, event) => {
        const { date, timeSlots } = event;
        const convertedTimeSlots = timeSlots.map((timeSlot) => {
            return covertDateFormat({
                ...timeSlot,
                date
            });
        });

        return [...convertedArray, ...convertedTimeSlots];
    }, []);
};

const EventDateTimeInput = ({
    event, handleChange, children, eventId, errors, isErrorsShown, isMultySlot, maxSlots, availableDates, excludeDates, timeIntervals = 15
}) => {
    const [date, setDate] = useState(event?.date);
    const [timeSlots, setTimeSlots] = useState([...event.timeSlots]);

    const handleAddTimeSlot = () => {
        const timeSlot = {
            startTime: "",
            endTime: "",
            date
        };

        setTimeSlots((prev) => [...prev, timeSlot]);
    };

    const handleRemoveTimeSlot = (index) => {
        const newTimeSlots = timeSlots.filter((_, i) => index !== i);

        setTimeSlots(newTimeSlots);
        const data = {
            date,
            timeSlots: newTimeSlots
        };

        handleChange && handleChange(eventId, data);
    };

    const handleChangeTimeSlot = (index, key, value) => {
        const hours = new Date(value).getHours()
        const min = new Date(value).getMinutes()
        const time = new Date(new Date(date).setHours(hours, min))
        console.log(value);

        const newEvents = timeSlots.map((timeSlot, i) => {
            if (i === index) {
                return {
                    ...timeSlot,
                    [key]: time
                };
            } else {
                return timeSlot;
            }
        });

        setTimeSlots(newEvents);
        const data = {
            ...event,
            date,
            timeSlots: newEvents
        };

        handleChange && handleChange(eventId, data);
    };

    const handleChangeDate = (date) => {
        const data = {
            ...event,
            date: date,
            timeSlots
        };

        setDate(date);
        handleChange && handleChange(eventId, data);
    };

    const dayStringToIndex = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6
    };

    const filterDateByDay = (date) => {
        const day = date.getDay();
        if (!availableDates) {
            return date
        }
        return availableDates.some((entry) => dayStringToIndex[entry.day] === day);
    };

    const filterDateByTime = (time) => {
        if (!date) return;
        if (!availableDates) {
            return time
        }
        const day = new Date(date).getDay();
        const hours = time.getHours();
        const minutes = time.getMinutes();

        const daySchedules = availableDates.filter((entry) => {
            return dayStringToIndex[entry.day] === day
        });

        return daySchedules.some((entry) => {
            const [startHours, startMinutes] = entry.startTime.split(':').map(Number);
            const [endHours, endMinutes] = entry.endTime.split(':').map(Number);

            const isAfterStart = hours > startHours || (hours === startHours && minutes >= startMinutes);
            const isBeforeEnd = hours < endHours || (hours === endHours && minutes <= endMinutes);

            return isAfterStart && isBeforeEnd;
        });
    };

    // const filterDates = (date) => {
    //     return filterDateByDay(date) && filterDateByTime(date);
    // };
    const generateExcludeTimes = (time) => {
        if (!time) return [];
        const baseDate = new Date();
        baseDate.setHours(time.getHours(), time.getMinutes(), 0, 0);
        return [baseDate];
    };

    const timeRange = (startTime) => {
        if (!startTime) return {}
        const minTime = new Date();
        const maxTime = new Date();
        minTime.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
        maxTime.setHours(23, 59, 0, 0);
        return { minTime, maxTime };
    }
    return (
        <div className="seminars-list-item">
            <div className="seminars-list-item__body-content">
                <div className="schedule-group">
                    <div className="schedule-group-item">
                        <div className="schedule-group-item__date">
                            <DatePicker
                                filterDate={filterDateByDay}
                                error={isErrorsShown && errors?.date}
                                excludeDates={excludeDates}
                                minDate={moment().toDate()}
                                onChange={(date) => handleChangeDate(date)}
                                placeholderText="Select Event Date"
                                selected={date}
                            />
                        </div>

                        <div className="schedule-group-item__time">
                            {timeSlots.map((timeSlot, index) => (
                                <div
                                    className="schedule-group-item__time-group"
                                    key={index}
                                >
                                    <div className="input-date-time-group">
                                        <TimePicker
                                            error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.startTime}
                                            onChange={(time) => handleChangeTimeSlot(index, "startTime", time)}
                                            placeholderText="0:00pm"
                                            selected={timeSlot?.startTime}
                                            filterTime={filterDateByTime}
                                            timeIntervals={timeIntervals}
                                            disabled={!date}

                                        />

                                        <span className="input-delimiter" />

                                        <TimePicker
                                            placeholderText="0:00pm"
                                            selected={timeSlot?.endTime}
                                            error={isErrorsShown && errors?.timeSlots?.length && errors?.timeSlots[index]?.endTime}
                                            onChange={(time) => handleChangeTimeSlot(index, "endTime", time)}
                                            filterTime={filterDateByTime}
                                            timeIntervals={timeIntervals}
                                            excludeTimes={generateExcludeTimes(timeSlot?.startTime)}
                                            disabled={!date}
                                            {...timeRange(timeSlot?.startTime)}

                                        />

                                        {isMultySlot && (
                                            index === 0 && (!maxSlots || (maxSlots > 0 && timeSlots?.length < maxSlots)) ? (
                                                <button
                                                    type="button"
                                                    onClick={handleAddTimeSlot}
                                                    className="btn btn--outline-default btn--square btn--md rounded-full"
                                                >
                                                    <PlusIcon />
                                                </button>
                                            ) : (
                                                timeSlots.length > 1 && (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveTimeSlot(index)}
                                                        className="btn btn--outline-default btn--square btn--md rounded-full"
                                                    >
                                                        <DeleteIcon />
                                                    </button>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

const EventDatesSelect = ({ dates = [], availableDates, handleChange, isErrorsShown, isMultySlot, maxSlots, maxDays = 2, timeIntervals }) => {
    const [events, setEvents] = useState(convertDatesToTimeSlot(dates));
    const [errors, setErrors] = useState([]);
    
    const addEvent = () => {
        const newEvent = {
            date: "",
            id: uuidv4(),
            timeSlots: [
                {
                    startTime: "",
                    endTime: ""
                }
            ]
        };
        const updatedEvents = [...events, newEvent];

        setEvents(updatedEvents);
        handleChange(convertDatesToFormData(updatedEvents));
    };

    const removeEvent = (index) => {
        const updatedEvents = events.filter((_, i) => i !== index);

        handleChange(convertDatesToFormData(updatedEvents));
        setEvents(updatedEvents);
    };

    const updateEventById = (eventId, newEvent) => {
        const updatedEvents = events.map((event, index) => {
            if (index === eventId) {
                return newEvent;
            }

            return event;
        });

        setEvents(updatedEvents);
        handleChange(convertDatesToFormData(updatedEvents));
    };

    const excludeDates = [...events.map((event) => event?.date).filter((event) => event),
    {
        date: new Date(new Date().getHours() + 1),
        id: uuidv4(),
        timeSlots: [
            {
                startTime: new Date(new Date().getHours() + 1),
                endTime: ""
            }
        ]
    }
    ];

    useEffect(() => {
        const errors = events.map((date) => {
            return checkErrors(date);
        });

        setErrors(errors);
    }, [events]);
    useEffect(() => {
        if (!events?.length) addEvent();
    }, [events]);
    useEffect(() => {
        setEvents(convertDatesToTimeSlot(dates));
    }, [dates]);



    return (
        <div className="seminars-list seminars-list--planning mb-18">
            {events.map((event, index) => (
                <React.Fragment key={event.id}>
                    <EventDateTimeInput
                        errors={errors?.length
                            ? errors[index]
                            : {}}
                        isMultySlot={isMultySlot}
                        maxSlots={maxSlots}
                        event={event}
                        eventId={index}
                        excludeDates={excludeDates}
                        handleChange={updateEventById}
                        isErrorsShown={isErrorsShown}
                        availableDates={availableDates}
                        timeIntervals={timeIntervals}
                    >
                        {
                            events.length > 1
                                ? (
                                    <button
                                        type="button"
                                        onClick={() => removeEvent(index)}
                                        className="btn btn--outline-default btn--square btn--md rounded-full"
                                        style={{ marginTop: 5 }}
                                    >
                                        <DeleteIcon />
                                    </button>
                                )
                                : null
                        }
                    </EventDateTimeInput>
                </React.Fragment>
            ))}
            {
                events.length && events.every(event => event.date) && (!maxDays || (maxDays && events?.length < maxDays)) && (
                    <div className="seminars-list-item">
                        <div className="seminars-list-item__body-content">
                            <button
                                className="btn btn--sm btn--primary"
                                onClick={addEvent}
                                type="button"
                            >
                                <span className="ico ico--20">
                                    <PlusIcon />
                                </span>

                                <span className="info">
                                    Add Event Date
                                </span>
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default EventDatesSelect;
