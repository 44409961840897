import React from "react";
import Congratulations from "../../components/parts/Congratulations";

const SuccessInvite = () => {
    return (
        <Congratulations
            text={(<>
                Your invite(s) has been send. Please wait for instructor(s) answer.

                {" "}
            </>)}
        />
    );
};

export default SuccessInvite;
