import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from "../../assets/img/arrow-icon.svg";
import { getBreadcrumbs } from '../../redux/slices/applicationSlice';
import { ReactComponent as ChevronPrevIcon } from "../../assets/img/ico-chevron--prev.svg";

const ButtonBack = ({ to, className }) => {
    const breadcrumbs = useSelector(getBreadcrumbs)
    const previousPage = breadcrumbs[breadcrumbs.length - 2]
    const disabled = !to && !breadcrumbs[breadcrumbs.length - 2]

    return (
        <Link
            to={to || previousPage}
            onClick={(e) => disabled ? e.preventDefault() : null}
            style={{
                pointerEvents: disabled ? "none" : "all",
                opacity: disabled ? "0.3" : "1"
            }}
            className={classNames("btn back-icon", className)}
        >
            <ArrowIcon />
            {/* <span className="ico">
                <ChevronPrevIcon />
            </span>

            <p className="text-sm">
                Back
            </p> */}
        </Link>
    )
}

export default ButtonBack