import { useEffect } from "react";

export const useLocaleStorage = (key, defaultValue) => {
    const setValue = () => {
        return (value) => window.localStorage.setItem(key, JSON.stringify(value));
    };

    const value = JSON.parse(window.localStorage.getItem(key));
    const removeValue = () => window.localStorage.removeItem(key);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, []);

    return [value, setValue(), removeValue];
};
