const base = "api/recently-viewed";

export const history = (instance) => ({
    async getHistory (type = "seminar") {
        const { data } = await instance.get(`${base}?type=${type}`);

        return data;
    },
    async clearHistory () {
        const { data } = await instance.delete(`${base}/all`);

        return data;
    }
});
