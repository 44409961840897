const base = "api/tracking";

export const tracking = (instance) => ({
    async getAllTracks (query = "") {
        const { data } = await instance.get(`${base}?${query}`);

        return data;
    },
    async findTrack ({ type, subscribeId }) {
        const { data } = await instance.get(`${base}/find?type=${type}&subscribeId=${subscribeId}`);

        return data;
    },
    async createTrack (body) {
        // const body = {
        //     "type": "instructor_as_free",
        //     "subscribeId": "11"
        // } 
        const { data } = await instance.post(`${base}`, body);

        return data;
    },
    async deleteTrack (id) {
        const { data } = await instance.delete(`${base}/${id}`);

        return data;
    }
});
