import { Country } from "country-state-city";
import { pick } from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useLocation, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import Input from "../../../components/ui/formElements/Input";
import Textarea from "../../../components/ui/formElements/Textarea";
import PageLoader from "../../../components/ui/PageLoader";
import { convertObjectToFormData } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { resetSeminarFormData } from "../../../redux/slices/planningSlice";
import {
    createSeminar, updateSeminarFetch
} from "../../../redux/thunks/seminarsThunks";
import SeminarPhotosUpload from "../parts/SeminarPhotosUpload";
import PopularQuestions from "../parts/PopularQuestions";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { toast } from "react-toastify";
import { createTourFetch, updateTourFetch } from "../../../redux/thunks/toursThunks";

const DesignText = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { tourId } = useParams();
    const user = useSelector(getUser);
    const { seminarDetails, isLoading } = useSelector((state) => state.seminars);
    const isActive = seminarDetails?.status === "active";
    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [coverImage, setCoverImage] = useState(null);
    const [editedFields, setEditedFields] = useState([]);

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    const handleChangePhotos = (photos) => {
        handleStepChange("photos", photos);
    };

    const handleDeletePhoto = (id) => {
        handleStepChange("delete_photos", [...formData?.delete_photos || [], id]);
    };


    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors.hasErrors, errors, formData);
        if (tourId) {
            if (!errors.hasRequiredError) {
                const body = new FormData();

                const dataToUpdate = formData;
                // const dataToUpdate = (isActive) ? pick(formData, editedFields) : formData;

                convertObjectToFormData(dataToUpdate, body);
                if (!formData?.countries?.length) {
                    body.append('countries', null)
                }
                let res = await dispatch(updateTourFetch({
                    id: tourId,
                    body
                })).unwrap();

                if (res) {
                    dispatch(resetSeminarFormData());
                    // navigate(`/plan-tour/preview-tour/${res.data?.id}`);
                    // navigate(`/my-seminars/${res.data?.id}`);
                    navigate(`/my-seminars/`);
                    toast.success("Successfully updated")
                }
            }
        } else {
            if (!errors.hasRequiredError) {
                const body = new FormData();
                convertObjectToFormData(formData, body);
                const res = await dispatch(createTourFetch(body)).unwrap();
                if (res && res.data?.id) {
                    dispatch(resetSeminarFormData());
                    // navigate(`/plan-tour/preview-tour/${res.data?.id}`)

                    navigate(`../success?isPrivate=${res.isPrivate || 'false'}`);
                }
            };
        }
    }

    useEffect(() => {
        setEditedFields((prev) => {
            if (prev.includes("photos")) {
                return prev;
            } else {
                return [...prev, "photos"];
            }
        });
    }, [formData?.photos]);

    useEffect(() => {
        handlePreviewChange("cover", coverImage);
        const getCoverName = (cover) => cover?.name || cover?.originalname;

        if (coverImage?.id) {
            handleStepChange("coverId", coverImage?.id);

            return;
        }

        if (getCoverName(coverImage)) {
            handleStepChange("coverFilename", getCoverName(coverImage));
        } else if (formData.photos?.length) {
            handleStepChange("coverFilename", getCoverName(formData.photos[0]));
        } else {
            handleStepChange("coverFilename", "");
        }
    }, [coverImage]);

    useEffect(() => {
        setErrorConfig({
            exceptions: [],
            requiredFields: ["photos", "seminarName", "summary"]
        });
    }, [formData]);

    useEffect(() => {
        if (location.state?.previousLocation === "/template-editor") {
            setEditedFields((prev) => [...prev, "photos"]);
            setCoverImage(formData.photos?.find(photo => photo.name === formData.coverFilename))
        }
    }, [location])

    return (
        <>
            {
                !isLoading
                    ? (
                        <>
                            <SeminarPhotosUpload
                                coverImage={coverImage}
                                error={isErrorsShown && errors?.photos}
                                images={formData.photos}
                                onDelete={handleDeletePhoto}
                                setCoverImage={setCoverImage}
                                setImages={handleChangePhotos}
                            />

                            <hr className="hr mb-42" />

                            <h6 className="heading mb-32">
                                Tour Info
                            </h6>

                            <div className="row gx-16 py-24 mb-42">
                                <div className="col-12">
                                    <Input
                                        error={isErrorsShown && errors?.name}
                                        inputClasses="input--solid"
                                        label="Tour Name"
                                        onChange={(e) => handleStepChange("name", e.target.value)}
                                        placeholder="Add a name here..."
                                        value={formData.name}
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-12">
                                    <Textarea
                                        error={isErrorsShown && errors?.summary}
                                        label="Summary"
                                        onChange={(e) => handleStepChange("summary", e.target.value)}
                                        placeholder="Please write summary here..."
                                        style={{ minHeight: 140 }}
                                        textareaClasses="input--solid"
                                        value={formData.summary}
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <CheckboxSwitch
                                    checked={formData.isPrivate}
                                    onChange={() => handleStepChange("isPrivate", !formData.isPrivate)}
                                    text="Set this seminar as private."
                                />
                            </div>

                            <div className="page-action-group">
                                {
                                    isActive ? (
                                        <Link
                                            className="btn btn--default btn--sm"
                                            to={tourId && `/my-seminars/${tourId}`}
                                        >
                                            Cancel
                                        </Link>
                                    ) : (
                                        <Link
                                            className="btn btn--default btn--sm"
                                            to={"../seminar-info"}
                                        >
                                            Cancel
                                        </Link>
                                    )
                                }

                                <button
                                    className="btn btn--primary btn--sm"
                                    onClick={handleSubmit}
                                >
                                    {
                                        tourId
                                            ? (
                                                <span className="info">
                                                    Apply Changes
                                                </span>
                                            )


                                            : (
                                                <>
                                                    <span className="info">
                                                        {tourId ? "Continue" : "Publish"}
                                                    </span>
                                                    {
                                                        tourId && (
                                                            <span className="ico">
                                                                <svg
                                                                    fill="none"
                                                                    height="14"
                                                                    viewBox="0 0 14 14"
                                                                    width="14"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M5.25 2.625L9.625 7L5.25 11.375"
                                                                        stroke="#02020A"
                                                                        strokeLinecap="square"
                                                                        strokeWidth="1.6"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        )
                                                    }
                                                </>
                                            )


                                    }
                                </button>
                            </div>
                        </>
                    )


                    : <PageLoader />
            }
        </>
    );
};

export default DesignText;
