import SelectOne from "../ui/formElements/SelectOne";
import { ReactComponent as FiltersIcon } from "../../assets/img/icon-filters.svg";
import DropDown from "../ui/DropDown";
import CheckboxSwitch from "../ui/formElements/CheckboxSwitch";

const SelectFilters = ({ filters, filtersData, handleChange }) => {
    return (
        <>
            <DropDown
                buttonClasses="btn btn--outline btn--lg w-full justify-start"
                dropDownClasses="lg-hidden"
                dropDownListClasses="px-36 py-32"
                labelButton={(
                    <>
                        <FiltersIcon />

                        <span className="text-normal fw-500 text-14 style-normal">
                            Filters
                        </span>
                    </>
                )}
            >
                <div className="flex flex-col gap-12">
                    {filtersData.map((filter) => (
                        <SelectOne
                            key={filter.query}
                            onChange={(option) => handleChange(filter.query, option.value)}
                            options={filter.options}
                            placeholder={filter.label}
                            showAllLabel={filter.all}
                            value={filters.query}
                            wrapperClasses="form-group select--outline w-150 input--md select-border-option"
                        />
                    )



                    )}

                    <CheckboxSwitch
                        checked={filters.gender === "female"}
                        onChange={() => handleChange("gender", filters.gender === "female"
                            ? ""
                            : "female")}
                        text="Women only"
                    />

                    <CheckboxSwitch
                        checked={filters.gender === "male"}
                        onChange={() => handleChange("gender", filters.gender === "male"
                            ? ""
                            : "male")}
                        text="Men only"
                    />
                </div>
            </DropDown>

            <div className="hidden lg-flex items-center gap-12 mb-24">
                {filtersData.map((filter) => (
                    <SelectOne
                        key={filter.query}
                        onChange={(option) => handleChange(filter.query, option.value)}
                        options={filter.options}
                        placeholder={filter.label}
                        showAllLabel={filter.all}
                        value={filters.query}
                        wrapperClasses="form-group select--outline w-150 input--md select-border-option"
                    />
                )



                )}
            </div>
        </>
    );
};

export default SelectFilters;
