import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Link
} from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as ChevronRignt } from "../../../assets/img/ico-chevron--next.svg";
import { ReactComponent as ShareIcon } from "../../../assets/img/icon-share.svg";
import RequestToApplyEventModal from "../../../components/modals/RequestToApplyEventModal";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import Button from "../../../components/ui/buttons/Button";
import CheckboxEl from "../../../components/ui/formElements/CheckboxEl";
import { EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { explanations } from "../../../constants/explanations";
import { EventHelper } from "../../../helpers/EventHelper";
import {
    getBelt, getTrainingStyle
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";

const SeminarSinglePendingAsside = ({ pageData, eventType = EVENT_TYPE.seminar }) => {
    const user = useSelector(getUser);
    const [requestEventModalOpen, setRequestEventModalOpen] = useState(false)

    const requestChatId = pageData?._eventRequest?.chatId

    const handleOpenRequestModal = (data) => {

        if (user?.role === USER_ROLE.academy && !pageData.isAvailableLocation) {
            toast.error("Only academies within the selected area can apply to an instructor's pending seminar or camp");
            return
        }
        setRequestEventModalOpen({ eventType, ...data })
    }

    return (
        <>
            {pageData?.user?.role === USER_ROLE.academy || pageData?.createdBy === USER_ROLE.academy
                ? (
                    <AcademyCreator
                        pageData={pageData}
                        user={user}
                        requestChatId={requestChatId}
                        onRequest={handleOpenRequestModal}
                    />
                )
                : (
                    <InstructorCreator
                        pageData={pageData}
                        user={user}
                        requestChatId={requestChatId}
                        onRequest={handleOpenRequestModal}
                    />
                )}


            {
                requestEventModalOpen && (
                    <RequestToApplyEventModal
                        open={requestEventModalOpen}
                        handleClose={() => setRequestEventModalOpen(false)}
                        {...requestEventModalOpen}
                    />
                )
            }

        </>
    );
};




function AcademyCreator({ pageData, user, requestChatId, onRequest, agree, setAgree }) {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const [academyProfile] = useState(pageData?.userAcademy?.academyProfile);

    return (
        <div className="card-shadow px-36 py-32">
            <div className="flex items-center justify-between gap-12 mb-28">
                <div className="price-group">
                    <span className="price-group__price">
                        {EventHelper.dateRange(pageData)}
                    </span>
                </div>
            </div>

            {
                requestChatId && user && user?.role === USER_ROLE.instructor ? (
                    <Link to={`/chat/${requestChatId}`} className="btn btn--primary border-bottom mb-24 btn--lg w-full">Open Discussion</Link>
                ) : (
                    <>
                        <Button
                            className="btn btn--primary border-bottom mb-24 btn--lg w-full"
                            disabled={user && user?.role !== USER_ROLE.instructor}
                            onClick={() => onRequest({
                                eventId: pageData.id,
                                role: user?.role,
                                title: t('send_proposal')
                            })}
                        >
                            <span className="info text-20">
                                {t('send_proposal')}
                            </span>

                            <span className="ico">
                                <ChevronRignt />
                            </span>
                        </Button>
                    </>
                )

            }

            <button
                className="btn color-secondary gap-10 w-full mb-28 border-bottom pb-28"
            >
                <ShareIcon />

                <span className="underline not-italic fw-500 tt-none">
                    {t('share')}
                </span>
            </button>

            <div className="ticket-info-body ticket-info-body--no-border">
                <div className="ticket-info-body__item">
                    <span className="color-text">
                        Price range
                        <TooltipInfo content={pageData.sharedProfit ? explanations.sharedProfit : explanations.priceRangePendingAcademy} />
                        {" "}
                    </span>

                    <span className="fw-500">
                        {EventHelper.priceRange(pageData)}
                    </span>
                </div>
                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('host_single')}

                        {" "}
                    </span>

                    <span className="fw-500">
                        {academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('address')}
                    </span>

                    <span className="fw-500">
                        {
                            pageData?.address
                        }
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('belt_level')}
                    </span>

                    <span className="fw-500">
                        {getBelt(pageData?.specificBelt)?.title}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('gym_capacity')}
                    </span>

                    <span className="fw-500">
                        {pageData?.maxGymCapacity}

                        {" "}

                        {t('people')}
                    </span>
                </div>
            </div>
        </div>
    )
}

function InstructorCreator({ pageData, user, requestChatId, onRequest, agree, setAgree }) {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    return (
        <div className="card-shadow px-36 py-32">
            <div className="flex items-center justify-between gap-12 mb-28">
                <div className="price-group">
                    <span className="price-group__price">
                        {EventHelper.dateRange(pageData)}
                    </span>
                </div>
            </div>
            <>

                {
                    requestChatId && user && user?.role === USER_ROLE.academy ? (
                        <Link to={`/chat/${requestChatId}`} className="btn btn--primary border-bottom mb-24 btn--lg w-full">Open Discussion</Link>
                    ) : (
                        <>
                            <Button
                                className="btn btn--primary border-bottom mb-24 btn--lg w-full"
                                disabled={!pageData.isAvailableLocation && user && user?.role !== USER_ROLE.academy}
                                onClick={() => onRequest({
                                    eventId: pageData.id,
                                    role: user?.role,
                                    title: t('send_proposal')
                                })}
                            >
                                <span className="info text-20">
                                    {t('send_proposal')}
                                </span>

                                <span className="ico">
                                    <ChevronRignt />
                                </span>
                            </Button>
                            <p className="mb-24 color-text">Only academies within the selected area can apply to an instructor's pending seminar or camp</p>
                        </>
                    )
                }

            </>

            <button
                className="btn color-secondary gap-10 w-full mb-28 border-bottom pb-28"
            >
                <ShareIcon />

                <span className="underline not-italic fw-500 tt-none">
                    {t('share')}
                </span>
            </button>
            <div className="ticket-info-body ticket-info-body--no-border">
                <div className="ticket-info-body__item">
                    <span className="color-text">
                        Price range
                        <TooltipInfo content={pageData.sharedProfit ? explanations.sharedProfit : explanations.priceRangePendingInstructor} />
                        {" "}
                    </span>

                    <span className="fw-500">
                        {EventHelper.priceRange(pageData)}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        Location
                    </span>

                    <span className="fw-500">
                        {EventHelper.location(pageData)}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        Style
                    </span>

                    <span className="fw-500">
                        {getTrainingStyle(pageData.traningStyle)?.[0]?.title}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SeminarSinglePendingAsside;
