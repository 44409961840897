import { Country } from "country-state-city";
import { pick } from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { EVENT_STEP, USER_ROLE } from "../../../constants/enums";
import { explanations } from "../../../constants/explanations";
import { convertObjectToFormData, getFullLocation } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks";
import ButtonsGroup from "../parts/ButtonsGroup";
import DateRangeSelect from "../parts/DateRangeSelect";
import EventDatesSelect from "../parts/EventDatesSelect";
import Location from "../parts/Location";
import SpecificDatesSelect from "../parts/SpecificDatesSelect";

const TimeAndDate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { chatId: paramsChatId, seminarId } = useParams();
    const chatId = paramsChatId === "null" ? null : paramsChatId

    const user = useSelector(getUser);

    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();

    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [editedFields, setEditedFields] = useState([]);

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (!errors.hasRequiredError) {
            if (chatId && seminarId) {
                const body = new FormData();
                const dataToUpdate = pick(formData, editedFields);

                convertObjectToFormData(dataToUpdate, body, false);
                const res = await dispatch(updateSeminarFetch({
                    seminarId,
                    body
                })).unwrap();

                if (res) {
                    // navigate(`seminar-info`)
                    navigate(`/chat/${chatId}`);
                }
            } else {
                navigate("seminar-info");
            }
        }
    };

    useEffect(() => {
        setErrorConfig({
            exceptions: [
            ],
            requiredFields: [...user?.role === USER_ROLE.academy
                ? ["address", "location"]
                : [], ...user?.role === USER_ROLE.instructor
                    ? ["continent"]
                    : []
            ],
            validators: {
                availableDates: (value) => {
                    if (!value.length) return false;

                    let isValid = true;

                    value.forEach((item) => {
                        if (!item.date) {
                            isValid = false;
                        }
                    });

                    return isValid;
                }
            }
        });

    }, [formData, user]);

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    useEffect(() => {
        const location = getFullLocation([
            Country.getCountryByCode(formData.country)?.name,
            formData.city,
            formData.address
        ]);
        handlePreviewChange("location", location);
    }, [formData])

    const [isInterval, setIsInterval] = useState(true);

    return (
        <>
            <Location
                role={user?.role}
                formData={formData}
                onChange={handleStepChange}
                isErrorsShown={isErrorsShown}
                errors={errors}
                isAcademyDefault
            />
            <hr className="hr mb-42" />

            <div className="row-group mb-36">
                {!(chatId && seminarId) && (
                    <CheckboxSwitch
                        checked={isInterval}
                        onChange={() => setIsInterval(!isInterval)}
                    />
                )}
                <h6
                    className="heading"
                    style={{ marginLeft: 16 }}
                >
                    Set Date Interval
                </h6>

                <TooltipInfo content={explanations.dateInterval} />
            </div>

            {
                chatId && seminarId
                    ? (
                        <EventDatesSelect
                            dates={formData.availableDates}
                            handleChange={(dates) => handleStepChange("availableDates", dates)}
                            isErrorsShown={isErrorsShown}
                        />
                    )
                    : (
                        <DateRangeSelect
                            disabled={!isInterval}
                            // handleChange={(dates) => handleStepChange("availableDates", dates)}
                            handleChange={handleStepChange}
                            errors={errors}
                            isErrorsShown={isErrorsShown}
                            dates={formData.availableDates}
                            startAt={formData.startAt}
                            endAt={formData.endAt}

                        />
                    )}

            {
                !(chatId && seminarId) && (
                    <>
                        <div className="row-group mb-36 mt-42">
                            <CheckboxSwitch
                                checked={!isInterval}
                                onChange={() => setIsInterval(!isInterval)}
                            />

                            <h6
                                className="heading"
                                style={{ marginLeft: 16 }}
                            >
                                OR Set Specific Dates
                            </h6>
                            <TooltipInfo content={explanations.specificDates} />
                        </div>

                        <SpecificDatesSelect
                            dates={formData.availableDates}
                            disabled={isInterval}
                            errors={errors.availableDates}
                            handleChange={(dates) => handleStepChange("availableDates", dates)}
                            isErrorsShown={isErrorsShown}
                        />
                    </>
                )}

            {/* <EventDatesSelect dates={formData.availableDates} handleChange={(dates) => handleStepChange("availableDates", dates)} errors={errors} isErrorsShown={isErrorsShown} /> */}

            <hr className="hr mb-42 mt-42" />
            {
                user?.role === USER_ROLE.academy && (
                    <div className="option-group mb-36">
                        <div className="row-group">
                            <CheckboxSwitch
                                checked={formData.isAvailablePrivateLesson}
                                onChange={() => handleStepChange("isAvailablePrivateLesson", !formData.isAvailablePrivateLesson)}
                                // text={user?.role === USER_ROLE.instructor
                                //     ? "I want to do private sessions before/after the seminar"
                                //     : "Is instructor allowed to do private sessions before/after the seminar?"}
                                text={"Is instructor allowed to do private sessions before/after the seminar?"}
                            />
                        </div>
                    </div>
                )
            }

            {
                chatId && seminarId && formData.isAvailablePrivateLesson && (
                    <>
                        <div className="row-group mb-36">
                            <h6 className="heading">Private lessons available Dates</h6>
                        </div>
                        <EventDatesSelect
                            dates={formData.privateSessionDates}
                            handleChange={(dates) => handleStepChange("privateSessionDates", dates)}
                            errors={errors}
                            isErrorsShown={isErrorsShown}
                            isMultySlot
                        />
                    </>
                )
            }
            <ButtonsGroup
                chatId={chatId}
                onSubmit={handleSubmit}
                eventId={seminarId}
                step={EVENT_STEP.date}
            />
        </>
    );
};

export default TimeAndDate;
