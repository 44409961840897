import React, { useState } from "react";
import Input from "../../ui/formElements/Input";
import Button from "../../ui/buttons/Button";
import ModalTemplate from "../ModalTemplate";
import api from "../../../api";
import { toast } from "react-toastify";

const ForgotPass = ({ open, handleClose, loginHandler }) => {
    const [email, setEmail] = useState("");
    const [sended, setSended] = useState(false);
    const [sendedMessage, setSendedMessage] = useState("A one-time password recovery link has been sent to your email.");

    const send = async () => {
        const response = await api.auth.forgotPassword({ email });

        if (response?.success) {
            if (response?.message) {
                setSendedMessage(response.message);
            }

            setSended(true);
        } else {
            toast.error(response.message || "Something went wrong");
        }
    };

    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body">
                <form
                    className="form form--lg"
                    method="post"
                >
                    { sended
                        ? (
                            <div
                                className="form-header"
                                style={{
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                <div className="heading-block">
                                    <h3 className="heading">
                                        Successfully
                                    </h3>

                                    <p className="text--md desk-heading">
                                        { sendedMessage }
                                    </p>
                                </div>
                            </div>
                        )


                        : (
                            <>
                                <div className="form-header">
                                    <div className="heading-block">
                                        <h3 className="heading">
                                            Trouble with logging in?
                                        </h3>

                                        <p className="text--md desk-heading">
                                            Enter your email below to reset your password
                                        </p>
                                    </div>
                                </div>

                                <div className="form-body">
                                    <div className="row gutters-form">
                                        <div className="col-12">
                                            <Input
                                                inputClasses="input--solid"
                                                label="Email"
                                                onInput={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                placeholder="Type your email..."
                                                type="email"
                                                value={email}
                                                wrapperClasses="input--xl"
                                            />
                                        </div>

                                        <div className="col-12">
                                            <Button
                                                className="w-full btn--primary btn--lg radius"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    send();
                                                }}
                                                type="submit"
                                            >
                                                <span>
                                                    Request Reset Link
                                                </span>
                                            </Button>
                                        </div>

                                        <div className="col-12 text-center">
                                            <Button
                                                className="color-secondary btn-link"
                                                onClick={loginHandler}
                                            >
                                                <span className="--fontFamily text-normal text--md">
                                                    Back to Login
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                </form>
            </div>
        </ModalTemplate>
    );
};

export default ForgotPass;
