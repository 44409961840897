import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as LocationIcon } from "../../../assets/img/icon-decor-city.svg";
import { trainingStyle as trainingStyles } from "../../../constants";
import {
    getDateRange, getImageSrc
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";

const SeminarCardPreview = ({
    availableDates = [],
    location = "",
    seminarName = "",
    instructors = [],
    photo = "",
    fullName = "",
    minimalPriceTicket = "",
    cover = null,
    traningStyle = ""
}) => {
    const user = useSelector(getUser);
    const dateRange = useMemo(() => getDateRange(availableDates), [availableDates]);
    const trainingStyleOption = trainingStyles.find((option) => option.key === traningStyle);

    return (
        <div className="section-row-block__aside">
            {
                user && (
                    <div className="card-seminar card-shadow">
                        {/* card-seminar--empty */}
                        <div className="card-seminar__img-block">
                            <div className="card-seminar__img-info">
                                <span className="card-seminar__info-date">
                                    {!dateRange
                                        ? "Your Date"
                                        : dateRange}
                                </span>

                                {trainingStyleOption?.title && (
                                    <span className="card-seminar__gi">
                                        {
                                            trainingStyleOption.icon
                                                ? trainingStyleOption.icon
                                                : null
                                        }

                                        {trainingStyleOption?.title}
                                    </span>
                                )}
                            </div>

                            <div className="card-seminar__img">
                                {cover
                                    ? (
                                        <img
                                            src={getImageSrc(cover?.url || cover)}
                                            alt={seminarName}
                                        />
                                    )


                                    : (
                                        <img
                                            src={require("../../../assets/img/photo-placeholder.svg").default}
                                            alt=""
                                        />
                                    )}
                            </div>
                        </div>

                        <div className="card-seminar__body">
                            <div className="card-seminar__group-city">
                                <div className="ico">
                                    <LocationIcon />
                                </div>

                                <span>
                                    {location || (
                                        <span className="ttu italic">
                                            Your location
                                        </span>
                                    )}
                                </span>
                            </div>

                            <h4 className="heading ">
                                {seminarName
                                    ? seminarName
                                    : "New Seminar Name"}
                            </h4>

                            {instructors?.length
                                ? (
                                    <div className="card-seminar__group-tags">
                                        {
                                            user.role === "instructor" && (
                                                <span>
                                                    {fullName}
                                                </span>
                                            )
                                        }

                                        {
                                            instructors?.map((instructor) => (
                                                <span key={instructor.id}>
                                                    {instructor?.fullName}
                                                </span>
                                            )

                                            )
                                        }
                                    </div>
                                )


                                : (
                                    <div className="card-user-block">
                                        {
                                            photo
                                                ? (
                                                    <div className="card-user-block__icon">
                                                        <img
                                                            alt={fullName}
                                                            src={getImageSrc(photo)}
                                                        />
                                                    </div>
                                                )



                                                : null
                                        }

                                        <div className="card-user-block__name">
                                            {fullName}
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className="card-seminar__footer">
                            <div className="row-group gap--xs">
                                <button
                                    className="btn btn--primary btn--md radius disabled"
                                    href="#"
                                >
                                    Preview
                                </button>
                            </div>

                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default SeminarCardPreview;
