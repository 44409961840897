import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addPaymentCardFetch, setupIntentsFetch } from "../../redux/thunks/paymentsThunks";
import Button from "../ui/buttons/Button";
import CheckboxSwitch from "../ui/formElements/CheckboxSwitch";
import ModalTemplate from "./ModalTemplate";

const stripeKey = process.env.REACT_APP_STRIPE_KEY

const stripePromise = loadStripe(stripeKey);

const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    appearance: {
    },
};

const AddPaymentCardModal = ({ open, handleClose, onSubmit }) => {
    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body p-0">
                <div className="form">
                    <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Add Payment Card
                            </h3>

                            {/* <p className="text--md desk-heading">Write a Review</p> */}
                        </div>
                    </div>
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm onClose={handleClose} onSubmit={onSubmit} />
                    </Elements>
                </div>
            </div>
        </ModalTemplate >
    );
};

function CheckoutForm({ onClose, onSubmit }) {
    const dispatch = useDispatch()
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDefault, setIsDefault] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (elements == null) {
            return;
        }

        try {
            setIsLoading(true)
            const { error: submitError } = await elements.submit();

            if (submitError) {
                setErrorMessage(submitError.message);

                return;
            }

            const cardElement = elements.getElement(CardElement);
            const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            })

            if (paymentMethodError) {
                setErrorMessage(paymentMethodError.message);

                return
            }

            const paymentIntent = await dispatch(setupIntentsFetch()).unwrap()

            const { setupIntent, error: setupIntentError } = await stripe.confirmCardSetup(paymentIntent.client_secret, { payment_method: paymentMethod.id });

            if (setupIntentError) {
                setErrorMessage(setupIntentError?.message);
            } else {
                const res = await dispatch(addPaymentCardFetch({
                    payment_method: setupIntent.payment_method,
                    isDefault
                })).unwrap()

                if (res?.success) {
                    onSubmit && onSubmit()
                    onClose && onClose()
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    };

    const cardStyle = {
        style: {
            base: {
                maxWidth: '100%',
                width: '100%',
                backgroundColor: 'transparent',
                // backgroundColor: '#F5F5F5',
                border: '1px solid transparent',
                padding: '0 16px',
                flex: 'auto',
                borderRadius: '0px',
                lineHeight: '42px',
                fontSize: '15px',
                fontFamily: '"Roboto", sans-serif',
                color: '#02020A',
                "::placeholder": {
                    color: "#67676C"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-body cards-templates-container modal__body scroll-custom" style={{ height: 260 }}>
                <div className="form-group input--md">
                    <div className="input input--solid">
                        <div className="input-wrapper">
                            <CardElement options={cardStyle} />
                        </div>
                    </div>
                </div>
                {errorMessage && <div>{errorMessage}</div>}
                <div className="mt-40">
                    <CheckboxSwitch
                        checked={isDefault}
                        onChange={() => setIsDefault(!isDefault)}
                        text="Make this card the default"
                    />
                </div>
            </div>
            <div className="modal__footer justify-between mt-24">
                <button
                    className="btn btn--lg"
                    onClick={onClose}
                    type="button"
                >
                    <span>
                        Cancel
                    </span>
                </button>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    className="btn btn--lg btn--primary"
                    disabled={!stripe || !elements || isLoading}
                >
                    <span>
                        Submit
                    </span>
                </Button>
            </div>

        </form>
    );
}

;




export default AddPaymentCardModal;
