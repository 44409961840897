import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import api from "../../api";
import InputPass from "../../components/ui/formElements/InputPass";
import { setToken } from "../../redux/slices/applicationSlice.js";

const Security = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        password: "",
        newPassword: ""
    });
    const [confirmPassword, setConfirmPassword] = useState("");

    const save = async () => {
        if (!data?.password?.length || !data?.newPassword?.length || !confirmPassword?.length) {
            toast.error("All fields are required");

            return;
        }

        if (data.newPassword !== confirmPassword) {
            toast.error("\"CONFIRM PASSWORD\" is not equal to \"NEW PASSWORD\"");

            return;
        }

        const updateUserRes = await api.users.updateUser(data);

        if (updateUserRes.success) {
            toast.success(updateUserRes.message || "Updated");

            if (updateUserRes?.token) {
                localStorage.setItem("authToken", updateUserRes.token);
                dispatch(setToken(updateUserRes.token));
            }
        } else {
            toast.error(updateUserRes?.message || "Error");
        }
    };

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    Security
                </h3>
            </div>

            <form
                className="form form-settings"
                method="post"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <InputPass
                                        forgotPass={false}
                                        formGroupClass="input--lg input--icon-right"
                                        inputClass="input--solid w-full"
                                        inputClasses="input--solid"
                                        label="Current Password"
                                        onInput={(e) => {
                                            setData((prev) => {
                                                return {
                                                    ...prev,
                                                    password: e.target.value
                                                };
                                            });
                                        }}
                                        placeholder="Current Password"
                                        value={data?.password}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <InputPass
                                        forgotPass={false}
                                        formGroupClass="input--lg input--icon-right"
                                        inputClass="input--solid w-full"
                                        inputClasses="input--solid"
                                        label="New Password"
                                        onInput={(e) => {
                                            setData((prev) => {
                                                return {
                                                    ...prev,
                                                    newPassword: e.target.value
                                                };
                                            });
                                        }}
                                        placeholder="New Password"
                                        value={data?.newPassword}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <InputPass
                                        forgotPass={false}
                                        formGroupClass="input--lg input--icon-right"
                                        inputClass="input--solid w-full"
                                        inputClasses="input--solid"
                                        label="Confirm Password"
                                        onInput={(e) => {
                                            setConfirmPassword(e.target.value);
                                        }}
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-footer">
                    <div className="flex-group justify-end">
                        <button
                            className="btn btn--primary btn--sm"
                            onClick={(e) => {
                                e.preventDefault();
                                save();
                            }}
                            type="submit"
                        >
                            <span>
                                Save Changes
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Security;
