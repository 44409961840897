import {
    createSlice, current
} from "@reduxjs/toolkit";
const initialState = {
    history: [],
    activeIndex: null,
    historyIndex: 0,
    currentTemplate: {}
};
const editorSlice = createSlice({
    name: "editor",
    initialState,
    reducers: {
        setCurrentStyles (state, { payload }) {
            if (typeof state.activeIndex === "number" && state.currentTemplate.blocks?.length > state.activeIndex) {
                const { name, value } = payload;

                state.currentTemplate.blocks[state.activeIndex].attributes.style[name] = value;
                state.history = [...state.history.slice(0, state.historyIndex + 1), state.currentTemplate];
                state.historyIndex = state.history.length - 1;
            }
        },
        setCurrentTemplate (state, { payload }) {
            state.currentTemplate = payload;
        },
        setCurrentTemplateImage (state, { payload }) {
            if (!state.currentTemplate.blocks?.length) return;

            const image = state.currentTemplate.blocks.find((block) => block.id === "bg-image");

            image.attributes.src = payload;
        },
        setActiveIndex (state, { payload }) {
            state.activeIndex = payload;
        },
        addToHistory (state, { payload }) {
            state.history.push(payload);
            state.historyIndex = state.history.length - 1;
        },
        clearHistory (state) {
            state.history = [];
            state.historyIndex = 0;
        },
        updateHistory (state, { payload }) {
            state.history = [...state.history.slice(0, state.historyIndex + 1), payload];
            state.historyIndex = state.history.length - 1;
        },
        undo (state) {
            if (state.historyIndex > 0) {
                state.historyIndex--;

                if (!state.history[state.historyIndex]) return;

                state.currentTemplate = state.history[state.historyIndex];
            }
        },
        redo (state) {
            if (state.historyIndex < state.history.length - 1) {
                state.historyIndex++;

                if (!state.history[state.historyIndex]) return;

                state.currentTemplate = state.history[state.historyIndex];
            }
        },
        reset (state) {
            if (state.history.length) {
                state.currentTemplate = state.history[0];
                state.history = [state.currentTemplate];
                state.historyIndex = 0;
            }
        }
    },
    extraReducers: (builder) => {
    }
});

export const {
    setCurrentStyles,
    setActiveIndex,
    addToHistory,
    updateHistory,
    clearHistory,
    undo,
    redo,
    reset,
    setCurrentTemplate,
    setCurrentTemplateImage
} = editorSlice.actions;

export const getCurrentStyles = (state) => {
    const { currentTemplate, activeIndex } = state.editor;

    if (typeof activeIndex === "number" && currentTemplate.blocks?.length > activeIndex) {
        return currentTemplate.blocks[activeIndex].attributes.style;
    }
};

export default editorSlice.reducer;
