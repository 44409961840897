import React, { useRef } from "react";
import CardSeminar from "./cards/CardSeminar";
import {
    Swiper, SwiperSlide
} from "swiper/react";
import {
    Navigation, Pagination
} from "swiper/modules";
import Button from "../ui/buttons/Button";
import { useTranslation } from "react-i18next";

const SeminarsLockedAt = ({
    data = [], sectionTitle, sectionClasses = "pb-160", swiperClasses, isMobile = false
}) => {
    const { t } = useTranslation();
    const nextRef = useRef(null);
    const prevRef = useRef(null);

    return (
        <section className={`${sectionClasses} swiper-mobile-custom`}>
            <div className="container">

                <div className="flex items-center justify-between flex-wrap mb-32">
                    <h4 className="heading">
                        {sectionTitle}
                    </h4>

                    {!!data?.length && (
                        <div className="flex items-center gap-12 swiper-control-btns">
                            <div className="swiper-btns-group">
                                <div
                                    className="swiper-btn swiper-arrow-prev"
                                    ref={prevRef}
                                >
                                    <svg
                                        fill="none"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g>
                                            <path
                                                d="M7.5 3.75L13.75 10L7.5 16.25"
                                                stroke="currentColor"
                                                strokeLinecap="square"
                                                strokeWidth="1.2"
                                            />
                                        </g>
                                    </svg>
                                </div>

                                <div
                                    className="swiper-btn swiper-arrow-next"
                                    ref={nextRef}
                                >
                                    <svg
                                        fill="none"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g>
                                            <path
                                                d="M7.5 3.75L13.75 10L7.5 16.25"
                                                stroke="currentColor"
                                                strokeLinecap="square"
                                                strokeWidth="1.2"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <a
                                className="btn btn--md"
                                href="#"
                            >
                               {t('show_all')}
                            </a>
                        </div>
                    )}
                </div>

                {
                    data?.length
                        ? (
                            <Swiper
                                modules={[Navigation, Pagination]}
                                navigation={{
                                    nextEl: ".swiper-arrow-next",
                                    prevEl: ".swiper-arrow-prev"
                                }}
                                spaceBetween={24}
                                slidesPerView={3.2}
                                breakpoints={{
                                    0: { slidesPerView: 1 },
                                    993: { slidesPerView: 3.2 }
                                }}
                                pagination={{
                                    clickable: true,
                                    el: ".swiper-pagination"
                                }}
                                className={`swiper-current-seminars ${swiperClasses}`}
                            >
                                {
                                    data.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <CardSeminar data={item?.seminar || item?.plancamp || item} />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        )


                        : (
                            <h4 className="mb-24">
                                {t('seminars.no_data')}
                            </h4>
                        )


                }

                <div className="swiper-pagination" />

                {
                    !!data?.length && (
                        <Button className="btn btn--md show-all-swiper-solo-slide">
                            {t('show_all')}
                        </Button>
                    )}
            </div>
        </section>
    );
};

export default SeminarsLockedAt;
