import React, { useState } from 'react'
import InviteInstructorModal from '../../../components/modals/InviteInstructorModal'
import TooltipInfo from '../../../components/parts/TooltipInfo'
import { explanations } from '../../../constants/explanations'
import { getImageSrc } from '../../../helpers/utils'

const InviteInstructors = ({ onChange, preview, onPreviewChange }) => {
    const [inviteInstructorModalOpen, setInviteInstructorModalOpen] = useState(false);
    const [instructors, setInstructors] = useState(preview?.instructors);

    const handleAddInstructors = (data) => {
        setInstructors(data);
        
        onChange("instructors", data.map((item) => item?.user?.id));
        setInviteInstructorModalOpen(false);
        onPreviewChange("instructors", data);
    };

    const handleRemoveInstructor = (index) => {
        const updatedInstructors = instructors.filter((_, i) => i !== index);

        onChange("instructors", updatedInstructors.map((item) => item?.user?.id));
        setInstructors(updatedInstructors);
        onPreviewChange("instructors", updatedInstructors);
    };

    return (
        <div className="col-12">
            <hr className="hr-horisontal mb-42" />

            <div className="mb-42">
                <div className="row-group mb-32">
                    <h6 className="heading">
                        Invite Additional Instructor {" "}

                        <span className="color-grey">
                            (optional)
                        </span>
                    </h6>
                    <TooltipInfo content={explanations.inviteInstructor} />
                </div>
                {
                    !!instructors?.length && (
                        <ul className="invite-instructors-list mb-32">
                            {
                                instructors.map((instructor, index) => (
                                    <li
                                        key={index}
                                        className="invite-instructor__item"
                                    >
                                        <div className="invite-instructor__img">
                                            <img
                                                src={getImageSrc(instructor.photo)}
                                                alt={instructor.fullName}
                                            />
                                        </div>

                                        <div className="invite-instructor__group-info">
                                            <span className="invite-instructor__name">
                                                {instructor.fullName}
                                            </span>

                                            <span className="invite-instructor__email color-grey fs-14">
                                                {instructor?.user?.email}
                                            </span>
                                        </div>

                                        <button
                                            onClick={() => handleRemoveInstructor(index)}
                                            className="btn"
                                        >
                                            <svg
                                                width="24"
                                                height="25"
                                                viewBox="0 0 24 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g>
                                                    <path
                                                        d="M18.75 5.75L5.25 19.25"
                                                        stroke="#BDBDBD"
                                                        strokeWidth="1.2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                    </path>

                                                    <path
                                                        d="M18.75 19.25L5.25 5.75"
                                                        stroke="#BDBDBD"
                                                        strokeWidth="1.2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                    </path>
                                                </g>

                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            width="24"
                                                            height="24"
                                                            fill="white"
                                                            transform="translate(0 0.5)"
                                                        >
                                                        </rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button>
                                    </li>
                                )

                                )
                            }
                        </ul>
                    )
                }

                <button
                    className="btn btn--sm btn--primary"
                    data-modal="#invite-instructors"
                    onClick={() => setInviteInstructorModalOpen(true)}
                >
                    <span className="info">
                        Send Invite
                    </span>

                    <span className="ico">
                        <svg
                            fill="none"
                            height="14"
                            viewBox="0 0 14 14"
                            width="14"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_910_51633)">
                                <path
                                    d="M5.25 2.625L9.625 7L5.25 11.375"
                                    stroke="#02020A"
                                    strokeLinecap="square"
                                    strokeWidth="1.6"
                                />
                            </g>

                            <defs>
                                <clipPath id="clip0_910_51633">
                                    <rect
                                        fill="white"
                                        height="14"
                                        width="14"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            </div>
            {
                inviteInstructorModalOpen && (
                    <InviteInstructorModal
                        open={inviteInstructorModalOpen}
                        handleClose={() => setInviteInstructorModalOpen(false)}
                        onSubmit={handleAddInstructors}
                        data={instructors}
                    />
                )
            }
        </div>
    )
}

export default InviteInstructors