import React, { useState } from "react";
import Button from "../../ui/buttons/Button";
import ModalTemplate from "../ModalTemplate";
import api from "../../../api";
import { toast } from "react-toastify";
import InputPass from "../../ui/formElements/InputPass";
import {
    useParams, useSearchParams
} from "react-router-dom";

const RestorePass = ({ open, handleClose, loginHandler }) => {
    const { restoreToken } = useParams();
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const [sended, setSended] = useState(false);
    const [sendedMessage, setSendedMessage] = useState("Password changed");
    const [data, setData] = useState({
        password: "",
        confirm: ""
    });

    const send = async () => {
        if (data.password !== data.confirm) {
            toast.error("\"Confirm Password\" is not equal to \"Password\"");

            return;
        }

        const response = await api.auth.restorePassword({
            email,
            password: data.password,
            token: restoreToken
        });

        if (response?.success) {
            if (response?.message) {
                setSendedMessage(response.message);
            }

            setSended(true);
        } else {
            toast.error(response.message || "Something went wrong");
        }
    };

    return (
        <ModalTemplate
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body">
                <form
                    className="form form--lg"
                    method="post"
                >
                    { sended
                        ? (
                            <div
                                className="form-header"
                                style={{
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                <div className="heading-block">
                                    <h3 className="heading">
                                        Successfully
                                    </h3>

                                    <p className="text--md desk-heading">
                                        { sendedMessage }
                                    </p>
                                </div>
                            </div>
                        )


                        : (
                            <>
                                <div className="form-header">
                                    <div className="heading-block">
                                        <h3 className="heading">
                                            Restore password
                                        </h3>

                                        <p className="text--md desk-heading">
                                            Enter and confirm your new password
                                        </p>
                                    </div>
                                </div>

                                <div className="form-body">
                                    <div className="row gutters-form">
                                        <div className="col-12 form form--md">
                                            <InputPass
                                                forgotPass={false}
                                                formGroupClass="input--lg input--icon-right"
                                                inputClass="input--solid w-full"
                                                label="Password"
                                                onInput={(e) => {
                                                    setData({
                                                        ...data,
                                                        password: e.target.value
                                                    });
                                                }}
                                                placeholder="Password"
                                                value={data.password}
                                            />

                                            <InputPass
                                                forgotPass={false}
                                                formGroupClass="input--lg input--icon-right"
                                                inputClass="input--solid w-full"
                                                label="Confirm Password"
                                                onInput={(e) => {
                                                    setData({
                                                        ...data,
                                                        confirm: e.target.value
                                                    });
                                                }}
                                                placeholder="Confirm Password"
                                                value={data.confirm}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <Button
                                                className="w-full btn--primary btn--lg radius"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    send();
                                                }}
                                                type="submit"
                                            >
                                                <span>
                                                    Submit
                                                </span>
                                            </Button>
                                        </div>

                                        <div className="col-12 text-center">
                                            <Button
                                                className="color-secondary btn-link"
                                                onClick={loginHandler}
                                            >
                                                <span className="--fontFamily text-normal text--md">
                                                    Back to Login
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                </form>
            </div>
        </ModalTemplate>
    );
};

export default RestorePass;
