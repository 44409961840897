import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createReviewFetch } from "../../redux/thunks/reviewsThunks";
import Button from "../ui/buttons/Button";
import Input from "../ui/formElements/Input";
import Textarea from "../ui/formElements/Textarea";
import ModalTemplate from "./ModalTemplate";
import { toast } from "react-toastify";

function RatingInput({ value = 1, onChange }) {
    const [hovered, setHovered] = useState(0)

    const handleChange = (val) => {
        onChange && onChange(val)
    }

    return (
        <div className="row-group gap--xs">
            <div className="row-group">
                {
                    Array.from({ length: 5 }).map((_, index) => (
                        <button
                            type="button"
                            onMouseEnter={() => setHovered(index + 1)}
                            onMouseLeave={() => setHovered(0)}
                            onClick={() => handleChange(index + 1)}
                            key={index}
                            className="ico ico--lg"
                        >
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9 12.9262L4.63425 15L5.541 10.41L2.25 7.044L6.88425 6.46575L9 2.25L11.1158 6.46575L15.75 7.044L12.459 10.41L13.3658 15L9 12.9262Z"
                                    fill={(hovered || value) > index ? "#9747FF" : "#F5F5F5"}
                                >
                                </path>
                            </svg>
                        </button>
                    ))
                }
            </div>
            <span className="color-default fw-500">
                {value}
            </span>
        </div>
    )
}

const WriteReviewModal = ({ open, handleClose }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [formData, setFormData] = useState({
        rating: 1,
        topic: "",
        content: "",
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [errors, setErrors] = useState({})

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        setIsErrorsShown(false)
        const res = await dispatch(createReviewFetch({
            userId: id,
            comment: formData.content,
            title: formData.topic,
            rating: formData.rating
        }))

        setIsLoading(false)

        if(!res.payload.success) {
            setIsErrorsShown(true)
            const grouped = res.payload.errors.reduce((acc, { field, msg }) => {
                acc[field] = acc[field] ? acc[field] + '. ' + msg : msg;

                return acc;
            }, {});

            setErrors(grouped)

            if(grouped.userId) {
                toast.error(grouped.userId)
            }

            return
        } else {
            toast.success('Review created successfully!')
            handleClose && handleClose();
        }
    };

    return (
        <ModalTemplate
            modalSize="modal__dialog--xl"
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body p-0">
                <form className="form">
                    <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Write a Review
                            </h3>

                            {/* <p className="text--md desk-heading">Write a Review</p> */}
                        </div>
                    </div>
                    <div className="form-body cards-templates-container modal__body scroll-custom">
                        <div className="row gx-16 py-24">
                            <div className="col-12">
                                <RatingInput value={formData.rating} onChange={(value) => handleChange("rating", value)} />
                            </div>
                            <div className="col-12">
                                <Input
                                    error={isErrorsShown && errors?.title}
                                    inputClasses="input--solid"
                                    label="Title"
                                    onChange={(e) => handleChange("topic", e.target.value)}
                                    placeholder="Please write topic here..."
                                    type="text"
                                    value={formData.topic}
                                    wrapperClasses="input--lg"
                                />
                            </div>
                            <div className="col-12">
                                <Textarea
                                    error={isErrorsShown && errors?.comment}
                                    label="Comment"
                                    onChange={(e) => handleChange("content", e.target.value)}
                                    placeholder="Please write review here..."
                                    style={{ minHeight: 140 }}
                                    textareaClasses="input--solid"
                                    value={formData.content}
                                    wrapperClasses="input--lg"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal__footer justify-between mt-24">
                        <button
                            className="btn btn--lg"
                            onClick={handleClose}
                        >
                            <span>
                                Cancel
                            </span>
                        </button>

                        <Button
                            isLoading={isLoading}
                            className="btn btn--lg btn--primary"
                            onClick={handleSubmit}
                            type="button"
                        >
                            <span>
                                Submit
                            </span>
                        </Button>
                    </div>
                </form>
            </div>
        </ModalTemplate >
    );
};

export default WriteReviewModal;
