import classNames from "classnames";
import {
    useEffect, useMemo, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useSearchParams
} from "react-router-dom";
import api from "../../api";
import ReschedulePrivateLessonModal from "../../components/modals/ReschedulePrivateLessonModal";
import { PaginationTemplate } from "../../components/parts/PaginationTemplate";
import TooltipInfo from "../../components/parts/TooltipInfo";
import Button from "../../components/ui/buttons/Button";
import Loader from "../../components/ui/Loader";
import { EVENT_TYPE, USER_ROLE } from "../../constants/enums";
import { calcTotalHours, getDateRange, getImageSrc, getTrainingStyle } from "../../helpers/utils";
import { useTabs } from "../../hooks/useTabs";
import { getUser } from "../../redux/slices/applicationSlice";
import {
    acceptInstructorCampRequestFetch, rejectCampRequestFetch
} from "../../redux/thunks/planCampThuncks";
import { acceptRequestsPrivateLessonsFetch, rejectRequestsPrivateLessonFetch } from "../../redux/thunks/privateLessonsThunks";
import {
    acceptInstructorRequestFetch, cancelSeminarRequestFetch
} from "../../redux/thunks/seminarsThunks";

export const InstructorsAndAcademiesRequests = ({ userRole }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const eventType = searchParams.get("eventType")

    const [requests, setRequests] = useState([]);
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(false);


    const [counts, setCounts] = useState({
        "upcomingSeminarsCount": 0,
        "upcomingPlancampsCount": 0,
        "plannedSeminars": 0,
        "plannedPlancamps": 0,
        "inProgressPlancampsCount": 0,
        "inProgressSeminarsCount": 0,
        "previousSeminars": 0,
        "previousPlancamps": 0,
        "newRequestsSeminarCount": 0,
        "newRequestsPlancampCount": 0,
        "seminarRequestsCount": 0,
        "plancampRequestsCount": 0,
        "countMyOwnEvents": 0,
        countMySeminarEvents: 0,
        countMyPlancampEvents: 0,
        countPrivateLessons: 0,
        academyHostRequestPlancampCount: 0,
        academyHostRequestSeminarCount: 0
    });

    const getCounts = async () => {
        const res = await api.requests.getCounts()
        if (res?.success) {
            setCounts(res.data)
        }
    }

    const tabs = useMemo(() => [
        {
            label: "Seminar/Camp Requests",
            id: "requests",
            count: counts.seminarRequestsCount + counts.plancampRequestsCount,
            fetcher: (eventType) => api.requests.getRequests(`event=${eventType}`)
        },

        {
            label: "Seminar/Camps Invitations",
            id: "invitations",
            count: counts.countMySeminarEvents + counts.countMyPlancampEvents,
            fetcher: (eventType) => api.requests.getRequests(`is_my_own=true&event=${eventType}`)
        },
        {
            label: "Seminar/Camps hosting",
            id: "hosting",
            count: counts.academyHostRequestPlancampCount + counts.academyHostRequestSeminarCount,
            fetcher: (eventType) => api.requests.getHostingRequests(`type=${eventType}`),
        },
        {
            label: "Private Lesson Requests",
            id: "private",
            count: counts.countPrivateLessons,
            fetcher: api.requests.getRequestsPrivateLessons,
            acceptOnly: [USER_ROLE.instructor]
        },
    ], [counts])

    const { activeTab, setActiveTab, activeTabObject } = useTabs(tabs, "tab")

    const getRequests = async () => {
        if (!activeTabObject?.fetcher) return
        setIsLoading(true);
        getCounts()
        const res = await activeTabObject.fetcher(eventType);
        console.log(res);

        if (res?.success) {
            setRequests(res.data);
            setPagination(res.pagination)
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getRequests()
    }, [searchParams]);

    const handleChangeTab = (tab) => {
        setRequests([])
        setActiveTab(tab);
    };
    useEffect(() => {
        if (eventType) return
        setSearchParams(prev => ({
            ...prev,
            eventType: EVENT_TYPE.seminar
        }))
    }, [eventType])

    return (
        <div className="tab-seminars tabs">
            <div className="tabs__header flex items-center border-bottom gap-24 pb-24 pt-24 mb-24">
                <div className="tabs__nav row-group gap-24 flex-auto">
                    {
                        tabs.filter(({ acceptOnly }) => !acceptOnly?.length || acceptOnly?.includes(userRole)).map(tab => (
                            <button
                                key={tab.id}
                                className={classNames("tab-link", { "active-tab": activeTab === tab.id })}
                                onClick={() => handleChangeTab(tab.id)}
                            >
                                <span className="tab-link__text">
                                    {tab.label}
                                </span>

                                <span className="tab-link__number">
                                    {tab.count}
                                </span>
                            </button>
                        ))
                    }
                </div>
            </div>

            <div>
                <div className="tab active-tab">
                    <EventTypeSwitcher />
                    {
                        requests?.length && !isLoading
                            ? (
                                <>

                                    <div className="request-items mb-24">
                                        {requests.map((item) => activeTab === "private" ? (
                                            <LessonsRequestItem
                                                key={item.id}
                                                data={item}
                                                update={getRequests}
                                            />
                                        ) : (
                                            <RequestItem
                                                key={item.id}
                                                data={item}
                                                getRequests={getRequests}
                                                isHosting={activeTab === "hosting"}
                                            />
                                        ))}
                                    </div>
                                </>
                            )

                            : isLoading
                                ? <Loader />
                                : (
                                    <h2>
                                        There is no requiests yet
                                    </h2>
                                )
                    }
                    <PaginationTemplate {...pagination} />
                </div>
            </div>
        </div>
    );
};

function EventTypeSwitcher() {
    const [searchParams, setSearchParams] = useSearchParams({ eventType: EVENT_TYPE.seminar })
    const types = [
        {
            label: "Seminars",
            value: EVENT_TYPE.seminar,
        },
        {
            label: "Camps",
            value: EVENT_TYPE.camp,
        },
    ]
    const handleChangeTab = (type) => {
        setSearchParams(prev => {
            prev.set("eventType", type)
            return prev
        })
    }

    return (
        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
            <div className="select-checkers">
                {types.map((item, index) => (
                    <Button
                        onClick={() => handleChangeTab(item.value)}
                        key={index}
                        className={
                            classNames('btn btn--md radius',
                                { 'btn--default': item.value === searchParams.get("eventType") })
                        }
                    >
                        <span>{item.label}</span>
                    </Button>
                ))}

            </div>
        </div>
    )
}

function RequestItem({ data, getRequests, isHosting }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const me = useSelector(getUser);
    const isSeminar = data.object_type === EVENT_TYPE.seminar;
    const isCamp = data.object_type === EVENT_TYPE.camp;

    const handleApprove = async () => {
        const requestId = data.id;
        if (!requestId) return;

        if (isHosting) {
            const res = await api.requests.acceptHostingRequests({ requestId });
            const chatId = res?.data?.chatId;

            if (chatId) {
                navigate(`/chat/${chatId}`);
            }
        } else {
            const res = isSeminar
                ? await dispatch(acceptInstructorRequestFetch({ requestInstructorId: requestId })).unwrap()
                : await dispatch(acceptInstructorCampRequestFetch({ instructorId: requestId })).unwrap();

            const chatId = res?.data?.chatId;

            if (chatId) {
                navigate(`/chat/${chatId}`);
            }

        }
        getRequests();
    };

    const handleCancelRequest = async () => {
        const requestId = data.id;
        if (!requestId) return;

        if (isHosting) {
            const res = await api.requests.declineHostingRequests({ requestId });
            const chatId = res?.data?.chatId;

            if (chatId) {
                navigate(`/chat/${chatId}`);
            }
        } else {
            if (isSeminar) {
                await dispatch(cancelSeminarRequestFetch({ requestId })).unwrap();
            } else if (isCamp) {
                await dispatch(rejectCampRequestFetch({ requestId })).unwrap();
            }
        }
        getRequests();
    };
    const user = data.owner;
    const inviteMessage = data.inviteMessage;
    const isMy = data?.owner?.id === me?.id;
    const event = data.seminar || data.plancamp;
    const hrefPrefix = event?.userAcademy
        ? "planed-by-academy"
        : "planed-by-insctructor";

    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img
                        alt={user?.fullName || user?.name}
                        src={getImageSrc(user?.photo)}
                    />
                </div>

                <div
                    className="col-group gap-4"
                    style={{ width: 224 }}
                >
                    <h6 className="heading truncate flex-auto">
                        {event?.name || event?.seminarName}
                    </h6>
                    <TooltipInfo content={inviteMessage}>
                        <p className="color-text line-clamp-2 flex-auto">
                            {isMy ? event?.summary : inviteMessage}
                        </p>
                    </TooltipInfo>
                </div>
                {!data?.isReaded && !isMy && <span className="badge badge--primary">New</span>}
            </div>

            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">
                        Price Range
                    </span>

                    <p className="fw-500 text--sm">

                        ${event?.seminarMinPrice}
                        {" - "}
                        ${event?.seminarMaxPrice}
                    </p>
                </div>

                {/* <div className="col-group gap--xs">
                    <span className="label">
                        Ticket Price
                    </span>

                    <p className="fw-500 text--sm">
                        {event?.ticketPrice}
                    </p>
                </div> */}

                <div className="col-group gap--xs">
                    <span className="label">
                        teaching
                    </span>

                    <p className="fw-500 text--sm">
                        {getTrainingStyle(event?.traningStyle)?.[0]?.title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Date
                    </span>

                    <p className="fw-500 text--sm">
                        {new Date(event.startAt || event.seminarDates?.[0]?.date).toLocaleDateString()}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                {/* <Button className="btn btn--sm btn--square notification" count={data.count}> */}
                {/* <Link to={`/chat/${data.chat?.id}`} className="btn btn--sm btn--square">
                    <span className="ico">
                        <ChatIcon />
                    </span>
                </Link> */}
                <Link
                    className="btn btn--sm"
                    to={`/${hrefPrefix}/${event?.id}`}
                >
                    Show Details
                </Link>

                {!isMy && (
                    <Button
                        className="btn btn--sm btn--primary"
                        onClick={handleApprove}
                    >
                        Start Discussion
                    </Button>
                )}

                <Button
                    className="btn btn--sm btn--default"
                    onClick={handleCancelRequest}
                >
                    {isMy ? "Cancel" : "Decline"}
                </Button>
            </div>
        </div>
    );
};

function LessonsRequestItem({ data, update }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);

    const handleApprove = async (id) => {
        setIsApproving(true);
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(data.id)).unwrap();

        if (res?.success) {
            navigate(`/chat/${res.data?.chat?.id}`);
            update && update();
        }

        setIsApproving(false);
    };

    const handleReject = async (id) => {
        setIsRejecting(true);
        const res = await dispatch(rejectRequestsPrivateLessonFetch(data.id)).unwrap();

        if (res?.success) {
            update && update();
        }

        setIsRejecting(false);
    };

    const studentProfile = data.student?.studentProfile;

    const totalHours = useMemo(() => calcTotalHours(data.dates), [data.dates])
    const totalPrice = data?.priceInHour * totalHours

    return (
        <>
            <div className="request-item">
                <div className="request-item__user">
                    <div className="ico ico--md img-wrapper rounded-full">
                        <img
                            alt="student"
                            src={getImageSrc(studentProfile?.photo)}
                        />
                    </div>

                    <div
                        className="col-group gap-4"
                        style={{ width: 224 }}

                    >
                        <h6 className="heading">
                            {studentProfile?.fullName}
                        </h6>

                        <p className="color-text">
                            {getDateRange(data?.dates)}
                        </p>
                    </div>
                    {!data?.isReaded && <span className="badge badge--primary">New</span>}
                </div>

                <div className="request-item__details">
                    <div className="col-group gap--xs">
                        <span className="label">
                            Total Price
                        </span>

                        <p className="fw-500 text--sm">
                            ${totalPrice}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            People
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.peopleAttending}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            teaching
                        </span>

                        <p className="fw-500 text--sm">
                            {getTrainingStyle(data?.style)?.[0]?.title}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            Location
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.address}
                        </p>
                    </div>
                </div>

                <div className="request-item__action">
                    {/* <button
                        className="btn btn--sm"
                        onClick={() => setOpenRescheduleModal(true)}
                        type="button"
                    >
                        <span className="ico">
                            <CalendarIcon />
                        </span>

                        RESCHEDULE
                    </button> */}

                    {/* <a href="#" className="btn btn--sm">Show Details</a>*/}

                    <button
                        className="btn btn--sm "
                        isLoading={isRejecting}
                        onClick={handleReject}
                    >
                        Decline
                    </button>

                    <Button
                        className="btn btn--sm btn--primary"
                        isLoading={isApproving}
                        onClick={handleApprove}
                    >
                        Start Discussion
                    </Button>

                    {/* <Link
                        className="btn btn--sm btn--square notification"
                        count="1"
                        to={`/chat/${data?.chat?.id}`}
                    >
                        <span className="ico">
                            <ChatIcon />
                        </span>
                    </Link> */}
                </div>
            </div>

            <ReschedulePrivateLessonModal
                data={data.dates}
                handleClose={() => {
                    setOpenRescheduleModal(false);
                    update && update()
                }}
                id={data.id}
                open={openRescheduleModal}
            />
        </>
    );
}