import React from "react";
import HeaderDefault from "../components/headers/HeaderDefault";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";

const DefaultLayout = ({ headerClass = "", mainClass = "", wrapperClass = "", footerHide = false }) => {
    return (
        <>
            <HeaderDefault className={headerClass} />

            <div className={`wrapper ${wrapperClass}`}>
                <main className={`content ${mainClass} flex flex-col`}>
                    <Outlet />
                </main>

                {!footerHide && <Footer />}
            </div>
        </>
    );
};

export default DefaultLayout;
