export async function getCoordinates (address) {
    // const address = '1600 Pennsylvania Ave NW, Washington, DC 20500';
    const mapBoxToken = "pk.eyJ1IjoibWF4aW1va29semluIiwiYSI6ImNsdGU5OW1lMzBlOTQyaXF1OHQ3a3FyZzQifQ.iHTE8f0GCRZQyZiAX8rfBw";
    const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${mapBoxToken}&limit=1`;

    try {
        const response = await fetch(geocodingUrl);

        if (response.ok) {
            const data = await response.json();

            if (data.features && data.features.length > 0) {
                const coordinates = data.features[0].geometry.coordinates;

                return { coordinates: {
                    lat: coordinates[1],
                    lon: coordinates[0]
                } };
            } else {
                console.log("Not found");
            }
        } else {
            console.error(response.status, response.statusText);
        }
    } catch (error) {
        console.error(error);
    }
}
