import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/applicationSlice";
import { InstructorsAndAcademiesRequests } from "./InstructorsAndAcademiesRequests";
import { StudentProfileRequests } from "./studentProfilePages/StudentProfileRequests";
import { USER_ROLE } from "../../constants/enums";

export const ProfileRequests = () => {
    const user = useSelector(getUser);

    return (
        <div className="my-seminars-page__body">
            {user && user?.role === USER_ROLE.student
                ? <StudentProfileRequests />
                : <InstructorsAndAcademiesRequests userRole={user?.role} />}
        </div>
    );
};
