import {
    Country, State
} from "country-state-city";
import React, {
    useEffect, useState
} from "react";
import {toast} from "react-toastify";
import api from "../../../api";
import VideoPlayer from "../../../components/parts/cards/VideoPlayer";
import MapBox from "../../../components/parts/mapBox/MapBox";
import {getCoordinates} from "../../../components/parts/mapBox/mapBoxFunctions";
import Button from "../../../components/ui/buttons/Button";
import Input from "../../../components/ui/formElements/Input";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import {getImageSrc} from "../../../helpers/utils";
import {ReactComponent as TrashIcon} from "../../../assets/img/ico-trash.svg";
import {EditCooperateTeam} from "../../../components/modals/EditCooperateTeam";
import SelectMulty from "../../../components/ui/formElements/SelectMulty";

const GeneralInfoAcademy = () => {
    const [openAddTeamModal, setOpenAddTeamModal] = useState({
        open: false,
        team: null
    });
    const [baseData, setBaseData] = useState(null);
    const [gallery, setGallery] = useState(null);
    const [updateInfoValue, setUpdateInfoValue] = useState({});
    const [firstInit, setFirstInit] = useState(true);
    const allCountries = Country.getAllCountries();
    const allStates = State.getStatesOfCountry(baseData?.country || "US");
    const countries = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const states = allStates?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lon: null
    });

    const handleChange = async (file) => {
        const formData = new FormData();

        formData.append("file", file);
        const res = await api.academyGeneralInfo.uploadAcademyGalleryList(formData);

        if (res.success) {
            toast.success(res?.message || "Added");
            setGallery((prev) => [...prev, res.data]);
        } else {
            toast.success(res?.message || "Error");
        }
    };

    const updateInfoHandler = ({target}) => {
        setBaseData((prev) => ({
            ...prev,
            [target.name]: target.value
        }));
        setUpdateInfoValue((prev) => ({
            ...prev,
            [target.name]: target.value
        }));
    };


    const getAcademyGeneralInfo = async () => {
        const res = await api.academyGeneralInfo.getAcademyGeneralInfo();

        if (res?.data) {
            setCoordinates({
                lat: res.data.latitude,
                lon: res.data.longitude
            });
            setBaseData(res.data);
            setGallery(res.data.gallery);
            setTimeout(() => {
                setFirstInit(false);
            }, 100);
        }
    };

    const removeGaleryHandle = async (id) => {
        const res = await api.academyGeneralInfo.deleteAcademyGalleryItem(id);

        if (res.success) {
            setGallery((prev) => prev.filter((item) => item.id !== id));
            toast.success(res?.message || "Delete");
        } else {
            toast.error(res?.message || "Error");
        }
    };

    useEffect(() => {
        getAcademyGeneralInfo();
    }, []);

    const changeCoordinatesHandler = (obj) => {
        setCoordinates({
            lat: obj.lat,
            lon: obj.lon
        });
        updateInfoHandler({
            target: {
                name: "latitude",
                value: `${obj.lat}`
            }
        });
        updateInfoHandler({
            target: {
                name: "longitude",
                value: `${obj.lon}`
            }
        });
    };

    useEffect(() => {
        if (!baseData || firstInit) return;

        const req = async (address) => {
            const res = await getCoordinates(address);

            if (coordinates.lat !== res.coordinates.lat || coordinates.lon !== res.coordinates.lon) changeCoordinatesHandler({
                lat: res.coordinates.lat,
                lon: res.coordinates.lon
            });
        };

        if (baseData?.addressLine && baseData?.city && baseData?.zip) {
            const a = `${baseData?.addressLine} ${baseData?.city} ${updateInfoValue?.state} ${baseData?.zip}`;

            req(a);
        }
        // 6391 Elgin St. Celina, Delaware 10299
    }, [baseData?.addressLine, baseData?.city, updateInfoValue?.state, baseData?.zip]);


    // cooperate team

    const [allCooperateTeams, setCooperateTeams] = useState(false);
    const [cooperateTeamAddLoading, setCooperateTeamAddLoading] = useState(false);
    const [newTeamName, setNewTeamName] = useState("");
    const [newTeamImg, setNewTeamImg] = useState(null);
    const [imageTeam64, setImageTeam64] = useState(null);
    const [teamNameError, setTeamNameError] = useState(false);
    const [myOwnTeams, setMyOwnTeams] = useState(null);
    const [teamsOptions, setTeamsOptions] = useState(null);
    const [academyAffiliations, setAcademyAffiliations] = useState([]);

    const getAllCooperateTeam = async () => {
        const res = await api.cooperateTeam.getAllCooperateTeams();

        if (res.data) {
            setAcademyAffiliations(prev => {
                const unique = new Map();

                [...prev, ...res.data].forEach(item => {
                    unique.set(item.id, item);
                });

                return Array.from(unique.values());
            });

            setCooperateTeams(res.data);
        }
    };
    const getMyOwnTeams = async () => {
        const res = await api.cooperateTeam.getMyOwnTeams();

        if (res.data) {
            setMyOwnTeams(res.data);
        }
    };
    const getAllTeamsSystem = async () => {
        const res = await api.cooperateTeam.getAllCooperateTeamsSystem();

        if (res.data) {
            setTeamsOptions(res.data);
        }
    };
    const removeTeam = async (id) => {
        try {
            const res = await api.cooperateTeam.detachTeam(id);
            if (res.success) toast.success(res.message)
        } catch (e) {
            console.log(e)
        }
    };
    const addTeam = async (id) => {
        try {
            const res = await api.cooperateTeam.attachTeam({teamId: id});
            if (res.success) toast.success(res.message)
        } catch (e) {
            console.log(e)
        }
    };
    const changeUserTeams = async (teams = []) => {
        const teamsSelected = teamsOptions?.filter(one => teams?.map(team => team.value)?.includes(one.id));

        const teamToDelete = academyAffiliations?.filter(
            old => !teams.some(one => one.value === old.id)
        );

        const teamToAdd = teams.filter(
            one => !academyAffiliations?.some(old => old.id === one.value)
        );

        if (teamToDelete?.length) {
            removeTeam(teamToDelete[0].id)
            setAcademyAffiliations(teamsSelected);
        }

        if (teamToAdd?.length) {
            addTeam(teamToAdd[0].value)
            setAcademyAffiliations(teamsSelected);
        }

    };

    const toBase64 = async (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = async () => await resolve(reader.result);
        reader.onerror = reject;
    });

    const changePhoto = async (file) => {
        if (!file) return;

        const res = await toBase64(file);

        setNewTeamImg(file);
        setImageTeam64(res);
    };

    const onSuccessCooperateTeam = (data) => {
        if (data.action === "update") {
            setCooperateTeams((prev) => prev.map((el) => {
                if (el.id === data.id) {
                    return data.team;
                } else {
                    return el;
                }
            }));
        }

        if (data.action === "delete") {
            setCooperateTeams((prev) => prev.filter((el) => el.id !== data.id));
        }
    };

    const onSuccessAddModal = (data) => {
        if (data.action === "delete") {
            setAllInstructors((prevState) => prevState.filter((item) => item.id !== data.instructor.id));
        }

        if (data.action === "create") {
            setAllInstructors((prevState) => [...prevState, data.instructor]);
        }

        if (data.action === "edit") {
            setAllInstructors((prevState) => prevState.map((item) => (item.id === data.instructor.id
                ? data.instructor
                : item)));
        }
    };

    const saveTeam = async (e) => {
        e.preventDefault();

        if (!newTeamName) {
            setTeamNameError(true);

            return;
        }

        const data = new FormData();

        data.append("name", newTeamName);
        data.append("logo", newTeamImg);
        setCooperateTeamAddLoading(true);
        const res = await api.cooperateTeam.addCooperateTeam(data);

        if (res.data) {
            setCooperateTeams((prev) => [...prev || [], res.data]);
            setNewTeamImg(null);
            setImageTeam64(null);
            setNewTeamName("");
            addTeam(res.data.id)
        }
        getMyOwnTeams();
        getAllCooperateTeam();
        setCooperateTeamAddLoading(false);
    };

    useEffect(() => {
        getAllCooperateTeam();
        getAllTeamsSystem();
        getMyOwnTeams();
    }, []);


    const save = async () => {
        const resInfo = await api.academyGeneralInfo.updateAcademyGeneralInfo(updateInfoValue);

        if (resInfo.success) {
            toast.success(resInfo.message || "Updated");
        } else {
            toast.error(resInfo?.message || "Error");
        }
    };


    if (!baseData) return null;

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    General Information
                </h3>
            </div>

            <form
                className="form form-settings"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <SelectOne
                                        defaultValue={countries?.find((item) => item?.value === baseData?.country || null)}
                                        label="Country / Region"
                                        onChange={(option) => {
                                            setUpdateInfoValue((prev) => ({
                                                ...prev,
                                                country: option.value
                                            }));
                                        }}
                                        options={countries}
                                        placeholder="Country..."
                                        wrapperClasses="select--outline select--outline-bg input--lg"
                                    />
                                </div>

                                <div className="col-12">
                                    <Input
                                        defaultValue={baseData?.addressLine}
                                        inputClasses="input--solid"
                                        label="Address Line"
                                        name="addressLine"
                                        onChange={updateInfoHandler}
                                        placeholder="6391 Elgin St. Celina, Delaware 10299"
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-12">
                                    <div className="row gutters-form-2">
                                        <div className="col-md-4 col-sm-4 col-12">
                                            <Input
                                                defaultValue={baseData?.city}
                                                inputClasses="input--solid"
                                                label="Town / City"
                                                name="city"
                                                onChange={updateInfoHandler}
                                                placeholder="Milford"
                                                wrapperClasses="input--lg"
                                            />
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-12">
                                            <SelectOne
                                                defaultValue={states?.find((item) => item?.value === baseData?.state) || null}
                                                label="State"
                                                onChange={(option) => {
                                                    setUpdateInfoValue((prev) => ({
                                                        ...prev,
                                                        state: option.value
                                                    }));
                                                }}
                                                disabled={states.length === 0 || !states}
                                                options={states}
                                                placeholder="State..."
                                                wrapperClasses="select--outline  select--outline-bg input--lg"
                                            />
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-12">
                                            <Input
                                                defaultValue={baseData?.zip}
                                                inputClasses="input--solid"
                                                label="ZIP"
                                                name="zip"
                                                onChange={updateInfoHandler}
                                                placeholder="10299"
                                                wrapperClasses="input--lg"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div
                                        className="academy-profile-settings map"
                                        style={
                                            {height: "200px"}
                                        }
                                    >
                                        <MapBox
                                            getCoordinatesOnclick={changeCoordinatesHandler}
                                            mainPoints={coordinates.lat
                                                ? [{coordinates: [coordinates.lon, coordinates.lat]}]
                                                : null}
                                            newCenter={coordinates.lat
                                                ? [coordinates.lon, coordinates.lat]
                                                : null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.facebookURL}
                                        inputClasses="input--solid"
                                        label="Facebook"
                                        name="facebookURL"
                                        onChange={updateInfoHandler}
                                        placeholder="Add your link here..."
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.instagramURL}
                                        inputClasses="input--solid"
                                        label="Instagram"
                                        name="instagramURL"
                                        onChange={updateInfoHandler}
                                        placeholder="Add your link here..."
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.youtubeURL}
                                        inputClasses="input--solid"
                                        label="YouTube"
                                        name="youtubeURL"
                                        onChange={updateInfoHandler}
                                        placeholder="Add your link here..."
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.fanaticsURL}
                                        inputClasses="input--solid"
                                        label="BJJ fanatics"
                                        name="fanaticsURL"
                                        onChange={updateInfoHandler}
                                        placeholder="Add your link here..."
                                        wrapperClasses="input--lg"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.yearOfFoundation}
                                        inputClasses="input--solid"
                                        label="Year of Foundation"
                                        name="yearOfFoundation"
                                        onChange={updateInfoHandler}
                                        placeholder="2007"
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.numberOfStudents}
                                        inputClasses="input--solid"
                                        label="Number of Students"
                                        name="numberOfStudents"
                                        onChange={updateInfoHandler}
                                        placeholder="250"
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.personInCharge}
                                        inputClasses="input--solid"
                                        label="Person in Charge"
                                        name="personInCharge"
                                        onChange={updateInfoHandler}
                                        placeholder="Rubens Charles Maciel"
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.academyOwner}
                                        inputClasses="input--solid"
                                        label="Academy Owner"
                                        name="academyOwner"
                                        onChange={updateInfoHandler}
                                        placeholder="Rubens Charles Cobrinha Maciel"
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.maxSeminarCapacity}
                                        inputClasses="input--solid"
                                        label="Max. Seminar Capacity"
                                        name="maxSeminarCapacity"
                                        onChange={updateInfoHandler}
                                        placeholder="100"
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        defaultValue={baseData?.website}
                                        inputClasses="input--solid"
                                        label="Website"
                                        name="website"
                                        onChange={updateInfoHandler}
                                        placeholder="cobrinhabjj.com"
                                        wrapperClasses="input--lg"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form-3">
                                <div className="col-12">
                                    <div className="heading-row">
                                        <div className="flex-ico-ss-text">
                                            <h4 className="heading">
                                                Gallery
                                            </h4>
                                        </div>

                                        <div className="flex-group">
                                            <label className="ml-auto">
                                                <input
                                                    hidden={true}
                                                    onChange={(e) => handleChange(e.target.files[0])}
                                                    type="file"
                                                />

                                                <span className="btn btn--md">
                                                    <span>
                                                        Add Photo / Video
                                                    </span>

                                                    <span className="ico">
                                                        <svg
                                                            fill="none"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            width="20"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.125 10H16.875"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="1.2"
                                                            />

                                                            <path
                                                                d="M10 3.125V16.875"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="1.2"
                                                            />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {gallery.length && (
                                    <div className="col-12">
                                        <ul className="gallery-row">
                                            {gallery.map((galleryItem) => (
                                                <li
                                                    className={"gallery-grid"}
                                                    key={galleryItem.id}
                                                >
                                                    {galleryItem.mimetype?.includes("image") && (
                                                        <div className={"gallery-thumb"}>
                                                            <img
                                                                src={getImageSrc(galleryItem?.url)}
                                                                alt={galleryItem?.url}
                                                            />
                                                            <div className={"thumb-actions"}>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        removeGaleryHandle(galleryItem.id);
                                                                    }}
                                                                    className={"btn--square thumb-trash-btn"}
                                                                >
                                                                    <span className={"ico"}>
                                                                        <TrashIcon/>
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {galleryItem.mimetype?.includes("video") && (
                                                        <div className={"gallery-thumb"}>
                                                            <VideoPlayer url={galleryItem.url}/>
                                                            <div className={"thumb-actions"}>
                                                                <Button
                                                                    className={"btn--square thumb-trash-btn"}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        removeGaleryHandle(galleryItem.id);
                                                                    }}
                                                                >
                                                                    <span className={"ico"}>
                                                                        <TrashIcon/>
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form-4">
                                <div className="col-12">
                                    <div className="heading-row">
                                        <div className="flex-ico-ss-text">
                                            <h4 className="heading">
                                                affiliation/team
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <SelectMulty
                                        label="Add Affiliation/Team"
                                        onChange={(value) => {
                                            changeUserTeams(value);
                                        }}
                                        options={teamsOptions?.map(team => ({
                                            value: team.id,
                                            label: team.name
                                        }))}
                                        placeholder="Type language to add..."
                                        value={academyAffiliations?.map((item) => ({
                                            value: item.id,
                                            label: item.name
                                        })) || []}
                                        wrapperClasses="select--outline  select--outline-bg input--lg"
                                    />
                                    <ul className="team-list mt-4">
                                        {academyAffiliations && academyAffiliations.length > 0 && academyAffiliations.map((el, idx) => (
                                                <li className="team-item">
                                                    <div className="team-widget">
                                                        <div className="tw-item tw-logo">
                                                            <div
                                                                className="ico ico--lg rounded-full border-ico">
                                                                {el.logo && <img
                                                                    src={process.env.REACT_APP_API_URL + el.logo}/>}
                                                            </div>

                                                        </div>

                                                        <div className="tw-item tw-control">
                                                            <input
                                                                readOnly
                                                                className="input input--solid input--lg"
                                                                type="text"
                                                                value={el.name}
                                                            />
                                                        </div>

                                                        <div className="tw-item tw-actions">
                                                            <button
                                                                onClick={() => {
                                                                    removeTeam(el.id)
                                                                    setAcademyAffiliations(academyAffiliations.filter(item => item.id !== el.id))
                                                                }}
                                                                className="btn btn--square  btn--md rounded-full btn-border"
                                                            >
                                                                           <span className={"ico"}>
                                                                                <svg
                                                                                    width={"20"}
                                                                                    height={"20"}
                                                                                    viewBox={"0 0 20 20"}
                                                                                    fill={"none"}
                                                                                    xmlns={"http://www.w3.org/2000/svg"}
                                                                                >
                                                                                    <path
                                                                                        fillRule={"evenodd"}
                                                                                        clipRule={"evenodd"}
                                                                                        d={"M13.4482 17.4997H6.54818C5.67568 17.4997 4.95068 16.8263 4.88568 15.9555L4.13818 5.83301H15.8332L15.1107 15.9513C15.0482 16.8238 14.3224 17.4997 13.4482 17.4997V17.4997Z"}
                                                                                        stroke={"currentColor"}
                                                                                        strokeWidth={"1.2"}
                                                                                        strokeLinecap={"round"}
                                                                                        strokeLinejoin={"round"}
                                                                                    ></path>
                                                                                    <path
                                                                                        d={"M10.0002 9.16699V14.167"}
                                                                                        stroke={"currentColor"}
                                                                                        strokeWidth={"1.2"}
                                                                                        strokeLinecap={"round"}
                                                                                        strokeLinejoin={"round"}
                                                                                    ></path>
                                                                                    <path
                                                                                        d={"M3.3335 5.83366H16.6668"}
                                                                                        stroke={"currentColor"}
                                                                                        strokeWidth={"1.2"}
                                                                                        strokeLinecap={"round"}
                                                                                        strokeLinejoin={"round"}
                                                                                    ></path>
                                                                                    <path
                                                                                        d={"M14.1668 5.83333L13.3227 3.58167C13.0785 2.93083 12.4568 2.5 11.7618 2.5H8.2385C7.5435 2.5 6.92183 2.93083 6.67766 3.58167L5.8335 5.83333"}
                                                                                        stroke={"currentColor"}
                                                                                        strokeWidth={"1.2"}
                                                                                        strokeLinecap={"round"}
                                                                                        strokeLinejoin={"round"}
                                                                                    ></path>
                                                                                    <path
                                                                                        d={"M12.8585 9.16699L12.5002 14.167"}
                                                                                        stroke={"currentColor"}
                                                                                        strokeWidth={"1.2"}
                                                                                        strokeLinecap={"round"}
                                                                                        strokeLinejoin={"round"}
                                                                                    ></path>
                                                                                    <path
                                                                                        d={"M7.1418 9.16699L7.50013 14.167"}
                                                                                        stroke={"currentColor"}
                                                                                        strokeWidth={"1.2"}
                                                                                        strokeLinecap={"round"}
                                                                                        strokeLinejoin={"round"}
                                                                                    ></path>
                                                                                </svg>
                                                                           </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>

                                <div className="col-12">
                                    <ul className="team-list">
                                        {myOwnTeams && myOwnTeams.length > 0 && myOwnTeams.map((el, idx) => (
                                                <li className="team-item">
                                                    <div className="team-widget">
                                                        <div className="tw-item tw-logo">
                                                            <div className="ico ico--lg rounded-full border-ico">
                                                                {el.logo && <img src={getImageSrc(el.logo)}/>}
                                                            </div>

                                                        </div>

                                                        <div className="tw-item tw-control">
                                                            <input
                                                                readOnly
                                                                className="input input--solid input--lg"
                                                                type="text"
                                                                value={el.name}
                                                            />
                                                        </div>

                                                        <div className="tw-item tw-actions">
                                                            <button
                                                                onClick={() => setOpenAddTeamModal({
                                                                    open: true,
                                                                    team: el
                                                                })}
                                                                className="btn btn--square  btn--md rounded-full btn-border"
                                                            >
                                                            <span className="ico">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20px"
                                                                    height="20px"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <g id="Edit / Edit_Pencil_01">
                                                                        <path
                                                                            id="Vector"
                                                                            d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001"
                                                                            stroke="#000000"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        )}

                                        {myOwnTeams && myOwnTeams.length < 3 && <li className="team-item">
                                            <div className="team-widget">
                                                <label
                                                    className="tw-item tw-logo"
                                                    htmlFor="team-img"
                                                >
                                                    <div className="ico ico--lg rounded-full border-ico">
                                                        {
                                                            imageTeam64
                                                                ? (
                                                                    <img
                                                                        src={imageTeam64
                                                                            ? imageTeam64
                                                                            : process.env.REACT_APP_API_URL + imageTeam64}
                                                                        alt=""
                                                                    />
                                                                )


                                                                : null
                                                        }
                                                    </div>

                                                    <input
                                                        disabled={cooperateTeamAddLoading}
                                                        hidden
                                                        id="team-img"
                                                        name="team-img"
                                                        onChange={(e) => changePhoto(e.target.files[0])}
                                                        type="file"
                                                    />
                                                </label>

                                                <div className="tw-item tw-control">
                                                    <input
                                                        className="input input--solid input--lg"
                                                        disabled={cooperateTeamAddLoading}
                                                        onChange={(e) => {
                                                            setTeamNameError(false);
                                                            setNewTeamName(e.target.value);
                                                        }}
                                                        placeholder="Add new team..."
                                                        type="text"
                                                        value={newTeamName}
                                                    />
                                                </div>

                                                <div className="tw-item tw-actions">
                                                    <button
                                                        className={`btn btn--square  btn--md rounded-full btn-border ${cooperateTeamAddLoading
                                                            ? "disabled"
                                                            : ""}`}
                                                        href="#"
                                                        onClick={saveTeam}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>

                                            {teamNameError && (
                                                <p
                                                    className="error-input"
                                                    style={{padding: "5px 75px"}}
                                                >
                                                    Enter a team Name
                                                </p>
                                            )}
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="form-footer --bordered">
                    <div className="flex-group justify-end">
                        <button
                            className="btn btn--primary btn--sm"
                            onClick={(e) => {
                                e.preventDefault();
                                save();
                            }}
                            type="submit"
                        >
                            <span>
                                Save Changes
                            </span>
                        </button>
                    </div>
                </div>
            </form>
            {openAddTeamModal.open && (
                <EditCooperateTeam
                    data={openAddTeamModal.team}
                    handleClose={() => setOpenAddTeamModal({
                        open: false,
                        team: null
                    })}
                    onSuccess={(data) => onSuccessCooperateTeam(data)}
                    open={openAddTeamModal.open}
                />
            )}
        </div>
    );
};

export default GeneralInfoAcademy;
