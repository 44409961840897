import { useEffect, useState } from 'react';
import TooltipInfo from '../../../components/parts/TooltipInfo';
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch';
import DatePicker from '../../../components/ui/formElements/DatePicker';
import Input from '../../../components/ui/formElements/Input';
import SelectMulty from '../../../components/ui/formElements/SelectMulty';
import SelectOne from '../../../components/ui/formElements/SelectOne';
import { belts, languages, sharedProfits, teachingStyles, trainingStyle } from '../../../constants';
import { EVENT_TYPE, USER_ROLE } from '../../../constants/enums';
import { explanations } from '../../../constants/explanations';

const EventDetails = ({ chatId, eventId, isErrorsShown, errors, onChange, role, formData, eventType = EVENT_TYPE.seminar }) => {
    const [isSharedProfit, setIsSharedProfit] = useState(formData.sharedProfit || false);

    const teachingStyleOptions = teachingStyles.map(({ key, title }) => ({
        value: key,
        label: title
    }));
    const specificBeltOptions = belts.filter(({ key }) => !["blue_belt", "white_belt"].includes(key)).map(({ key, title }) => ({
        value: key,
        label: title
    }));

    const traningStyleOptions = trainingStyle.map(({ key, title }) => ({
        value: key,
        label: title
    }));
    const languageOptions = languages.map(({ title, key }) => ({
        value: key,
        label: title
    }));
    const sharedProfitOptions = sharedProfits.map(({ key, title }) => ({
        label: title,
        value: +key
    }))

    const labels = {
        [EVENT_TYPE.seminar]: 'Seminar',
        [EVENT_TYPE.camp]: 'Camp',
        [EVENT_TYPE.tour]: 'Tour',
    }

    const eventName = labels[eventType]
    return (
        <>
            {
                !(chatId && eventId) && (
                    <div className="col-12">
                        <div className="form-group input--lg mb-16">
                            <div className="row-group gap--sm">
                                <label className="label">
                                    Price range for {eventName}
                                </label>
                                <TooltipInfo
                                    content={role === USER_ROLE.academy
                                        ? explanations.priceRangeAcademy
                                        : explanations.priceRangeInstructor}
                                />
                            </div>
                            <div className="row-group w-full gap-10 mobile-range">
                                <div className="form-group flex-auto input--lg">
                                    <Input
                                        disabled={isSharedProfit}
                                        error={isErrorsShown && errors.seminarMinPrice}
                                        inputClasses="input--solid"
                                        onChange={(e) => onChange("seminarMinPrice", e.target.value)}
                                        placeholder="3000"
                                        type="number"
                                        min={0}
                                        max={99999900}
                                        value={formData.seminarMinPrice}
                                        wrapperClasses="input--currency"
                                    />
                                </div>

                                <span className="input-delimiter" />

                                <div className="form-group flex-auto input--lg">
                                    <Input
                                        disabled={isSharedProfit}
                                        error={isErrorsShown && errors.seminarMaxPrice}
                                        inputClasses="input--solid"
                                        onChange={(e) => onChange("seminarMaxPrice", e.target.value)}
                                        placeholder="4000"
                                        type="number"
                                        min={0}
                                        max={99999900}
                                        value={formData.seminarMaxPrice}
                                        wrapperClasses="input--currency"

                                    />
                                </div>
                            </div>
                        </div>

                        {!isSharedProfit && (
                            <p className="color-text text-14 mb-16">
                                {eventName} pricing varies based on duration. Please adjust parameters based on your {eventName.toLowerCase()} duration.
                            </p>
                        )}

                        <CheckboxSwitch
                            checked={isSharedProfit}
                            onChange={() => {
                                if (!isSharedProfit) {
                                    onChange("seminarMinPrice", "");
                                    onChange("seminarMaxPrice", "");
                                } else {
                                    onChange("sharedProfit", "");
                                }

                                setIsSharedProfit(!isSharedProfit);
                            }}
                            text={`Set “Shared Profit” for ${eventName}`}
                        />
                    </div>
                )
            }

            {chatId && eventId && (
                <div className="col-12">
                    <div className="row gx-16 mb-16">
                        <div className="col-md-6 col-sm-6 col-12">
                            <div className="form-group flex-auto input--lg">
                                <label className="label">
                                    Ticket Price
                                </label>

                                <Input
                                    error={isErrorsShown && errors?.minimalPriceTicket}
                                    inputClasses="input--solid"
                                    onChange={(e) => onChange("minimalPriceTicket", e.target.value)}
                                    placeholder="100.00"
                                    type="text"
                                    value={formData.minimalPriceTicket}
                                    wrapperClasses="form-group flex-auto input--lg input--currency"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12">
                            <Input
                                error={isErrorsShown && errors?.instructorPrice}
                                wrapperClasses="form-group flex-auto input--lg input--currency"
                                inputClasses="input--solid"
                                label="Instructor Price"
                                onChange={(e) => onChange("instructorPrice", e.target.value)}
                                placeholder="100"
                                value={formData.instructorPrice}
                                disabled={isSharedProfit}
                            />
                        </div>
                    </div>
                    <CheckboxSwitch
                        checked={isSharedProfit}
                        onChange={() => {
                            if (!isSharedProfit) {
                                onChange("seminarMinPrice", "");
                                onChange("seminarMaxPrice", "");
                                onChange("instructorPrice", "");
                            } else {
                                onChange("sharedProfit", "");
                            }

                            setIsSharedProfit(!isSharedProfit);
                        }}
                        text={`Set “Shared Profit” for ${eventName}`}
                    />

                </div>
            )}
            <div className="col-md-6 col-sm-6 col-12">
                <SelectOne
                    disabled={!isSharedProfit}
                    error={isErrorsShown && errors?.sharedProfit}
                    label="Shared Profit (Instructor / Academy)"
                    labelRowSecondItem={(<TooltipInfo content={explanations.sharedProfit} />)}
                    onChange={(option) => onChange("sharedProfit", option.value)}
                    options={sharedProfitOptions}
                    placeholder="Specific Shared Profit"
                    value={+formData.sharedProfit}
                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                />
            </div>

            <div className="col-12">
                <hr className="hr" />
            </div>

            <div className="col-md-6 col-sm-6 col-12">
                <SelectOne
                    error={isErrorsShown && errors?.traningStyle}
                    label="Gi or Nogi"
                    onChange={(option) => onChange("traningStyle", option.value)}
                    options={traningStyleOptions}
                    placeholder="Gi or Nogi"
                    value={formData.traningStyle}
                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                />
            </div>

            {
                role === USER_ROLE.academy && !eventId && (
                    <>
                        <div className="col-md-6 col-sm-6 col-12">
                            <SelectOne
                                error={isErrorsShown && errors?.specificBelt}
                                label="Specific Belt for instructor"
                                onChange={(option) => onChange("specificBelt", option.value)}
                                options={specificBeltOptions}
                                placeholder="Specific Belt for instructor"
                                value={formData.specificBelt}
                                wrapperClasses="select--outline select--outline-bg input--lg w-full"
                            />
                        </div>

                        <div className="col-12">
                            <SelectMulty
                                error={isErrorsShown && errors?.languages}
                                label="Preferred Language"
                                onChange={(options) => onChange("languages", options.map((option) => option.value))}
                                options={languageOptions}
                                placeholder="Select preferred languages"
                                value={formData.languages}
                                wrapperClasses="select--outline select--outline-bg input--lg w-full"
                            />
                        </div>
                    </>
                )}

            <div className="col-md-6 col-sm-6 col-12">
                <SelectOne
                    error={isErrorsShown && errors?.targetAudience}
                    label="Open to"
                    onChange={(option) => onChange("targetAudience", option.value)}
                    options={[
                        {
                            value: "any",
                            label: "Everyone"
                        }, {
                            value: "female",
                            label: "Female Only"
                        }, {
                            value: "male",
                            label: "Male Only"
                        }, {
                            value: "kids",
                            label: "Kids Only"
                        }
                    ]}
                    placeholder="Select target audience"
                    value={formData.targetAudience}
                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                />
            </div>

            {
                role === USER_ROLE.academy && (
                    <div className="col-md-6 col-sm-6 col-12">
                        <Input
                            error={isErrorsShown && errors?.maxGymCapacity}
                            inputClasses="input--solid"
                            afterLabel={(<TooltipInfo content={explanations.maxGymCapacity} />)}
                            label="Max Gym capacity"
                            onChange={(e) => onChange("maxGymCapacity", e.target.value)}
                            placeholder="0"
                            type="text"
                            value={formData.maxGymCapacity}
                            wrapperClasses="input--lg"
                        />
                    </div>
                )}

            {
                chatId && eventId && (
                    <>
                        <div className="col-md-6 col-sm-6 col-12">
                            <Input
                                inputClasses={"input--solid"}
                                label={"Minimum Attendance Requirement"}
                                afterLabel={(<TooltipInfo content={explanations.minimumAttendance} />)}
                                readOnly={!isSharedProfit}
                                type={"text"}
                                wrapperClasses={"input--lg"}
                                error={isErrorsShown && isSharedProfit && errors?.minAttendance}
                                onChange={(e) => !isSharedProfit ? () => { } : onChange("minAttendance", e.target.value)}
                                placeholder={"0"}
                                value={isSharedProfit ? formData.minAttendance : formData.minAttendanceRequirement}

                            />
                        </div>

                        <div className="col-md-6 col-sm-6 col-12">
                            <DatePicker
                                dateFormat={"dd.MM.yyyy"}
                                error={isErrorsShown && errors?.minAttendanceDeadline}
                                afterLabel={(<TooltipInfo content={explanations.minAttendanceDeadline} />)}
                                label={"Minimum Attendance Deadline"}
                                placeholderText={"Select Event Date"}
                                readOnly
                                selected={formData.minAttendanceDeadline}
                                onChange={() => { }}
                            />

                        </div>
                    </>
                )}

            <CheckboxSwitch
                checked={eventType === EVENT_TYPE.camp ? !!formData.allowUnder18 : formData.allowUnder18}
                onChange={() => {
                    if (eventType === EVENT_TYPE.camp) {
                        onChange("allowUnder18", formData.allowUnder18 ? 0 : 1)
                    } else {
                        onChange("allowUnder18", !formData.allowUnder18)
                    }
                }}
                text="Do you allow students under the age of 18 to attend your seminar/camp?"
            />

            <div className="col-12">
                <hr className="hr" />
            </div>

            <div className="col-12">
                <SelectMulty
                    error={isErrorsShown && errors?.teachingStyles}
                    label={role === USER_ROLE.instructor
                        ? "What do you want to teach?"
                        : "Teaching Style"}
                    onChange={(options) => onChange("teachingStyles", options.map((option) => option.value))}
                    options={teachingStyleOptions}
                    placeholder="Add teaching style..."
                    value={formData.teachingStyles}
                    wrapperClasses="select--outline select--outline-bg input--lg w-full my-18"
                />
            </div>

        </>
    )
}

export default EventDetails