import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api"; // Adjust the import path as necessary
import { toast } from "react-toastify";
export const getAllTracksFetch = createAsyncThunk("/getAllTracksFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tracking.getAllTracks(payload);

        if (res?.success) {
            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const findTrackFetch = createAsyncThunk("/findTrackFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tracking.findTrack(payload);

        if (res?.success) {
            return res;
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const createTrackFetch = createAsyncThunk("/createTrackFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tracking.createTrack(payload);

        if (res?.success) {
            toast.success(res?.message || "You will be notified when the instructor is free");

            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const deleteTrackFetch = createAsyncThunk("/deleteTrackFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tracking.deleteTrack(payload);

        if (res?.success) {
            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});

