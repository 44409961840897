import { createSlice } from "@reduxjs/toolkit";
import {
    deleteNotificationFetch, getAllNotificationsFetch, readNotificationsFetch
} from "../thunks/notificationsThunks";
import {
    createTrackFetch, deleteTrackFetch, findTrackFetch, getAllTracksFetch
} from "../thunks/trackingThunks";
const initialState = {
    tracks: [],
    currentTrack: null,
    isCurrentTrackLoading: false,
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const trackingSlice = createSlice({
    name: "tracking",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All
            .addCase(getAllTracksFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tracks = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllTracksFetch.pending, (state, action) => {
                state.isLoading = true;
                state.tracks = [];
                state.pagination = {};
            })
            .addCase(getAllTracksFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.tracks = [];
                state.pagination = {};
            })
            // find
            .addCase(findTrackFetch.fulfilled, (state, action) => {
                state.currentTrack = action.payload?.data;
                state.isCurrentTrackLoading = false;
            })
            .addCase(findTrackFetch.pending, (state, action) => {
                state.isCurrentTrackLoading = true;
            })
            .addCase(findTrackFetch.rejected, (state, action) => {
                state.isCurrentTrackLoading = false;
            })
            // create
            .addCase(createTrackFetch.fulfilled, (state, action) => {
                state.currentTrack = action.payload?.data;
            })
            // delete
            .addCase(deleteTrackFetch.fulfilled, (state, action) => {
                state.currentTrack = null;
            });
    }
});

export const {} = trackingSlice.actions;
export default trackingSlice.reducer;
