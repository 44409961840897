import classNames from "classnames";
import React, { forwardRef } from "react";
const Textarea = forwardRef(({
    wrapperClasses, textareaClasses, label, value, error, maxChars, ...props
}, ref) => {
    const charsCount = value?.toString().length || 0;

    return (
        <div className={`form-group ${wrapperClasses}`}>
            {label && (
                <label className="label">
                    {label}
                </label>
            )}

            <textarea
                className={`input ${error
                    ? "input--error"
                    : ""} ${textareaClasses}`}
                maxLength={maxChars}
                ref={ref}
                value={value}
                {...props}
            />

            {maxChars && (
                <p
                    className="color-text text--xs text-right"
                    style={{ textAlign: "right" }}
                >
                    <span className={classNames({ "color-danger": charsCount > maxChars })}>
                        {charsCount}
                    </span>

                    {" "}

                    /

                    {maxChars}
                </p>
            )}

            {error && (
                <p className="error-input">
                    {error}
                </p>
            )}
        </div>
    );
});

export default Textarea;
