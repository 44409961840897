import { useEffect, useState } from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams, useSearchParams
} from "react-router-dom";
import PageLoader from "../../components/ui/PageLoader";

import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronPrevIcon } from "../../assets/img/ico-chevron--prev.svg";
import { PaginationTemplate } from "../../components/parts/PaginationTemplate";
import Button from "../../components/ui/buttons/Button";
import { EventHelper } from "../../helpers/EventHelper";
import { getImageSrc } from "../../helpers/utils";
import { getUser } from "../../redux/slices/applicationSlice";
import { acceptInstructorRequestFetch, cancelSeminarRequestFetch } from "../../redux/thunks/seminarsThunks";
import { getTourByIdFetch } from "../../redux/thunks/toursThunks";
import { EVENT_STATUS } from "../../constants/enums";
import { useTabs } from "../../hooks/useTabs";
import classNames from "classnames";
import api from "../../api";
import { toast } from "react-toastify";

const TourOverview = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const { tourId } = useParams();
    const dispatch = useDispatch();
    const { tour, isLoading } = useSelector(state => state.tours);
    const pageData = tour;

    const seminars = tour?.seminars

    useEffect(() => {
        dispatch(getTourByIdFetch({ tourId }));

    }, [dispatch, tourId]);

    if (isLoading) return <PageLoader />;

    if (!pageData) {
        return null
    }

    return (
        <section className="section-settings profile-page">
            <div className="container container--full">
                <div className="section-heading-row">
                    <Link
                        className="btn btn-back"
                        to="/my-seminars"
                    >
                        <span className="ico">
                            <ChevronPrevIcon />
                        </span>

                        <p className="text-sm">
                            Back
                        </p>
                    </Link>
                </div>

                <div className="settings-row">
                    <div className="settings-col">
                        <div className="settings-body settings-gap">
                            <HeaderOverview pageData={pageData} tourId={tourId} />
                            <div className="form form-settings">

                                <div className="form-body">
                                    <SeminarsList seminars={seminars} />
                                    <div className="form-items-list">
                                        <hr className="hr-horisontal my-42" />
                                        <SeminarOverviewRequsts data={pageData?.requestSeminars || []} getRequests={() => dispatch(getTourByIdFetch({ tourId }))} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};



function EventTypeSwitcher({ activeTab, setActiveTab, tabs }) {
    return (
        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
            <div className="select-checkers">
                {tabs.map((item, index) => {
                    return (
                        <Button
                            onClick={() => setActiveTab(item.id)}
                            key={index}
                            className={
                                classNames('btn btn--md radius',
                                    { 'btn--default': item.id === activeTab })
                            }
                        >
                            <span>{item.label}</span>
                        </Button>
                    )
                })}

            </div>
        </div>
    )
}


function SemianarsItemsList({ data }) {
    if (!data?.length) {
        return <h4>There is no items</h4>
    }
    return (
        <>
            {
                data.map(item => (
                    <div key={item.id} className="request-item">
                        <div className="request-item__user">
                            <div className="ico ico--md rounded-full img-wrapper">
                                <img
                                    alt={item?.seminarName}
                                    src={getImageSrc(item?.cover)}
                                />
                            </div>

                            <div
                                className="col-group gap-4"
                                style={{ width: 300 }}
                            >
                                <h6 className="heading truncate flex-auto">
                                    {item?.seminarName}
                                </h6>

                                <p className="color-text truncate flex-auto">
                                    {item?.summary}
                                </p>
                            </div>
                        </div>

                        <div className="request-item__details">
                            <div className="col-group gap--xs">
                                <span className="label">
                                    Seminar Price
                                </span>

                                <p className="fw-500 text--sm">
                                    $

                                    {item?.seminarMinPrice}
                                </p>
                            </div>

                            <div className="col-group gap--xs">
                                <span className="label">
                                    Ticket Price
                                </span>

                                <p className="fw-500 text--sm">
                                    {item?.ticketPrice}
                                </p>
                            </div>

                            <div className="col-group gap--xs">
                                <span className="label">
                                    teaching
                                </span>

                                <p className="fw-500 text--sm">
                                    {EventHelper.style(item)?.title}
                                </p>
                            </div>

                            <div className="col-group gap--xs">
                                <span className="label">
                                    Date
                                </span>

                                <p className="fw-500 text--sm">
                                    {EventHelper.dateRange(item)}
                                </p>
                            </div>
                        </div>
                        {
                            item._chatId && (
                                <div className="request-item__action">
                                    <Link
                                        className="btn btn--sm"
                                        to={`/chat/${item._chatId}`}
                                    >
                                        Open discussion
                                    </Link>
                                </div>
                            )
                        }
                        <div className="request-item__action">
                            <Link
                                className="btn btn--sm"
                                to={`/my-seminars/${item.id}`}
                            >
                                Show Details
                            </Link>
                        </div>
                    </div>
                ))}
        </>
    )
}

function SeminarsList({ seminars }) {
    const tabs = [
        {
            label: "Active",
            id: "active",
        },
        {
            label: "Pending",
            id: "pending",
        },
    ]
    const { activeTab, setActiveTab } = useTabs(tabs, "event_status")

    const activeSeminars = seminars?.filter(item => item.status === EVENT_STATUS.active)
    const pendingSeminars = seminars?.filter(item => item.status !== EVENT_STATUS.active)

    const tabComponent = {
        active: <SemianarsItemsList data={activeSeminars} />,
        pending: <SemianarsItemsList data={pendingSeminars} />,
    }
    const renderTab = activeTab => {
        return tabComponent[activeTab]
    }
    return (
        <div className="wrapp-table">
            <div className="tabs__header flex items-center gap-24 pb-24">
                <h6 className="heading">
                    Seminars:
                </h6>
            </div>
            <EventTypeSwitcher activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
            <div className="request-items">
                {renderTab(activeTab)}
            </div>

        </div>
    )
}


function HeaderOverview({ pageData, tourId }) {
    const navigate = useNavigate()
    const handleDelete = async () => {
        const res = await api.tours.deleteTour({ tourId })
        if (res?.success) {
            toast.success("Deleted")
            navigate('/my-seminars')
        } else {
            toast.error(res?.message)
        }
    }

    return (
        <div className="sidebar-headers">
            <div className="sidebar-header heading-row">
                <div className="row-group gap--md">
                    <h3 className="heading">
                        {pageData?.name}
                    </h3>

                </div>

                <div className="row-group gap--md">
                    <Link
                        to={`/plan-tour/${tourId}`}
                        className="btn  btn--md radius"
                    >
                        <span>
                            Edit
                        </span>
                    </Link>
                    <Button
                        onClick={handleDelete}
                        className="btn btn--default  btn--md radius"
                    >
                        <span>
                            Delete
                        </span>
                    </Button>
                </div>
            </div>

            <div className="sidebar-header heading-row">
                <div className="options">
                    <>
                        {(pageData?.seminarMinPrice) && (
                            <div className="option">
                                <div className="price-group">
                                    <span className="color-grey mr-8 text-sm">
                                        from

                                        {" "}
                                    </span>

                                    <span className="price-group__price">
                                        ${pageData?.seminarMinPrice}
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="option">
                            <p className="text-sm font-500">
                                Planned Tour
                            </p>
                        </div>
                    </>

                    <div className="option">
                        <span className="card-seminar__gi">
                            <span className="ico">
                                {EventHelper.style(pageData)?.icon}
                            </span>

                            <span>
                                {EventHelper.style(pageData)?.title}
                            </span>
                        </span>

                        <ul className="list-point-row">
                            <li>
                                <p className="text-sm">
                                    {EventHelper.dateRange(pageData)}
                                </p>
                            </li>
                            <li>
                                <p className="text-sm">
                                    {EventHelper.teaching(pageData)}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

function RequestItem({ data, getRequests }) {
    const dispatch = useDispatch();
    const requestInstructorId = data?.instructor?.id
    const handleApprove = async () => {

        if (!requestInstructorId) return;

        await dispatch(acceptInstructorRequestFetch({ requestInstructorId })).unwrap();
        getRequests();

    };

    const handleCancelRequest = async () => {
        const requestId = data.id;

        if (!requestId) return;
        await dispatch(cancelSeminarRequestFetch({ requestId: requestInstructorId })).unwrap();
        getRequests();
    };

    const user = data?.owner;
    // const hrefPrefix = event?.userAcademy
    //     ? "planed-by-academy"
    //     : "planed-by-insctructor";
    const hrefPrefix = "planed-by-academy"

    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img
                        alt={user?.fullName || user?.name}
                        src={getImageSrc(user?.photo)}
                    />
                </div>

                <div
                    className="col-group gap-4"
                    style={{ width: 200 }}
                >
                    <h6 className="heading truncate flex-auto">
                        {user?.fullName || user?.name}
                    </h6>

                    <p className="color-text truncate flex-auto">
                        {data?.seminarName}
                    </p>
                </div>
            </div>

            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">
                        Price Range
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.priceRange(data)}
                    </p>
                </div>

                {/* <div className="col-group gap--xs">
                    <span className="label">
                        Ticket Price
                    </span>

                    <p className="fw-500 text--sm">
                        {event?.ticketPrice}
                    </p>
                </div> */}

                <div className="col-group gap--xs">
                    <span className="label">
                        teaching
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.style(data).title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Date
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.dateRange(data)}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                <Link
                    className="btn btn--sm"
                    to={`/${hrefPrefix}/${data?.id}`}
                >
                    Show Details
                </Link>

                <Button
                    className="btn btn--sm btn--primary"
                    onClick={handleApprove}
                >
                    Approve
                </Button>

                <Button
                    className="btn btn--sm btn--default"
                    onClick={handleCancelRequest}
                >
                    Decline
                </Button>
            </div>
        </div>
    );
};

function SeminarOverviewRequsts({ data, getRequests }) {
    return (
        <div className="form-item">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="tabs tab-seminars">
                        <div className="tabs__header flex items-center gap-24 pb-24">
                            <h6 className="heading">
                                Requests:
                            </h6>
                        </div>

                        <div className="tabs__body">

                            <div className="wrapp-table">
                                <div className="request-items">
                                    {data?.length ?
                                        data.map(item => (
                                            <RequestItem
                                                key={item.id} getRequests={getRequests}
                                                data={{ ...item }}
                                            />
                                        ))
                                        : <h4>There is no items</h4>
                                    }
                                </div>

                                <PaginationTemplate />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TourOverview;
