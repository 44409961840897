import React from "react";
import { Link } from "react-router-dom";
import { trainingStyle } from "../../../constants";
import {
    getBelt, getFullLocation, getImageSrc
} from "../../../helpers/utils";

const InstructorSeminarCard = ({ data }) => {
    const belt = getBelt(data?.user?.generalInstructorInfo?.belt);
    const giStyle = trainingStyle.find((style) => style.key === data?.user?.generalInstructorInfo?.traningStyle?.toLowerCase())?.title || data?.user?.generalInstructorInfo?.traningStyle;
    const user = data?.user;

    return (
        <div className="seminar-instructor items-center">
            <div className="seminar-instructor__img img-wrapper flex-shrink-0">
                <img
                    alt={data?.fullName}
                    src={getImageSrc(user?.instructorProfile?.photo)}
                />
            </div>

            <div className="seminar-instructor__body flex-auto">
                <div className="col-group gap-4 mb-10">
                    <p className="text-14 color-text">
                        {getFullLocation([user?.generalInstructorInfo?.country, user?.generalInstructorInfo?.city])}
                    </p>

                    <h5 className="heading">
                        {user?.instructorProfile?.fullName}
                    </h5>
                </div>

                <div className="flex items-center gap-8 mb-8">
                    <svg
                        fill="none"
                        height="16"
                        viewBox="0 0 16 16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.9136 4.45645L10.5882 2.31262C10.1416 2.93022 9.15305 3.36121 8.00005 3.36121C6.84704 3.36121 5.85844 2.93022 5.4119 2.31262L1.08649 4.45645C0.910981 4.54531 0.83989 4.75858 0.926532 4.93409L2.19728 7.4778C2.28614 7.6533 2.49941 7.72439 2.67492 7.63775L3.93233 7.02237C4.16782 6.90685 4.44329 7.07791 4.44329 7.34228V12.9762C4.44329 13.3694 4.76098 13.6871 5.1542 13.6871H10.8414C11.2347 13.6871 11.5524 13.3694 11.5524 12.9762V7.34006C11.5524 7.07791 11.8278 6.90463 12.0633 7.02015L13.3207 7.63553C13.4962 7.72439 13.7095 7.6533 13.7984 7.47558L15.0713 4.93409C15.1602 4.75858 15.0891 4.54309 14.9136 4.45645Z"
                            fill="#BDBDBD"
                        />
                    </svg>

                    <span>
                        {giStyle}
                    </span>
                </div>

                {
                    belt
                        ? (
                            <div className="flex items-center gap-8 mb-18">
                                {belt?.icon}

                                <span>
                                    {belt?.title}
                                </span>
                            </div>
                        )


                        : (
                            <div className="flex items-center gap-8 mb-18">
                                <span>
                                    No belt
                                </span>
                            </div>
                        )


                }

                <Link
                    className="btn color-secondary gap-8"
                    to={`/instructors/${user?.id}`}
                >
                    <span className="info">
                        See More
                    </span>

                    <span className="ico">
                        <svg
                            fill="none"
                            height="14"
                            viewBox="0 0 14 14"
                            width="14"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_910_48435)">
                                <path
                                    d="M5.25 2.625L9.625 7L5.25 11.375"
                                    stroke="#9747FF"
                                    strokeLinecap="square"
                                    strokeWidth="1.6"
                                />
                            </g>

                            <defs>
                                <clipPath id="clip0_910_48435">
                                    <rect
                                        fill="white"
                                        height="14"
                                        width="14"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </Link>
            </div>
        </div>
    );
};

export default InstructorSeminarCard;
