import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import api from "../../../api";
import { PaginationTemplate } from "../../../components/parts/PaginationTemplate";
import Button from "../../../components/ui/buttons/Button";
import PageLoader from "../../../components/ui/PageLoader";
import { getImageSrc, getTrainingStyle } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { acceptInstructorCampRequestFetch, rejectCampRequestFetch } from "../../../redux/thunks/planCampThuncks";
import { acceptInstructorRequestFetch, cancelSeminarRequestFetch } from "../../../redux/thunks/seminarsThunks";

const RequestItem = ({ data, owner, getRequests }) => {
    const dispatch = useDispatch();
    const me = useSelector(getUser);
    const isSeminar = data.object_type === "seminar";
    const isCamp = data.object_type === "plancamp";

    const handleApprove = async () => {
        const requestInstructorId = data.id;

        if (!requestInstructorId) return;

        if (isSeminar) {
            await dispatch(acceptInstructorRequestFetch({ requestInstructorId })).unwrap();
            getRequests();
        }

        if (isCamp) {
            await dispatch(acceptInstructorCampRequestFetch({ instructorId: requestInstructorId })).unwrap();
            getRequests();
        }
    };

    const handleCancelRequest = async () => {
        const requestId = data.id;

        if (!requestId) return;

        if (isSeminar) {
            await dispatch(cancelSeminarRequestFetch({ requestId })).unwrap();
            getRequests();
        }

        if (isCamp) {
            await dispatch(rejectCampRequestFetch({ requestId })).unwrap();
            getRequests();
        }
    };

    const user = data.owner;
    const isMy = data?.owner.id === me?.id;
    const event = data.seminar || data.plancamp;
    const hrefPrefix = event.userAcademy
        ? "planed-by-academy"
        : "planed-by-insctructor";

    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img
                        alt={user?.fullName || user?.name}
                        src={getImageSrc(user?.photo)}
                    />
                </div>

                <div
                    className="col-group gap-4"
                    style={{ width: 300 }}
                >
                    <h6 className="heading truncate flex-auto">
                        {event?.name || event?.seminarName}
                    </h6>

                    <p className="color-text truncate flex-auto">
                        {event?.summary || event?.summary}
                    </p>
                </div>
            </div>

            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">
                        Seminar Price
                    </span>

                    <p className="fw-500 text--sm">
                        $

                        {event?.seminarMinPrice}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Ticket Price
                    </span>

                    <p className="fw-500 text--sm">
                        {event?.ticketPrice}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        teaching
                    </span>

                    <p className="fw-500 text--sm">
                        {getTrainingStyle(event?.traningStyle)?.[0]?.title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Date
                    </span>

                    <p className="fw-500 text--sm">
                        {new Date(event.startAt || event.seminarDates?.[0]?.date).toLocaleDateString()}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                {/* <Button className="btn btn--sm btn--square notification" count={data.count}> */}
                {/* <Link to={`/chat/${data.chat?.id}`} className="btn btn--sm btn--square">
                    <span className="ico">
                        <ChatIcon />
                    </span>
                </Link> */}
                <Link
                    className="btn btn--sm"
                    to={`/${hrefPrefix}/${event?.id}`}
                >
                    Show Details
                </Link>

                {!isMy && (
                    <Button
                        className="btn btn--sm btn--primary"
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>
                )}

                <Button
                    className="btn btn--sm btn--default"
                    onClick={handleCancelRequest}
                >
                    Decline
                </Button>
            </div>
        </div>
    );
};

const SeminarOverviewRequsts = ({ eventType, event }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState([])

    const getRequests = async () => {
        try {
            setIsLoading(true)
            const res = await api.requests.getRequestsByEventIdAndEventType({
                eventId: id,
                eventType
            })

            if (res?.success) {
                setRequests(res.data)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRequests()
    }, [dispatch, id]);

    if (isLoading) {
        return <PageLoader />
    }

    if(!requests?.length) return; 

    return (
        <div className="form-item">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="tabs tab-seminars">
                        <div className="tabs__header flex items-center gap-24 pb-24">
                            <h6 className="heading">
                                Requests:
                            </h6>
                        </div>

                        <div className="tabs__body">

                            <div className="wrapp-table">
                                <div className="request-items">
                                    {
                                        requests.map(item => (
                                            <RequestItem key={item.id} getRequests={getRequests} data={{ ...item, [eventType]: event }} />
                                            // <div className="request-item">
                                            //     <div className="request-item__user">
                                            //         <div className="ico ico--md img-wrapper">
                                            //             <img
                                            //                 alt={item.fullName}
                                            //                 src={getImageSrc(item.photo)}
                                            //             />
                                            //         </div>

                                            //         <div className="col-group gap-4">
                                            //             <h6 className="heading">
                                            //                 {item.fullName}
                                            //             </h6>

                                            //             <p className="color-text">
                                            //                 {item.date}
                                            //             </p>
                                            //         </div>
                                            //     </div>

                                            //     <div className="request-item__details">
                                            //         <div className="col-group gap--xs">
                                            //             <span className="label">
                                            //                 Total Price
                                            //             </span>

                                            //             <p className="fw-500 text--sm">
                                            //                 ${item.price}
                                            //             </p>
                                            //         </div>

                                            //         <div className="col-group gap--xs">
                                            //             <span className="label">
                                            //                 People
                                            //             </span>

                                            //             <p className="fw-500 text--sm">
                                            //                 {item.people}
                                            //             </p>
                                            //         </div>

                                            //         <div className="col-group gap--xs">
                                            //             <span className="label">
                                            //                 teaching
                                            //             </span>

                                            //             <p className="fw-500 text--sm">
                                            //                 {getTrainingStyle(item.teachingStyle)?.[0]?.title}
                                            //             </p>
                                            //         </div>

                                            //         <div className="col-group gap--xs">
                                            //             <span className="label">
                                            //                 Location
                                            //             </span>

                                            //             <p className="fw-500 text--sm">
                                            //                 {item.location}
                                            //             </p>
                                            //         </div>
                                            //     </div>

                                            //     <div className="request-item__action">
                                            //         <Button
                                            //             className="btn btn--sm btn--square Linejoin=notification"
                                            //             count="1"
                                            //         >
                                            //             <span className="ico">
                                            //                 <svg
                                            //                     fill="none"
                                            //                     height="22"
                                            //                     viewBox="0 0 22 22"
                                            //                     width="22"
                                            //                     xmlns="http://www.w3.org/2000/svg"
                                            //                 >
                                            //                     <g clipPath="url(#clip0_728_13209)">
                                            //                         <path
                                            //                             d="M8.25 9.625H13.75"
                                            //                             stroke="#02020A"
                                            //                             strokeLinecap="round"
                                            //                             strokeLinejoin="round"
                                            //                             strokeWidth="1.2"
                                            //                         />

                                            //                         <path
                                            //                             d="M8.25 12.375H13.75"
                                            //                             stroke="#02020A"
                                            //                             strokeLinecap="round"
                                            //                             strokeLinejoin="round"
                                            //                             strokeWidth="1.2"
                                            //                         />

                                            //                         <path
                                            //                             d="M6.86918 18.1423C8.60214 19.1453 10.6407 19.4838 12.6048 19.0947C14.5689 18.7056 16.3245 17.6156 17.5442 16.0277C18.764 14.4398 19.3646 12.4626 19.2342 10.4646C19.1038 8.46654 18.2512 6.58415 16.8353 5.16833C15.4195 3.75252 13.5371 2.89991 11.5391 2.76949C9.54111 2.63906 7.56384 3.23971 5.97598 4.45945C4.38812 5.67919 3.29804 7.43477 2.90897 9.39887C2.5199 11.363 2.8584 13.4015 3.86137 15.1345L2.78629 18.3443C2.74589 18.4654 2.74003 18.5954 2.76936 18.7197C2.79869 18.844 2.86205 18.9576 2.95234 19.0479C3.04263 19.1382 3.15629 19.2016 3.28057 19.2309C3.40485 19.2602 3.53484 19.2544 3.65598 19.214L6.86918 18.1423Z"
                                            //                             stroke="#02020A"
                                            //                             strokeLinecap="round"
                                            //                             strokeLinejoin="round"
                                            //                             strokeWidth="1.2"
                                            //                         />
                                            //                     </g>

                                            //                     <defs>
                                            //                         <clipPath id="clip0_728_13209">
                                            //                             <rect
                                            //                                 fill="white"
                                            //                                 height="22"
                                            //                                 width="22"
                                            //                             />
                                            //                         </clipPath>
                                            //                     </defs>
                                            //                 </svg>
                                            //             </span>
                                            //         </Button>

                                            //         <Link
                                            //             to={`/${eventType === "seminar" ? "seminar" : "plancamp"}${id}`}
                                            //             className="btn btn--sm"
                                            //         >
                                            //             Show Details
                                            //         </Link>

                                            //         <Button
                                            //             className="btn btn--sm btn--primary"
                                            //         >
                                            //             Approve
                                            //         </Button>

                                            //         <Button
                                            //             className="btn btn--sm btn--default"
                                            //         >
                                            //             Decline
                                            //         </Button>
                                            //     </div>
                                            // </div>
                                        ))
                                    }
                                </div>

                                <PaginationTemplate />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeminarOverviewRequsts;
