import React, { forwardRef } from "react";
const Input = forwardRef(({
    label, placeholder, afterLabel, defaultValue, type = "text", inputClasses, wrapperClasses, value, error, ...props
}, ref) => {
    return (
        <div className={`form-group ${wrapperClasses}`}>
            {label && (
                <div className="row-group gap--sm">
                    <label className="label">
                        {label}
                    </label>
                    {afterLabel}
                </div>
            )}

            <div className="input-wrapper">
                <input
                    className={`input ${error
                        ? "input--error"
                        : ""} ${inputClasses}`}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={ref}
                    type={type}
                    value={value}
                    {...props}
                />
            </div>

            {error && (
                <p className="error-input">
                    {error}
                </p>
            )}
        </div>
    );
});

export default Input;
