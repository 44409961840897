const base = "api/invoices";

export const invoices = (instance) => ({
    async getMyInvoices(payload) {
        const { data } = await instance.get(`${base}/my?no_limit=true`);

        return data;
    },
    async getDownloadLink(id) {
        const { data } = await instance.get(`${base}/download-link/${id}`);

        return data;
    },
});
