import classNames from "classnames";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";

export const PaginationTemplate = ({
    limit = 1,
    maxPages = 1,
    offset = 0,
    page = 1,
    total = 1,
    className,
    onPageChange
}) => {
    const [searchParams, setSearchParams] = useSearchParams()

    if (maxPages <= 1) {
        return null
    }

    const handlePageChange = (page) => {
        if (onPageChange) {
            onPageChange(page)
        } else {
            setSearchParams(prev => {
                prev.set("page", page)
                return prev
            })
        }
    }

    return (

        <div className={classNames("paginations__wrap", className)}>
            <span className="page__counter">
                {`Page ${page} of ${maxPages}`}
            </span>

            <ReactPaginate
                activeLinkClassName="active"
                breakLabel="..."
                className="paginations"
                forcePage={page - 1}
                marginPagesDisplayed={2}
                nextClassName="pagination__next"
                nextLabel={null}
                onPageChange={(selectedItem) => handlePageChange(selectedItem.selected + 1)}
                pageClassName="pagination__item"
                pageCount={maxPages}
                pageLinkClassName="pagination__btn"
                pageRangeDisplayed={3}
                previousClassName="pagination__previous"
                previousLabel={null}
                total={total}
            />

            <div className="flex items-center gap-12">
                <button
                    className="btn btn--outline btn--square btn--sm rounded-full"
                    disabled={page === 1}
                    onClick={() => handlePageChange(Math.max(0, page - 1))}
                >
                    <span className="ico">
                        <svg
                            fill="none"
                            height="20"
                            viewBox="0 0 20 20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.16663 9.99967H15.8333"
                                stroke="#67676C"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.2"
                            />

                            <path
                                d="M8.33329 5.83301L4.16663 9.99967"
                                stroke="#67676C"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.2"
                            />

                            <path
                                d="M8.33329 14.1667L4.16663 10"
                                stroke="#67676C"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.2"
                            />
                        </svg>
                    </span>
                </button>

                <button
                    className="btn btn--outline btn--square btn--sm rounded-full"
                    disabled={page === maxPages}
                    onClick={() => handlePageChange(Math.min(maxPages, page + 1))}
                >
                    <span className="ico">
                        <svg
                            fill="none"
                            height="20"
                            viewBox="0 0 20 20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.8333 9.99967H4.16663"
                                stroke="#67676C"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.2"
                            />

                            <path
                                d="M11.6666 14.1667L15.8333 10"
                                stroke="#67676C"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.2"
                            />

                            <path
                                d="M11.6666 5.83301L15.8333 9.99967"
                                stroke="#67676C"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.2"
                            />
                        </svg>
                    </span>
                </button>
            </div>
        </div>
    );
};
