import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api"; // Adjust the import path as necessary
import { toast } from "react-toastify";
export const getSeminarsMy = createAsyncThunk("/seminars/my", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.getSeminarsMy(payload);

        if (res.success) {
            return res;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const getSeminarsMyAvailableToInstructorFetch = createAsyncThunk("/seminars/my/available-to-instructor", async (payload = null, { rejectWithValue }) => {
    try {
        const res = await api.seminars.getSeminarsMyAvailableToInstructor(payload);

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const getSeminarsById = createAsyncThunk("/seminars/byId", async (seminarId, { rejectWithValue }) => {
    try {
        const res = await api.seminars.getSeminarsById(seminarId);

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const createSeminar = createAsyncThunk("/seminars/create", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.createSeminar(payload);

        toast.success(res.message);

        if (res.success) {
            return res.data;
        } else {
            toast.error(res.message);
        }
    } catch (err) {
        console.log(err);
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const updateSeminarFetch = createAsyncThunk("/seminars/update", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.updateSeminar(payload);

        if (res.success) {
            toast.success(res.message);
            return res.data;
        } else {
            toast.error(res.message);
        }
    } catch (err) {
        console.log(err);
        toast.error(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const uploadSeminarPhotos = createAsyncThunk("/seminars/uploadPhotos", async ({ body, seminarId }, { rejectWithValue }) => {
    try {
        const res = await api.seminars.uploadSeminarPhotos({
            body,
            seminarId
        });

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const deleteSeminarPhoto = createAsyncThunk("/seminars/deletePhoto", async (photoId, { rejectWithValue }) => {
    try {
        const res = await api.seminars.deleteSeminarPhoto(photoId);

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const addDatesToSeminar = createAsyncThunk("/seminars/addDates", async ({ seminarId, body }, { rejectWithValue }) => {
    try {
        const res = await api.seminars.addDatesToSeminar({
            seminarId,
            body
        });

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const updateSeminarDate = createAsyncThunk("/seminars/updateDate", async ({ dateId, body }, { rejectWithValue }) => {
    try {
        const res = await api.seminars.updateSeminarDate({
            dateId,
            body
        });

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const deleteSeminarDate = createAsyncThunk("/seminars/deleteDate", async ({ dateId, body }, { rejectWithValue }) => {
    try {
        const res = await api.seminars.deleteSeminarDate({
            dateId,
            body
        });

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const getAllSeminarsWithFiltersFetch = createAsyncThunk("/seminars/send-request", async (qwery = "", { rejectWithValue }) => {
    try {
        const res = await api.seminars.getAllSeminarsWithFilters(qwery);

        if (res.success) {
            return res;
        }
    } catch (err) {
        return rejectWithValue(err?.response?.data);
    }
});
export const sendSeminarRequestFetch = createAsyncThunk("/seminars/send-request", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.sendSeminarRequest(payload);

        if (res?.success) {
            toast.success(res.message || "Request send successfully!");
        } else {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.success(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const sendSeminarRequestToInstructorFetch = createAsyncThunk("/sendSeminarRequestToInstructorFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.sendSeminarRequestToInstructor(payload);

        if (res?.success) {
            toast.success(res.message || "Request send successfully!");
        } else {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.success(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const sendSeminarRequestByInstructorFetch = createAsyncThunk("/sendSeminarRequestByInstructorFetch",
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.seminars.sendSeminarRequestByInstructor(payload);

            if (res?.success) {
                toast.success(res.message || "Request send successfully!");
            } else {
                toast.error(res?.message || "Something went wrong");
            }

            return res;
        } catch (err) {
            console.log(err);
            toast.error(err?.message || "Something went wrong");

            return rejectWithValue(err?.response?.data);
        }
    });
export const acceptSeminarRequestFetch = createAsyncThunk("/acceptSeminarRequestFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.acceptSeminarRequest(payload);

        if (res?.success) {
            toast.success(res.message || "Request send successfully!");

            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const acceptInstructorRequestFetch = createAsyncThunk("/acceptInstructorRequestFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.acceptInstructorRequest(payload);

        if (res?.success) {
            toast.success(res.message || "Request accepted successfully!");
        } else {
            toast.success(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.success(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const cancelSeminarRequestFetch = createAsyncThunk("/cancelSeminarRequestFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.cancelSeminarRequest(payload);

        if (res?.success) {
            toast.success(res.message || "Request cenceled successfully!");

            return res.data;
        } else {
            toast.success(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.success(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const getSeminarContractFetch = createAsyncThunk("/getSeminarContractFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.getSeminarContract(payload);

        if (res?.success) {
            return res.data;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});

export const getActiveSeminarsByInstructorIdFetch = createAsyncThunk("/getActiveSeminarsByInstructorIdFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.seminars.getActiveSeminarsByInstructorId(payload);

        if (res?.success) {
            return res.data;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});

