import { createSlice } from "@reduxjs/toolkit";
import {
    getInstructorInfoFetch, getInstructorsAllFetch
} from "../thunks/instructorsThunks";
const initialState = {
    instructors: [],
    instructorInfo: null,
    isLoading: false,
    error: null,
    pagination: null
};
const instructorsSlice = createSlice({
    name: "instructors",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInstructorsAllFetch.fulfilled, (state, { payload }) => {
            state.instructors = payload?.data;
            state.pagination = payload?.pagination;
            state.isLoading = false;
        });
        builder.addCase(getInstructorsAllFetch.pending, (state, { payload }) => {
            state.instructors = [];
            state.isLoading = true;
        });
        builder.addCase(getInstructorsAllFetch.rejected, (state, { payload }) => {
            state.instructors = [];
            state.isLoading = false;
        });
        builder.addCase(getInstructorInfoFetch.fulfilled, (state, { payload }) => {
            state.instructorInfo = payload;
            state.isLoading = false;
        });
        builder.addCase(getInstructorInfoFetch.pending, (state, { payload }) => {
            state.instructorInfo = null;
            state.isLoading = true;
        });
        builder.addCase(getInstructorInfoFetch.rejected, (state, { payload }) => {
            state.instructorInfo = null;
            state.isLoading = false;
        });
    }
});

export default instructorsSlice.reducer;
