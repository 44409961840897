import { Country } from "country-state-city";
import {
    useEffect, useMemo, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Outlet, useLocation, useNavigate, useParams, useSearchParams
} from "react-router-dom";
import api from "../../../api";
import PageLoader from "../../../components/ui/PageLoader";
import { EVENT_STATUS, USER_ROLE } from "../../../constants/enums";
import { EventHelper } from "../../../helpers/EventHelper";
import { checkErrors, getFullLocation } from "../../../helpers/utils";
import { getGeneralInfo, getUser } from "../../../redux/slices/applicationSlice";
import {
    getSeminarFormData, resetSeminarFormData, setSeminarFormData
} from "../../../redux/slices/planningSlice";
import { getAcademyGeneralInfoFetch } from "../../../redux/thunks/academyGeneralInfoThunks";
import { getInstructorGeneralInfoFetch } from "../../../redux/thunks/applicationThunks";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";
import { getSeminarsById } from "../../../redux/thunks/seminarsThunks";
import { covertDateFormat } from "../parts/EventDatesSelect";
import PlanningSteps from "../parts/PlanningSteps";
import SeminarCardPreview from "../parts/SeminarCardPreview";

const academyFields = {
    maxGymCapacity: "",
    address: '',
    specificBelt: "",


}
const instructorFields = {
    city: '',
    continent: "all",

}

const getInitialData = (role) => {
    const commonFields = {
        seminarMinPrice: null,
        seminarMaxPrice: null,
        sharedProfit: null,
        targetAudience: "any",
        languages: [],
        minAttendance: null,
        country: "",
        traningStyle: "",
        specificBelt: "",
        minimumAttendance: "",
        minAttendanceDeadline: "",
        allowUnder18: false,
        teachingStyles: [],
        photos: [],
        seminarName: "",
        summary: "",
        isPrivate: false,
        isAvailablePrivateLesson: false,
    };
    return {
        ...commonFields,
        ...(role === USER_ROLE.instructor ? instructorFields : {}),
        ...(role === USER_ROLE.academy ? academyFields : {}),
    };
};

const prepareInitialData = (seminarDetails) => {
    return {
        seminarMinPrice: seminarDetails?.seminarMinPrice ?? "",
        seminarMaxPrice: seminarDetails?.seminarMaxPrice ?? "",
        sharedProfit: seminarDetails?.sharedProfit,
        address: seminarDetails?.address,
        coutry: seminarDetails?.coutry,
        continent: seminarDetails?.continent,
        specificBelt: seminarDetails?.specificBelt,
        isAvailablePrivateLesson: seminarDetails?.isAvailablePrivateLesson,
        traningStyle: seminarDetails?.traningStyle,
        targetAudience: seminarDetails?.targetAudience,
        allowUnder18: seminarDetails?.allowUnder18,
        availableDates: seminarDetails.seminarDates,
        seminarName: seminarDetails?.seminarName,
        teachingStyles: seminarDetails?.teachingStyles,
        maxGymCapacity: seminarDetails?.maxGymCapacity,
        summary: seminarDetails?.summary,
        instructors: seminarDetails?.instructors,
        languages: seminarDetails?.languages,
        isPrivate: seminarDetails?.isPrivate,
        minAttendance: seminarDetails?.minAttendance,
        minAttendanceRequirement: seminarDetails?.minAttendanceRequirement,
        minAttendanceDeadline: seminarDetails?.minAttendanceDeadline,
        minimalPriceTicket: seminarDetails.minimalPriceTicket,
        instructorPrice: "",
        coverFilename: seminarDetails?.photos?.find((photo) => photo?.id === seminarDetails?.coverId)?.originalname,
        photos: seminarDetails?.photos,
    };
}


const PlanSeminarWrapper = ({ type }) => {
    const { pathname } = useLocation();
    const { seminarId, chatId: paramsChatId, tourId } = useParams();
    const chatId = paramsChatId === "null" ? null : paramsChatId

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorConfig, setErrorConfig] = useState({});
    const [isInCountry, setIsInCountry] = useState(false);
    const [isInCountryCalculating, setIsInCountryCalculating] = useState(false);
    const user = useSelector(getUser);
    const { seminarDetails, isLoading } = useSelector((state) => state.seminars);
    const formData = useSelector(getSeminarFormData);
    const generalInfo = useSelector(getGeneralInfo);
    const [errors, setErrors] = useState({});
    const [preview, setPreview] = useState({});

    useEffect(() => {
        if (!chatId) return;

        setIsInCountryCalculating(true);
        dispatch(getChatByIdFetch({ chatId })).unwrap()
            .then((res) => {
                const users = res?.data?.users;

                if (users?.length) {
                    const promises = users.map((user) => {
                        if (user?.role === USER_ROLE.instructor) {
                            return api.instructors.getInstructorInfo(user.id);
                        } else {
                            return api.academyGeneralInfo.getAcademyById(user.id);
                        }
                    });

                    Promise.all(promises).then((res) => {
                        const countries = res.map((res) => {
                            const user = res.data;

                            return user?.generalAcademyInfo?.country || user?.generalInstructorInfo?.country;
                        });

                        setIsInCountry(countries[0] === countries[1]);
                    });
                }
            })
            .finally(() => setIsInCountryCalculating(false));
    }, [chatId, navigate]);

    const handlePreviewChange = (name, value) => {
        setPreview((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({ name, value }));
        if (preview.hasOwnProperty(name)) {
            handlePreviewChange(name, value);
        }
    };

    function getStartDate(dates) {
        return dates?.length
            ? seminarDetails.seminarDates[0]?.date
            : null;
    }

    function calculateMinAttendanceDeadline(seminarDate, isInCountry) {
        const seminarDateObj = new Date(seminarDate);
        const deadlineOffset = isInCountry
            ? 7
            : 21; // 1 week for in-country, 3 weeks for out-of-country

        seminarDateObj.setDate(seminarDateObj.getDate() - deadlineOffset);
        const minAttendanceDeadline = seminarDateObj.toISOString();

        return minAttendanceDeadline;
    }

    useEffect(() => {
        if (user?.role === USER_ROLE.academy && generalInfo) {
            setPreview(prev => ({
                ...prev,
                fullName: user?.profile?.name || "",
                photo: user?.profile?.photo || "",
            }))
        }
        if (user?.role === USER_ROLE.instructor && generalInfo) {
            setPreview(prev => ({
                ...prev,
                fullName: user?.profile?.fullName || "",
                photo: user?.profile?.photo || "",
                location: getFullLocation([Country.getCountryByCode(generalInfo?.country)?.name, generalInfo?.country, generalInfo?.addressLine]),
            }))
        }
    }, [user, generalInfo, type]);

    useEffect(() => {
        if (user?.role === USER_ROLE.academy) {
            dispatch(getAcademyGeneralInfoFetch());
        }
        if (user?.role === USER_ROLE.instructor) {
            dispatch(getInstructorGeneralInfoFetch())
        }
    }, [user, type]);

    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig));
    }, [formData, errorConfig]);

    useEffect(() => {
        if (!seminarId) return;
        dispatch(getSeminarsById(seminarId));
    }, [seminarId]);

    useEffect(() => {
        if (seminarId && isInCountryCalculating) return;

        if (seminarId && seminarDetails && !isLoading) {
            if (location.state?.previousLocation !== "/template-editor") {
                const minAttendanceDeadline = calculateMinAttendanceDeadline(getStartDate(seminarDetails.seminarDates, isInCountry));
                setPreview(prev => ({
                    ...prev,
                    location: getFullLocation([Country.getCountryByCode(seminarDetails.country)?.name, seminarDetails.country, seminarDetails.city]),
                    availableDates: seminarDetails.seminarDates,
                    instructors: seminarDetails?.instructors.map(item => ({
                        id: item.user?.id,
                        fullName: item.user?.instructorProfile?.fullName
                    })),
                }))
                dispatch(resetSeminarFormData(prepareInitialData({ ...seminarDetails, minAttendanceDeadline })));
                handlePreviewChange("cover", seminarDetails.cover);
            }
        }

        if (!seminarId && user) {
            if (location.state?.previousLocation !== "/template-editor") {
                dispatch(resetSeminarFormData(getInitialData(user.role)));
                setPreview({})
                navigate(`/plan-seminar/${tourId ? tourId : ""}`);
            }
        }
    }, [seminarDetails, user, isInCountryCalculating, type, tourId]);


    const step = EventHelper.getEventRequest(seminarDetails, chatId)?.step

    return (
        <>
            <PlanningSteps
                step={step}
                isCreated={seminarId}
                isActive={seminarId && seminarDetails?.status === EVENT_STATUS.active}
                chatId={chatId}
            />

            <section className="section-42">
                <div className="container">
                    {
                        formData && !isLoading
                            ? (
                                <div className="section-row-block plan-block">
                                    <div className="section-row-block__body">
                                        <Outlet
                                            context={[
                                                formData,
                                                handleChange,
                                                errors,
                                                handlePreviewChange,
                                                preview,
                                                setErrorConfig
                                            ]}
                                        />
                                    </div>

                                    {
                                        !pathname.includes("success") && <SeminarCardPreview {...preview} />
                                    }
                                </div>
                            )


                            : <PageLoader />
                    }
                </div>
            </section>
        </>
    );
};

export default PlanSeminarWrapper;
