import {
    useEffect, useState
} from "react";
import CheckboxEl from "../../../components/ui/formElements/CheckboxEl";
import { what_includes } from "../../../constants";

const IncludedSection = ({ data, handleChange, error }) => {
    const [included, setIncluded] = useState(data || []);

    const handleIncludesChange = (id) => {
        const updatedItems = [...data];
        const index = updatedItems.findIndex((item) => item === id);

        if (index >= 0) {
            updatedItems.splice(index, 1);
        } else {
            updatedItems.push(id);
        }

        handleChange(updatedItems);
    };

    useEffect(() => {
        setIncluded(data);
    }, [data]);

    return (
        <div className="form-group input--lg pb-32 border-bottom">
            <label className="label mb-12">
                Whats Included
            </label>

            <div className="row gy-32">
                {
                    what_includes.map((item, index) => (
                        <div
                            className="col-md-4"
                            key={index}
                        >
                            <label className="check-btn check-group-btn justify-start">
                                <CheckboxEl
                                    checked={included?.includes(item.id)}
                                    onChange={() => handleIncludesChange(item.id)}
                                />

                                <div className="check-included">
                                    <div className="flex">
                                        <img src={item.imgSrc} />
                                    </div>

                                    {item.label}
                                </div>
                            </label>
                        </div>
                    )



                    )
                }

                {error && (
                    <div className="col-12">
                        <p className="error-input">
                            {typeof error === "string"
                                ? error
                                : "This field is required"}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default IncludedSection;
