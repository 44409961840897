import { createSlice } from "@reduxjs/toolkit";
import { getAllToursFetch, getTourByIdFetch } from "../thunks/toursThunks";
const initialState = {
    tours: [],
    tour: null,
    isLoading: false,
    errors: null,
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    }
};
const toursSlice = createSlice({
    name: "tours",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All
            .addCase(getAllToursFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tours = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllToursFetch.pending, (state, action) => {
                state.isLoading = true;
                state.tours = [];
                state.pagination = {};
            })
            .addCase(getAllToursFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.tours = [];
                state.pagination = {};
            })
            // Get by id
            .addCase(getTourByIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tour = action.payload?.data;
            })
            .addCase(getTourByIdFetch.pending, (state, action) => {
                state.isLoading = true;
                state.tour = [];
            })
            .addCase(getTourByIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.tour = [];
            })
    }
});

export const { } = toursSlice.actions;
export default toursSlice.reducer;
