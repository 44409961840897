import React from "react";
import MapBox from "../../../components/parts/mapBox/MapBox";

const SinglePageMapBlock = () => {
    return (
        <>
            <h4 className="heading mb-32">
                Location on Map
            </h4>

            <div className="map-section">
                <div
                    style={{
                        height: "250px",
                        width: "100%"
                    }}
                >
                    <MapBox />
                </div>
            </div>
        </>
    );
};

export default SinglePageMapBlock;
