import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { EVENT_TYPE, USER_ROLE } from "../../constants/enums";
import {
    sendCampRequestByInstructorFetch,
    sendRequestCampHostingFetch
} from "../../redux/thunks/planCampThuncks";
import {
    sendSeminarRequestByInstructorFetch,
    sendSeminarRequestFetch
} from "../../redux/thunks/seminarsThunks";
import Button from "../ui/buttons/Button";
import CheckboxEl from "../ui/formElements/CheckboxEl";
import Textarea from "../ui/formElements/Textarea";
import ModalTemplate from "./ModalTemplate";

const MIN_LENGTH = 6;

const RequestToApplyEventModal = ({ open, handleClose, eventId, eventType, role, title }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [formData, setFormData] = useState({ inviteMessage: "" });
    const { t } = useTranslation('translation');

    const handleChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleRequest = async (action, payload) => {
        try {
            setIsLoading(true);
            const response = await dispatch(action(payload)).unwrap();
            if (response?.success) {
                toast.success(response.message);
                handleClose();
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = () => {
        setIsErrorsShown(true);
        if (!agree) {
            toast.error("You should accept our Terms and Conditions of Service");
            return;
        }
        if (formData.inviteMessage.length < MIN_LENGTH) return;
        const payload = { body: formData };
        if (eventType === EVENT_TYPE.camp) {
            payload.plancampId = eventId;
        } else {
            payload.seminarId = eventId;
        }

        if (role === USER_ROLE.academy) {
            const action = eventType === EVENT_TYPE.camp
                ? sendRequestCampHostingFetch
                : sendSeminarRequestFetch;
            handleRequest(action, payload);
        }

        if (role === USER_ROLE.instructor) {
            const action = eventType === EVENT_TYPE.camp
                ? sendCampRequestByInstructorFetch
                : sendSeminarRequestByInstructorFetch;
            handleRequest(action, payload);
        }
    };

    return (
        <ModalTemplate
            modalSize="modal__dialog--xs"
            onClick={handleClose}
            open={open}
        >
            <div className="refund_confirm_body">
                <div className="heading-block">
                    <h2 className="refund_confirm_heading mb-24">{title}</h2>
                    <p className="text-14 color-text mb-24 text-center">Once you’ve sent a Seminar Request, the instructor or academy can accept it. After your request is accepted, you'll be able to discuss all seminar details through the chat.</p>
                </div>
                <Textarea
                    label="Message"
                    onChange={(e) => handleChange("inviteMessage", e.target.value)}
                    placeholder="Type the reason why you need the cancel event..."
                    style={{ minHeight: 140 }}
                    textareaClasses="input--solid"
                    value={formData.inviteMessage}
                    error={
                        isErrorsShown && formData.inviteMessage.length < MIN_LENGTH &&
                        `Message is required, minimum length is ${MIN_LENGTH} symbols`
                    }
                    wrapperClasses="input--lg"
                />
                <div className="flex items-center mb-20 mt-5">
                    <CheckboxEl
                        checked={agree}
                        onChange={(e) => setAgree(!agree)}
                        wrapperClasses="mr-20"
                    />

                    <Trans
                        i18nKey="agree_terms"
                        components={{
                            1: <a
                                href="/terms-of-service"
                                className="btn btn-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >Terms and Conditions of Service</a>,
                        }}
                    />
                </div>
                <div className="m-auto pt-24 w-full row-group justify-between">
                    <Button className="btn--md" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        className="btn--primary btn--md"
                        onClick={handleSubmit}
                        isLoading={isLoading}
                    >
                        Sumbit
                    </Button>
                </div>
            </div>
        </ModalTemplate>
    );
};

export default RequestToApplyEventModal;
