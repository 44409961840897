import {
    useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../assets/img/ico-circle-add.svg";
import CardSeminar from "../../components/parts/cards/CardSeminar";
import CardTour from "../../components/parts/cards/CardTour";
import Button from "../../components/ui/buttons/Button";
import PageLoader from "../../components/ui/PageLoader";
import { USER_ROLE } from "../../constants/enums";
import { resetSeminarFormData } from "../../redux/slices/planningSlice";
import { getTourByIdFetch } from "../../redux/thunks/toursThunks";

const PreviewTour = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tourId } = useParams();
    const { tour, isLoading } = useSelector(state => state.tours)
    const seminars = tour?.seminars || []

    async function handleDelete(id) {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["tours"], "readwrite");
            const store = transaction.objectStore("tours");

            return new Promise((resolve, reject) => {
                const request = store.delete(id);

                request.onsuccess = () => {
                    console.log(`Tour with ID ${id} deleted successfully`);
                    setSeminars((prev) => prev?.filter((item) => item.id !== id) || []);
                    resolve();
                };

                request.onerror = (event) => {
                    console.error("Failed to delete tour:", event.target.error);
                    reject(event.target.error);
                };
            });
        } catch (error) {
            console.error("Failed to delete tour:", error);
        }
    }

    const handleCreateNewTourSeminar = () => {
        if (!tourId) return
        navigate(`/plan-seminar/${tourId}`);
        dispatch(resetSeminarFormData());
    };

    const handleSubmit = async () => {
        navigate('/planed-by-insctructor/tours?sort_as=desc')
    };

    useEffect(() => {
        if (!tourId) return
        dispatch(getTourByIdFetch({ tourId }));
    }, [tourId]);


    return (
        <main className="content flex flex-col my-seminars-page">
            <section>
                <div className="container">
                    <div className="my-seminars-page__header mb-42">
                        <div className="my-seminars-page__header--row">
                            <div className="option">
                                {/* <div className="options options--px-0">
                                    <div className="option">
                                        <Link to=".." className="btn btn--md btn--default" >
                                            Cancel
                                        </Link>
                                    </div>
                                </div> */}

                                <h4 className="heading">
                                    Preview Tour
                                </h4>
                            </div>

                            <div className="option">
                                <div className="options options--px-0">
                                <div className="option">
                                        <Link
                                            to={`/plan-tour/${tourId}`}
                                            className="btn btn--md"
                                            disabled={!seminars?.length}
                                        >
                                            <span className="info">
                                                Edit
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="option">
                                        <Button
                                            className="btn btn--md btn--primary"
                                            isLoading={isLoading}
                                            onClick={handleSubmit}
                                        >
                                            <span className="info">
                                                Done
                                            </span>

                                            <span className="ico ico-18">
                                                <svg
                                                    fill="none"
                                                    height="18"
                                                    viewBox="0 0 19 18"
                                                    width="19"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g>
                                                        <path
                                                            d="M7.25 3.375L12.875 9L7.25 14.625"
                                                            stroke="currentColor"
                                                            strokeLinecap="square"
                                                            strokeWidth="2"
                                                        />
                                                    </g>

                                                    <defs>
                                                        <clipPath>
                                                            <rect
                                                                fill="white"
                                                                height="18"
                                                                transform="translate(0.5)"
                                                                width="18"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="my-seminars-page__body">
                        {
                            !isLoading
                                ? (
                                    <div className="row gx-24 gy-70">
                                        {seminars.map((seminar, index) => (
                                            <div
                                                key={index}
                                                className="col-xl-4 col-md-6"
                                            >
                                                <CardSeminar
                                                    data={seminar}
                                                />
                                            </div>
                                        ))}

                                        <div className="col-xl-4 col-md-6">
                                            <button
                                                className="btn add-photo-btn add-photo-btn--col"
                                                onClick={handleCreateNewTourSeminar}
                                                type="button"
                                                style={{ minHeight: 530 }}
                                            >
                                                <span className="ico">
                                                    <AddIcon />
                                                </span>

                                                <h4 className="heading">
                                                    Add New Seminar
                                                </h4>
                                            </button>
                                        </div>
                                    </div>
                                )
                                : <PageLoader />
                        }

                    </div>
                </div>
            </section>

        </main>
    );
};

export default PreviewTour;
