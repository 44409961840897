const usersBase = "api/profiles";

export const profiles = (instance) => ({
    async updateAcademy (payload) {
        const { data } = await instance.put(`${usersBase}/academy`, payload);

        return data;
    },

    async updateInstructor (payload) {
        const { data } = await instance.put(`${usersBase}/instructor`, payload);

        return data;
    },

    async getInstructorGeneralInfo (token = null) {
        const { data } = await instance.get("/api/instructor-general-info/my", token
            ? { headers: { Authorization: `Bearer ${token}` } }
            : {});

        return data;
    },

    async updateInstructorGeneralInfo (payload) {
        const { data } = await instance.put("api/instructor-general-info/my", payload);

        return data;
    },

    async updateStudent (payload) {
        const { data } = await instance.put(`${usersBase}/student`, payload);

        return data;
    }
});
