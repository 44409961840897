import React from 'react'
import { Tooltip } from 'react-tooltip';
import { ReactComponent as InfoIcon } from "../../assets/img/question-circle.svg";
import { uuidv4 } from '../../helpers/utils';

const TooltipInfo = ({ content, children }) => {
    const id = uuidv4()
    return (
        <>  {
            children ?
                <span data-tooltip-id={id}>{children}</span> :
                <button
                    data-tooltip-id={id}
                    className="btn color-secondary btn-link ms-2">
                    <InfoIcon />
                </button>
        }
            <Tooltip
                id={id}
                content={content}
                style={{ maxWidth: 400 }}
            />
        </>
    )
}

export default TooltipInfo