const base = "api/refund-complaints";

export const refund = (instance) => ({
    async sendRefundToInvoice (payload) {
        const { data } = await instance.post(`${base}`, payload);

        return data;
    },

});
