import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api"; // Adjust the import path as necessary
import { toast } from "react-toastify";


export const getReviewsFetch = createAsyncThunk("/getAllReviewsFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.reviews.getReviews(payload);

        if (res?.success) {
            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});

export const createReviewFetch = createAsyncThunk("/createReviewFetch", async (payload, { rejectWithValue, dispatch }) => {
    try {
        const res = await api.reviews.createReview(payload);

        if (res?.success) {
            dispatch(getReviewsFetch(res.data.userId))

            return res;
        } else {
            return rejectWithValue(res);
            // toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
