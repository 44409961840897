import { Country } from "country-state-city";
import moment from "moment";
import { useState } from "react";
import DropDown from "../../../../components/ui/DropDown";
import DatePicker from "../../../../components/ui/formElements/DatePicker";
import { updateSeminarFetch } from "../../../../redux/thunks/seminarsThunks";
import { ReactComponent as DoneIcon } from "../../../../assets/img/done.svg";
import { CardDetails } from "../CardDetails";
import { Step } from "../Step";
import { useDispatch } from "react-redux";
import { updateCampFetch } from "../../../../redux/thunks/planCampThuncks";

export const StepLocationDate = ({
    seminarDetails, currentStatus, status, link, canEdit = true, step, requestId
}) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [formData, setFormData] = useState({})

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleCheck = async () => {
        setIsOpen(false);
        setIsLoading(true)
        const res = await dispatch(updateCampFetch({
            campId: seminarDetails.id,
            body: formData
        })).unwrap();

        setIsLoading(false)
        setFormData({})
    };

    return (
        <Step
            currentStatus={currentStatus}
            label="Location & Date"
            status={status}
            step={step}
            requestId={requestId}
            eventType="plancamp"
            eventId={seminarDetails.id}

        >
            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("address") || seminarDetails?.lastEdited?.includes("location")}
                label="Location "
            >
                <p className="color-text">
                    {[
                        seminarDetails?.instructorLocation,
                        seminarDetails?.address,
                        seminarDetails?.continent,
                        Country.getCountryByCode(seminarDetails?.country)?.name || seminarDetails?.country,
                        seminarDetails?.city
                    ].filter(Boolean).join(", ") || "Empty"}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("startAt")}
                label="Start Date"
            >
                <div className="row-group justify-between">
                    <p className="fw-500">
                        {moment(seminarDetails.startAt).format("DD MMM, YYYY")}
                    </p>
                    {!isLoading && (
                        <DropDown
                            dropDownListClasses="time-input-menu"
                            labelButton={<button
                                className="fw-500 text-14 text-normal style-normal color-secondary"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Change Date
                            </button>}
                            position="left"
                            overflow="visible"
                        >
                            <div
                                className="schedule-group-item__time-group"
                            >
                                <div className="time-input-box-wrapper">
                                    <DatePicker
                                        onChange={(date) => handleChange("startAt", moment(date).format("YYYY-MM-DD"))}
                                        placeholderText="0:00pm"
                                        selected={formData.startAt ? new Date(formData.startAt) : null}

                                    />

                                    <button
                                        className="done-button"
                                        onClick={handleCheck}
                                    >
                                        <DoneIcon />
                                    </button>
                                </div>
                            </div>
                        </DropDown>
                    )}
                </div>
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("endAt")}
                label="End Date"
            >
                <div className="row-group justify-between">
                    <p className="fw-500">
                        {moment(seminarDetails.endAt).format("DD MMM, YYYY")}
                    </p>
                    {
                        !isLoading && (
                            <DropDown
                                dropDownListClasses="time-input-menu"
                                labelButton={<button
                                    className="fw-500 text-14 text-normal style-normal color-secondary"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    Change Date
                                </button>}
                                position="left"
                                overflow="visible"
                            >
                                <div
                                    className="schedule-group-item__time-group"
                                >
                                    <div className="time-input-box-wrapper">
                                        <DatePicker
                                            onChange={(date) => handleChange("endAt", moment(date).format("YYYY-MM-DD"))}
                                            placeholderText="0:00pm"
                                            selected={formData.endAt ? new Date(formData.endAt) : null}

                                        />

                                        <button
                                            className="done-button"
                                            onClick={handleCheck}
                                        >
                                            <DoneIcon />
                                        </button>
                                    </div>
                                </div>
                            </DropDown>
                        )
                    }
                </div>
            </CardDetails>
        </Step>
    );
};
