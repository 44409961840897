import axios from "axios";
import { apiConfig } from "./config";
import { academyGeneralInfo } from "./parts/academyGeneralInfo";
import { academySeminars } from "./parts/academySeminars";
import { auth } from "./parts/auth";
import { chats } from "./parts/chats";
import { contracts } from "./parts/contracts";
import { cooperateTeam } from "./parts/cooperateTeam";
import { faqQuestions } from "./parts/faqQuestions";
import { general } from "./parts/general";
import { guest } from "./parts/guest";
import { instructorPrivateSessionInfo } from "./parts/instructorPrivateSessionInfo";
import { instructors } from "./parts/instructors";
import { notifications } from "./parts/notifications";
import { pages } from "./parts/pages";
import { pastInstructors } from "./parts/pastInstructors";
import { payments } from "./parts/payments";
import { payoutMethods } from "./parts/payout-methods";
import { planCamp } from "./parts/planCamp";
import { privateLessons } from "./parts/privateLessons";
import { profiles } from "./parts/profiles";
import { history } from "./parts/recentlyViewed";
import { requests } from "./parts/requests";
import { reviews } from "./parts/reviews";
import { seminars } from "./parts/seminars";
import { tracking } from "./parts/tracking";
import { users } from "./parts/users";
import { invoices } from "./parts/invoices";
import { tickets } from "./parts/tickets";
import { refund } from "./parts/refund";
import { events } from "./parts/events";
import { tours } from "./parts/tours";


// const apiInstance = axios.create(apiConfig);

const apiInstance = axios.create(apiConfig);

apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

const api = {
    auth: auth(apiInstance),
    users: users(apiInstance),
    profiles: profiles(apiInstance),
    academyGeneralInfo: academyGeneralInfo(apiInstance),
    pages: pages(apiInstance),
    pastInstructors: pastInstructors(apiInstance),
    instructorPrivateSessionInfo: instructorPrivateSessionInfo(apiInstance),
    // instructorLocations: instructorLocations(apiInstance),
    academySeminars: academySeminars(apiInstance),
    cooperateTeam: cooperateTeam(apiInstance),
    seminars: seminars(apiInstance),
    instructors: instructors(apiInstance),
    general: general(apiInstance),
    requests: requests(apiInstance),
    chats: chats(apiInstance),
    planCamp: planCamp(apiInstance),
    contracts: contracts(apiInstance),
    faqQuestions: faqQuestions(apiInstance),
    history: history(apiInstance),
    notifications: notifications(apiInstance),
    tracking: tracking(apiInstance),
    privateLessons: privateLessons(apiInstance),
    guest: guest(apiInstance),
    reviews: reviews(apiInstance),
    payments: payments(apiInstance),
    payoutMethods: payoutMethods(apiInstance),
    invoices: invoices(apiInstance),
    tickets: tickets(apiInstance),
    refund: refund(apiInstance),
    events: events(apiInstance),
    tours: tours(apiInstance),
};

export default api;
