const base = "api/events";

export const events = (instance) => ({
    async cancelEvent({ eventId, eventType, body }) {
        const { data } = await instance.post(`${base}/cancel/${eventId}/${eventType}`, body);

        return data;
    },
    async cancelEventRequest({ eventId, eventType }) {
        const { data } = await instance.post(`api/academy-requests/cancel-active-event/${eventId}/${eventType}`);

        return data;
    },
    async cancelOrActivateEventRequest({ eventId, eventType, mode }) {
        // shared_profit_cancel or shared_profit_active
        const { data } = await instance.post(`api/academy-requests/shared-profit-accept/${eventId}/${eventType}?mode=${mode}`);

        return data;
    },
    async unacceptStep({ eventId, eventType, body, query = "" }) {
        const { data } = await instance.post(`${base}/undo-step/${eventId}/${eventType}?${query}`, body);

        return data;
    },

});
