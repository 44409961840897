import { Country } from "country-state-city";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Outlet, useLocation, useNavigate, useParams
} from "react-router-dom";
import PageLoader from "../../../components/ui/PageLoader";
import { EVENT_STATUS, EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { checkErrors, getFullLocation } from "../../../helpers/utils";
import { getGeneralInfo, getUser } from "../../../redux/slices/applicationSlice";
import {
    getSeminarFormData, resetSeminarFormData, setSeminarFormData
} from "../../../redux/slices/planningSlice";
import { getInstructorGeneralInfoFetch } from "../../../redux/thunks/applicationThunks";
import { getSeminarsById } from "../../../redux/thunks/seminarsThunks";
import { getTourByIdFetch } from "../../../redux/thunks/toursThunks";
import PlanningSteps from "../parts/PlanningSteps";
import SeminarCardPreview from "../parts/SeminarCardPreview";

const instructorFields = {

}

const getInitialData = () => {
    const commonFields = {
        continent: "all",
        countries: [],
        seminarMinPrice: null,
        seminarMaxPrice: null,
        sharedProfit: null,
        targetAudience: "any",
        languages: [],
        traningStyle: "",
        specificBelt: "",
        allowUnder18: false,
        teachingStyles: [],
        photos: [],
        name: "",
        summary: "",
        isPrivate: false,
        isAvailablePrivateLesson: false,
        startAt: null,
        endAt: null
    };
    return {
        ...commonFields,
    };
};
const prepareInitialData = (data) => {
    return {
        city: data.city,
        continent: data.continent,
        countries: data.countries || [],
        seminarMinPrice: data.seminarMinPrice,
        seminarMaxPrice: data.seminarMaxPrice,
        sharedProfit: data.sharedProfit,
        targetAudience: data.targetAudience,
        languages: data.languages,
        traningStyle: data.traningStyle,
        specificBelt: data.specificBelt,
        allowUnder18: data.allowUnder18,
        teachingStyles: data.teachingStyles,
        name: data.name,
        summary: data.summary,
        isPrivate: data.isPrivate,
        isAvailablePrivateLesson: data.isAvailablePrivateLesson,
        startAt: data.startAt,
        endAt: data.endAt,
        photos: data?.photos.map((photo) => ({
            id: photo?.id,
            url: photo?.file
        })),
    };
}


const initialPreviewFields = {
    availableDates: [],
    location: "",
    seminarName: "",
    instructors: [],
    photo: "",
    fullName: "",
    minimalPriceTicket: "",
    cover: null,
    traningStyle: "",
}
const PlanTourWrapper = ({ type }) => {
    const { pathname } = useLocation();
    const { tourId } = useParams();

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorConfig, setErrorConfig] = useState({});
    const user = useSelector(getUser);
    const { tour, isLoading } = useSelector((state) => state.tours);
    const formData = useSelector(getSeminarFormData);
    const generalInfo = useSelector(getGeneralInfo);
    const [errors, setErrors] = useState({});
    const [preview, setPreview] = useState(initialPreviewFields);

    const handlePreviewChange = (name, value) => {
        setPreview((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({ name, value }));
        if (preview.hasOwnProperty(name)) {
            handlePreviewChange(name, value);
        }
    };

    useEffect(() => {
        if (user?.role === USER_ROLE.instructor && generalInfo) {
            setPreview(prev => ({
                ...prev,
                fullName: user?.profile?.fullName || "",
                photo: user?.profile?.photo || "",
                location: getFullLocation([Country.getCountryByCode(generalInfo?.country)?.name, generalInfo?.country, generalInfo?.addressLine]),
            }))
        }
    }, [user, generalInfo, type]);

    useEffect(() => {
        if (user?.role === USER_ROLE.instructor) {
            dispatch(getInstructorGeneralInfoFetch())
        }
    }, [user, type]);

    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig));
    }, [formData, errorConfig]);

    useEffect(() => {
        if (!tourId) return;
        dispatch(getTourByIdFetch({ tourId }));
    }, [tourId]);

    useEffect(() => {
        if (user) {
            if (tourId && tour && !isLoading) {
                if (location.state?.previousLocation !== "/template-editor") {
                    if (tour?.user?.role === USER_ROLE.instructor) {
                        setPreview(prev => ({
                            ...prev,
                            location: getFullLocation([Country.getCountryByCode(tour.country)?.name, tour.country, tour.city]),
                            instructors: tour?.instructors?.map(item => ({
                                id: item.user?.id,
                                fullName: item.user?.instructorProfile?.fullName
                            })),
                        }))
                        dispatch(resetSeminarFormData(prepareInitialData(tour)));
                    }
                    handlePreviewChange("cover", tour.cover);

                }
            } else {
                if (location.state?.previousLocation !== "/template-editor") {
                    dispatch(resetSeminarFormData(getInitialData(user.role)));
                    setPreview({})
                    navigate(`/plan-tour/${tourId ? tourId : ""}`);
                }
            }
        }
    }, [user, type, tour]);
    return (
        <>
            <PlanningSteps
                isCreated={tourId}
                isActive={tourId && tour?.status === EVENT_STATUS.active}
                eventType={EVENT_TYPE.tour}
            />

            <section className="section-42">
                <div className="container">
                    {
                        formData && !isLoading
                            ? (
                                <div className="section-row-block plan-block">
                                    <div className="section-row-block__body">
                                        <Outlet
                                            context={[
                                                formData,
                                                handleChange,
                                                errors,
                                                handlePreviewChange,
                                                preview,
                                                setErrorConfig
                                            ]}
                                        />
                                    </div>

                                    {
                                        !pathname.includes("success") && <SeminarCardPreview {...preview} />
                                    }
                                </div>
                            )
                            : <PageLoader />
                    }
                </div>
            </section>
        </>
    );
};

export default PlanTourWrapper;
