import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api"; // Adjust the import path as necessary
import { toast } from "react-toastify";
export const getAllNotificationsFetch = createAsyncThunk("/getAllNotificationsFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.notifications.getAllNotifications(payload);

        if (res?.success) {
            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const readNotificationsFetch = createAsyncThunk("/readAllNotificationsFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.notifications.readNotifications(payload);

        if (res?.success) {
            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const reviewNotificationsFetch = createAsyncThunk("/reviewNotificationsFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.notifications.reviewNotifications(payload);

        if (res?.success) {
            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const deleteNotificationFetch = createAsyncThunk("/deleteNotificationFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.notifications.deleteNotification(payload);

        if (res?.success) {
            return res;
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});

