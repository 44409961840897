const Authorization = localStorage.getItem("authToken")
    ? `Bearer ${localStorage.getItem("authToken")}`
    : false;

export const apiConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "X-Requested-With": "XMLHttpRequest",
        Authorization
    }
};
