import React from "react";
import ReactPlayer from "react-player";
import { getImageSrc } from "../../../helpers/utils";
import Button from "../../ui/buttons/Button";

const VideoPlayer = ({ url, ...rest }) => {
    return (
        <div className="video-wrapper">
            <ReactPlayer
                className="react-player"
                controls={true}
                height="100%"
                light={
                    (<video
                        controls={false}
                        src={getImageSrc(url)}
                    />)
                }
                playIcon={
                    (<Button className="btn--md btn-play">
                        <img
                            alt="play"
                            src={require("../../../assets/img/video-play.svg").default}
                        />
                    </Button>)
                }
                playing={true}
                playsinline={true}
                url={getImageSrc(url)}
                width="100%"
                {...rest}
            />
        </div>
    );
};

export default VideoPlayer;
