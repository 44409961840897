import React from "react";

const CheckboxSwitch = ({ text, checked = true, textClassName = "", ...props }) => {
    return (
        <label className="switch-btn">
            <div className="switch-group">
                <input
                    checked={checked}
                    hidden={true}
                    type="checkbox"
                    {...props}
                />

                <div className="switcher">
                    <span className="switch-status">
                        <span className="switch-status-rounded" />
                    </span>
                </div>
            </div>

            {text && (
                <p className={`switch-text ${textClassName}`}>
                    {text}
                </p>
            )}
        </label>
    );
};

export default CheckboxSwitch;
