import { Country } from "country-state-city";
import { eachDayOfInterval, format } from "date-fns";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as DoneIcon } from "../../../assets/img/done.svg";
import { ReactComponent as PencilIcon } from "../../../assets/img/ico-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";

import DropDown from "../../../components/ui/DropDown";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import TimePicker from "../../../components/ui/formElements/TimePicker";
import { convertTimeToDate } from "../../../helpers/utils";
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks";
import { covertDateFormat } from "../../planning/parts/EventDatesSelect";
import { CardDetails } from "./CardDetails";
import { Step } from "./Step";

export const StepLocationDate = ({
    seminarDetails, currentStatus, status, link, canEdit = true, step, requestId, isReady
}) => {
    const dispatch = useDispatch()
    const dates = seminarDetails?.seminarDates;
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState(dates?.length ? dates[0] : null);
    const [endDate, setEndDate] = useState(dates?.length ? dates[dates.length - 1] : null);
    const [formData, setFormData] = useState([])
    const [newDate, setNewDate] = useState({
        date: "",
        startTime: null,
        endTime: null
    })
    const handleChange = (name, index, value) => {
        setFormData(prev =>
            prev.map((item, idx) =>
                idx === index
                    ? { ...item, [name]: value }
                    : item
            )
        );
    };

    const update = async (dates) => {
        setIsOpen(false);
        setIsLoading(true)
        const res = await dispatch(updateSeminarFetch({
            seminarId: seminarDetails.id,
            body: {
                "availableDates": dates
            }
        })).unwrap();

        setIsLoading(false)
        // setFormData([])
    }
    const handleCheck = async () => {
        update(formData.map(date => covertDateFormat(date)))
    };

    const handleAddDate = () => {
        update([...formData, newDate].filter(Boolean).map(date => covertDateFormat(date)))
    }
    const handleDeleteDate = (index) => {
        const updatedData = formData.filter((item, idx) => idx !== index)
        setFormData(updatedData);
        update(updatedData.map(date => covertDateFormat(date)))
    }
    useEffect(() => {
        if(formData.length > 0) {
            let errors = false
            formData.map(item => {
                Object.values(item).forEach(value => {
                    if(value === '' || value === null || value === undefined) {
                        errors = true
                    }
                })
            })
            isReady(!errors)
        }
    }, [formData])
    useEffect(() => {
        if (Array.isArray(dates)) {
            setFormData(dates.map(date => ({
                ...date,
                startTime: convertTimeToDate(date.date, date.startTime),
                endTime: convertTimeToDate(date.date, date.endTime),
            })))
        }
    }, [dates]);

    // useEffect(() => {
    //     if (!(startDate && endDate)) return;

    //     handleChange("availableDates", eachDayOfInterval({
    //         start: startDate,
    //         end: endDate
    //     }).map((date) => ({
    //         date: format(date, "yyyy-MM-dd"),
    //         startTime: null,
    //         endTime: null
    //     })));
    // }, [startDate, endDate]);



    return (
        <>
            <Step
                currentStatus={currentStatus}
                label="Location & Date"
                status={status}
                step={step}
                requestId={requestId}
                eventType="seminar"
                eventId={seminarDetails.id}

            >

                <CardDetails
                    isEdited={seminarDetails?.lastEdited?.includes("address") || seminarDetails?.lastEdited?.includes("location")}
                    label="Location "
                >
                    <p className="color-text capitalize">
                        {[
                            seminarDetails?.instructorLocation,
                            seminarDetails?.address,
                            seminarDetails?.continent,
                            Country.getCountryByCode(seminarDetails?.country)?.name || seminarDetails?.country,
                            seminarDetails?.city
                        ].filter(Boolean).join(", ") || "Empty"}
                    </p>
                </CardDetails>

                {/* <CardDetails
                    isEdited={seminarDetails?.lastEdited?.includes("availableDates")}
                    label="Start Date"
                >
                    <div className="row-group justify-between">
                        <p className="fw-500">
                            {moment(startDate).format("DD MMM, YYYY")}
                        </p>
                        {!isLoading && (
                            <DropDown
                                dropDownListClasses="time-input-menu"
                                labelButton={<button
                                    className="fw-500 text-14 text-normal style-normal color-secondary"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    Change Date
                                </button>}
                                position="left"
                                overflow="visible"
                            >
                                <div
                                    className="schedule-group-item__time-group"
                                >
                                    <div className="time-input-box-wrapper">
                                        <DatePicker
                                            placeholderText="0:00pm"
                                            selected={startDate ? new Date(startDate) : null}
                                            onChange={(date) => setStartDate(date)}

                                        />

                                        <button
                                            className="done-button"
                                            onClick={handleCheck}
                                        >
                                            <DoneIcon />
                                        </button>
                                    </div>
                                </div>
                            </DropDown>
                        )}
                    </div>
                </CardDetails>

                <CardDetails
                    isEdited={seminarDetails?.lastEdited?.includes("availableDates")}
                    label="End Date"
                >
                    <div className="row-group justify-between">
                        <p className="fw-500">
                            {moment(endDate).format("DD MMM, YYYY")}
                        </p>
                        {
                            !isLoading && (
                                <DropDown
                                    dropDownListClasses="time-input-menu"
                                    labelButton={<button
                                        className="fw-500 text-14 text-normal style-normal color-secondary"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        Change Date
                                    </button>}
                                    position="left"
                                    overflow="visible"
                                >
                                    <div
                                        className="schedule-group-item__time-group"
                                    >
                                        <div className="time-input-box-wrapper">
                                            <DatePicker
                                                placeholderText="0:00pm"
                                                selected={endDate ? new Date(endDate) : null}
                                                onChange={(date) => setEndDate(date)}
                                            />

                                            <button
                                                className="done-button"
                                                onClick={handleCheck}
                                            >
                                                <DoneIcon />
                                            </button>
                                        </div>
                                    </div>
                                </DropDown>
                            )
                        }
                    </div>
                </CardDetails>
                 */}
                {
                    seminarDetails.startAt && seminarDetails.endAt && (
                        <CardDetails
                            label="Date Range"
                        >
                            <div className="row-group justify-between">
                                <p className="fw-500">
                                    {moment(seminarDetails.startAt).format("DD MMM, YYYY")}
                                </p>
                                {
                                    " - "
                                }
                                <p className="fw-500">
                                    {moment(seminarDetails.startAt).format("DD MMM, YYYY")}
                                </p>
                            </div>
                        </CardDetails>
                    )
                }
                <CardDetails
                    isEdited={seminarDetails?.lastEdited?.includes("availableDates")}
                    label="Set Date and Time"
                >
                    {
                        !!formData.length && formData.map((date, index) => (
                            <Fragment key={index}>
                                <div className="row-group justify-between">
                                    <div className="row-group gap--xs">
                                        <button
                                            className="fw-500 text-14 text-normal style-normal color-secondary"
                                            onClick={() => handleDeleteDate(index)}
                                        >
                                            <DeleteIcon />
                                        </button>
                                        <p className="fw-500">Day {index + 1}</p>
                                    </div>
                                    <div className="row-group gap--xs">
                                        <p className="fw-500">
                                            {moment(date?.date).format("DD MMM, YYYY")}
                                        </p>
                                        {
                                            !isLoading && (
                                                <DropDown
                                                    dropDownListClasses="time-input-menu"
                                                    labelButton={<div
                                                        className="fw-500 text-14 text-normal style-normal color-secondary"
                                                        onClick={() => setIsOpen(!isOpen)}
                                                    >
                                                        <PencilIcon />
                                                    </div>}
                                                    position="left"
                                                    overflow="visible"
                                                >
                                                    <div
                                                        className="schedule-group-item__time-group"
                                                    >
                                                        <div className="time-input-box-wrapper">
                                                            <DatePicker
                                                                placeholderText="0:00pm"
                                                                selected={date.date ? new Date(date.date) : null}
                                                                onChange={(date) => handleChange("date", index, date)}
                                                            />

                                                            <button
                                                                className="done-button"
                                                                onClick={handleCheck}
                                                            >
                                                                <DoneIcon />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </DropDown>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="row-group justify-between">
                                    <p className="fw-500">Exact time</p>
                                    <div className="row-group gap--xs">
                                        <p className="fw-500">
                                            {date?.startTime ? moment(date?.startTime).format("hh:mm A") : ""}
                                            {" - "}
                                            {date?.endTime ? moment(date?.endTime).format("hh:mm A") : ""}
                                        </p>
                                        {
                                            !isLoading && (
                                                <DropDown
                                                    dropDownListClasses="time-input-menu"
                                                    labelButton={<div
                                                        className="fw-500 text-14 text-normal style-normal color-secondary"
                                                        onClick={() => setIsOpen(!isOpen)}
                                                    >
                                                        <PencilIcon />
                                                    </div>}
                                                    position="left"
                                                    overflow="visible"
                                                >
                                                    <div
                                                        className="schedule-group-item__time-group"
                                                    >
                                                        <div className="time-input-box-wrapper">
                                                            <TimePicker
                                                                onChange={(time) => handleChange("startTime", index, time)}
                                                                placeholderText="0:00pm"
                                                                selected={date?.startTime}
                                                                wrapperClassName="w-88"

                                                            />

                                                            <span className="input-delimiter" />

                                                            <TimePicker
                                                                onChange={(time) => handleChange("endTime", index, time)}
                                                                placeholderText="0:00pm"
                                                                selected={date?.endTime}
                                                                wrapperClassName="w-88"
                                                            />

                                                            <button
                                                                className="done-button"
                                                                onClick={handleCheck}
                                                            >
                                                                <DoneIcon />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </DropDown>
                                            )
                                        }
                                    </div>
                                </div>
                                <hr className="hr-horisontal" />
                            </Fragment>
                        ))
                    }
                    {
                        formData.length < 2 && (
                            !isLoading && (
                                <DropDown
                                    dropDownListClasses="time-input-menu"
                                    labelButton={<div
                                        className="fw-500 text-14 text-normal style-normal color-secondary"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        Add Date
                                    </div>}
                                    position="left"
                                    overflow="visible"
                                >
                                    <div
                                        className="schedule-group-item__time-group"
                                    >
                                        <div className="time-input-box-wrapper">
                                            <DatePicker
                                                placeholderText="0:00pm"
                                                selected={newDate.date}
                                                onChange={(date) => setNewDate(prev => ({ ...prev, date }))}

                                            />

                                            <button
                                                className="done-button"
                                                onClick={handleAddDate}
                                            >
                                                <DoneIcon />
                                            </button>
                                        </div>
                                    </div>
                                </DropDown>
                            )
                        )
                    }

                </CardDetails>
            </Step>
        </>
    );
};
