import { City, Country } from 'country-state-city';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TooltipInfo from '../../../components/parts/TooltipInfo';
import CheckboxSwitch from '../../../components/ui/formElements/CheckboxSwitch';
import Input from '../../../components/ui/formElements/Input';
import SelectOne from '../../../components/ui/formElements/SelectOne';
import { continents } from '../../../constants';
import { EVENT_TYPE, USER_ROLE } from '../../../constants/enums';
import { explanations } from '../../../constants/explanations';
import { getAcademyGeneralInfo } from '../../../redux/slices/academyGeneralInfoSlice';

const Location = ({ role, formData, onChange, isErrorsShown, errors, eventType = EVENT_TYPE.seminar, isAcademyDefault }) => {
    const academyInfo = useSelector(getAcademyGeneralInfo);

    const allCountries = Country.getAllCountries();

    const locationOptions = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];

    const optionAll = [
        {
            label: "All",
            value: "all"
        }
    ];

    const continentOptions = continents.map(item => ({
        label: item.continent,
        value: item.continent
    }))

    const countryOptions = formData.continent === "all"
        ? Country.getAllCountries().map(item => ({
            label: item.name,
            value: item.isoCode
        }))
        : continents
            ?.find(item => item.continent === formData.continent)?.countries
            ?.map(({ code, name }) => ({
                label: name,
                value: code
            })) || []


    const cityOptions = (formData?.country === "all"
        ? []
        : City.getCitiesOfCountry(formData?.country))
        ?.map((item) => ({
            value: item.name,
            label: item.name
        })) || [];

    const isAcademyLocation = useMemo(
        () => role === USER_ROLE.academy
            && formData?.address === academyInfo?.addressLine
            && formData?.country === academyInfo?.country,
        [role, formData, academyInfo]
    )


    const handleSetIsAcademyLocation = () => {
        if (isAcademyLocation) {
            onChange("address", "");
            onChange("country", null);
        } else {
            onChange("address", academyInfo?.addressLine);
            onChange("country", academyInfo?.country);
        }
    }

    useEffect(() => {
        if (isAcademyDefault && role === USER_ROLE.academy) {
            onChange("address", academyInfo?.addressLine);
            onChange("country", academyInfo?.country);
        }
    }, [academyInfo, isAcademyDefault, role])

    return (
        <div className="mb-42">
            <div className="row gx-16 py-24 mb-10 ">
                {
                    role === USER_ROLE.academy
                        ? (
                            <>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        disabled={isAcademyLocation}
                                        error={isErrorsShown && errors.country}
                                        label="Country"
                                        onChange={(option) => onChange("country", option.value)}
                                        options={locationOptions}
                                        placeholder="Select country"
                                        value={formData.country}
                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        disabled={isAcademyLocation}
                                        error={isErrorsShown && errors?.address}
                                        inputClasses="input--solid"
                                        label="Address (academy by deafult)"
                                        onChange={(e) => onChange("address", e.target.value)}
                                        placeholder="Enter location here..."
                                        value={formData.address}
                                        wrapperClasses="input--lg"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-12">
                                    <div className="row-group ">
                                        <h4 className="row-group">
                                            Set your location Availability
                                        </h4>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        error={isErrorsShown && errors?.continent}
                                        inputClasses="input--solid"
                                        label="Continent"
                                        onChange={(option) => onChange("continent", option?.value)}
                                        options={[...optionAll, ...continentOptions]}
                                        placeholder="Enter location here..."
                                        value={formData?.continent}
                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        disabled={!formData?.continent}
                                        error={isErrorsShown && errors?.country}
                                        inputClasses="input--solid"
                                        label="Country"
                                        onChange={(option) => onChange("country", option?.value)}
                                        options={[...optionAll, ...countryOptions]}
                                        placeholder="Enter location here..."
                                        value={formData?.country}
                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <SelectOne
                                        disabled={!formData?.country}
                                        error={isErrorsShown && errors?.city}
                                        inputClasses="input--solid"
                                        label="City / State"
                                        onChange={(option) => onChange("city", option?.value)}
                                        options={[...optionAll, ...cityOptions]}
                                        placeholder="Enter location here..."
                                        value={formData?.city}
                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                    />
                                </div>
                            </>
                        )
                }
            </div>
            {
                role === USER_ROLE.academy && (
                    <div className="option-group ">
                        <div className="row-group">
                            <CheckboxSwitch
                                checked={!isAcademyLocation}
                                onChange={handleSetIsAcademyLocation}
                                text="The seminar will take place at a third party location."
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Location