const base = "/api/past-instructors";

export const pastInstructors = (instance) => ({

    async getAllPastInstructors () {
        const { data } = await instance.get(`${base}/my`);

        return data;
    },

    async addPastInstructors (payload) {
        const { data } = await instance.post(`${base}`, payload);

        return data;
    },

    async updatePastInstructors (payload, id) {
        const { data } = await instance.put(`${base}/${id}`, payload);

        return data;
    },

    async deletePastInstructors (id) {
        const { data } = await instance.delete(`${base}/${id}`);

        return data;
    }

});
