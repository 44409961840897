import classNames from "classnames";
import { Country } from "country-state-city";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ReactComponent as ChevronRignt } from "../../../assets/img/ico-chevron--next.svg";
import { ReactComponent as ShareIcon } from "../../../assets/img/icon-share.svg";
import { getLanguagesByKeys } from "../../../constants";
import { getDateRange, getDateRangeBetween, getFullLocation } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";

const TourDefaultAside = ({ pageData }) => {
	const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
	const user = useSelector(getUser);
	if (!pageData) return;


	const dateArray = getDateRangeBetween(pageData?.startAt, pageData?.endAt)
	? getDateRangeBetween(pageData?.startAt, pageData?.endAt).split(",")
	: [];

	// const canBuyTicket = !user || user?.role === USER_ROLE.student || user.role === USER_ROLE.guest
	// const ticketsLeft = (pageData?.maxGymCapacity || 0) - (pageData?.ticketsPurchased || 0)
	// const bookTicketLink = `/book/${pageData?._type === EVENT_TYPE.camp ? "camp/" : ""}${pageData.id}`
	// const bookLessonLink = ""
	return (
		<div className="card-shadow px-36 py-32">
			{/* <div className="flex items-center justify-between gap-12 mb-24 pb-24 border-bottom">
				<div className="price-group gap-4">
					<span className="color-grey">
						{t('from')} /

						{" "}
					</span>

					<span className="price-group__price">
						{pageData?.minimalPriceTicket}

						$
					</span>
				</div>

				<div className="group-ticket-info">
					<div className="flex items-center gap-4 color-secondary">
						<div className="flex">
							<img src={require("../../../assets/img/icon-ticket.svg").default} alt="tickets" />
						</div>
						{pageData?.ticketsPurchased} &nbsp;
					</div>

					/ {pageData?.maxGymCapacity}
				</div>
			</div> */}

			<div
				className={classNames(`flex items-center justify-between gap-12 mb-24`, { "pb-24 border-bottom": false })}
			>
				<span className="card-seminar__info-date card-seminar__info-date--lg">
					{
						dateArray && dateArray.length > 0 && (
							<span className="card-seminar__info-date card-seminar__info-date--lg">
								{dateArray[0]}
								{", "}
								&nbsp;
								<span className="color-secondary">
									{dateArray[1]}
								</span>
							</span>
						)}
				</span>
			</div>
			{/* <div className="pb-24 mb-24 border-bottom">
				<div data-tooltip-id={"buy-ticket-link"}>
					<Link
						to={"#"}
						className={classNames("btn btn--primary btn--lg w-full gap-10 mb-8", {
							"disabled": !ticketsLeft || !canBuyTicket
						})}
						onClick={e => (!canBuyTicket || !ticketsLeft) && e.preventDefault()}
						disabled={!canBuyTicket}
					>
						<span className="info fs-20">
							Buy Tickets
						</span>
						<ChevronRignt />
					</Link>
				</div>
				{
					!canBuyTicket && (
						<Tooltip
							id={"buy-ticket-link"}
							content={"You can’t buy a ticket as an Instructor/Academy"}
							style={{ maxWidth: 400 }}
						/>
					)
				}
				{
					!canBuyTicket && (
						<Tooltip
							id={"book-lesson-link"}
							content={"You can’t book private lesson as an Instructor/Academy"}
							style={{ maxWidth: 400 }}
						/>
					)
				}
			</div> */}

			<button
				className="btn color-secondary gap-10 w-full mb-24 pb-24 border-bottom"
			>
				<ShareIcon />

				<span className="underline fw-500 not-italic tt-none">
					{t('share')}
				</span>
			</button>

			<div className="ticket-info-body">
				{/* <div className="ticket-info-body__item">
					<span className="color-text">
						{t('langs')}

						{" "}
					</span>

					<span>
						{getLanguagesByKeys(pageData?.languages)}
					</span>
				</div> */}

				{/* <div className="ticket-info-body__item">
					<span className="color-text">
						{t('host')}
						{" "}
					</span>

					<span>
						{pageData?.userAcademy?.academyProfile?.name}
					</span>
				</div> */}

				<div className="ticket-info-body__item">
					<span className="color-text">
						Location
					</span>

					<span>
						{" "}

						{
							getFullLocation([Country.getCountryByCode(pageData?.country)?.name])
						}
					</span>
				</div>

			</div>
		</div>
	);
};

export default TourDefaultAside;
