import React, { useEffect } from "react";
import MyAcademy from "./academyProfilePages/MyAcademy";
import StudentMyProfile from "./studentProfilePages/StudentMyProfile";
import MyProfileInstructor from "./instructorProfilePages/MyProfileInstructor";
import { useSelector } from "react-redux";
import {
    getToken, getUser
} from "../../redux/slices/applicationSlice.js";

const MyProfile = () => {
    const user = useSelector(getUser);

    return (
        <>

            {user && user.role === "academy" && <MyAcademy userData={user} />}

            {user && user.role === "student" && <StudentMyProfile userData={user} />}

            {user && user.role === "instructor" && <MyProfileInstructor userData={user} />}

        </>
    );
};

export default MyProfile;
