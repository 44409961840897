import React from "react";
import { NavLink } from "react-router-dom";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import SelectFilters from "../../../components/parts/SelectFilters";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { useTranslation } from "react-i18next";

const SeminarFilters = ({
    onChange, viewStatus = "defautl", links = [], filters, filtersData, totalCards, sortBy, name = "seminars"
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    return (
        <>
            <div className="flex items-center justify-between flex-wrap gap-24 seminar-filters">
                {
                    !!links.length && (
                        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
                            <div className="select-checkers">
                                {links.map((link, index) => (
                                    <NavLink
                                        to={link.link}
                                        key={index}
                                        end
                                        className={({ isActive }) => isActive ? 'btn btn--md radius btn--default' : 'btn btn--md'}
                                    >
                                        <span>{link.label}</span>
                                    </NavLink>
                                ))}

                            </div>
                        </div>
                    )}

                <div className="seminar-filters-grid">
                    <SelectFilters
                        filters={filters}
                        filtersData={filtersData}
                        handleChange={onChange}
                        showAll={true}
                    />

                    <SelectOne
                        onChange={(option) => onChange("sort_by", option.value)}
                        options={sortBy}
                        value={filters.sort_by}
                        wrapperClasses="lg-hidden form-group select--outline w-150 input--md select-border-option"
                    />
                </div>
            </div>

            <div className="flex items-center justify-between flex-wrap gap-24">
                <span className="instructors__counter mb-12 lg-flex hidden">
                    {totalCards}

                    {" "}

                    {name}

                    {" "}

                    {t('available')}
                </span>

                <div className="flex items-center flex-wrap mb-12 gap-24">
                    {true
                        ? (
                            <div className="hidden lg-flex items-center flex-wrap gap-24">
                                <CheckboxSwitch
                                    checked={filters.gender === "female"}
                                    onChange={() => onChange("gender", filters.gender === "female"
                                        ? ""
                                        : "female")}
                                    text={t('women')}
                                />

                                <CheckboxSwitch
                                    checked={filters.gender === "male"}
                                    onChange={() => onChange("gender", filters.gender === "male"
                                        ? ""
                                        : "male")}
                                    text={t('men')}
                                />
                            </div>
                        )


                        : (
                            <label className="switch">
                                <div className="switch-group">
                                    <input
                                        className="switch__field"
                                        type="checkbox"
                                    />

                                    <span className="switch__slider" />
                                </div>

                                <span className="switch__text">
                                    Min. Attendance
                                </span>
                            </label>
                        )}

                    <div className="items-center lg-flex hidden">
                        <span className="label-sort">
                            {t('sort_by')}
                        </span>

                        <div className="form-group select--sort input--md">
                            <SelectOne
                                isSearchable={false}
                                onChange={(option) => onChange("sort_by", option.value)}
                                options={sortBy}
                                value={filters.sort_by}
                                wrapperClasses="w-120"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <span className="instructors__counter mb-12 flex lg-hidden">
                {totalCards}

                {" "}

                {name}

                {" "}

                {t('available')}
            </span>
        </>
    );
};

export default SeminarFilters;
