import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { toast } from "react-toastify";

export const createTourFetch = createAsyncThunk("/createTourFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tours.createTour(payload);
        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const updateTourFetch = createAsyncThunk("/updateTourFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tours.updateTour(payload);
        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
export const getAllToursFetch = createAsyncThunk("/getAllToursFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tours.getAllTours(payload);
        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});

export const getTourByIdFetch = createAsyncThunk("/getTourByIdFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.tours.getTourById(payload);
        if (!res?.success) {
            toast.error(res?.message || "Something went wrong");
        }

        return res;
    } catch (err) {
        console.log(err);
        toast.error(err?.message || "Something went wrong");

        return rejectWithValue(err?.response?.data);
    }
});
