import React, { useState } from 'react'
import Button from '../../../components/ui/buttons/Button'
import DropDown from '../../../components/ui/DropDown'
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots-horisontal.svg";
import ConfirmModal from '../../../components/modals/ConfirmModal';
import ConfirmComplateModal from '../../../components/modals/ConfirmComplateModal';
import CancelEventModal from '../../../components/modals/CancelEventModal';
import { getUser } from '../../../redux/slices/applicationSlice';
import { useSelector } from 'react-redux';
import api from '../../../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const SemianrActionsDropdown = ({ event, eventType }) => {
    const [cancelEventModalOpen, setCancelEventModalOpen] = useState(false)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(null)
    const [confirmComplateModal, setConfirmComplateModal] = useState(false)
    const navigate = useNavigate()
    const user = useSelector(getUser)
    const isActive = event?.status === "active"
    const handleBuyRemainingTickets = () => {
        const onSubmit = async () => {
            try {
                const res = await api.tickets.buyRemainingTickets({
                    eventId: event.id,
                    eventType: event._type
                });
                if (res.data?.session?.url) {
                    // toast.success("Remaining tickets have been purchased successfully!");
                    window.open(res.data?.session?.url)
                    setModalConfirmOpen(null)
                } else {
                    toast.error(res?.message || "Failed to purchase remaining tickets.");
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while purchasing tickets.");
            }
        };
        const count = event?.minimumAttendance - event?.ticketsPurchased;
        const price = event?.minimalPriceTicket;

        const totalPrice = (price * count) + (price * count * event._ticketFee);

        setModalConfirmOpen({
            title: "Purchase Remaining Tickets",
            description: `Are you sure you want to purchase the remaining <b>${count}</b> unsold tickets for <b>$${totalPrice}</b>  to meet the minimum attendance?`,
            onSubmit
        });
    };

    function calculateInstructorPrice(minimumAttendance, minimalPriceTicket) {
        const instructorPrice = minimumAttendance * minimalPriceTicket / 1.05;
        if (isNaN(instructorPrice) || instructorPrice < 0) return 0;
        return Math.floor(instructorPrice);
    }
    const handleReduceInstructorFee = () => {
        const val = calculateInstructorPrice(event.ticketsPurchased, event.minimalPriceTicket)
        const onSubmit = async () => {
            try {
                const res = await api.payoutMethods.reduceInstructorPrice({
                    eventId: event.id,
                    eventType: event._type
                });
                if (res?.success) {
                    toast.success("Reduced fee has been agreed upon successfully!");
                    setModalConfirmOpen(null)
                } else {
                    toast.error(res?.message || "Failed to confirm reduced fee.");
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while confirming reduced fee.");
            }
        };

        setModalConfirmOpen({
            title: "Confirm Reduced Fee",
            description: `Are you agree that the instructor's fee will be reduced to <b>$${val}</b>?`,
            onSubmit
        });
    };

    const handleProceedWithSeminar = () => {
        const onSubmit = async () => {
            try {
                const res = await api.events.cancelOrActivateEventRequest({
                    eventId: event.id,
                    eventType: event._type,
                    mode: "shared_profit_active"
                })
                if (res?.success) {
                    toast.success("Seminar will proceed as scheduled, despite minimum attendance not being met.");
                    setModalConfirmOpen(null)
                } else {
                    toast.error(res?.message || "Failed to confirm proceeding with the seminar.");
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while confirming seminar continuation.");
            }
        };

        setModalConfirmOpen({
            title: "Confirm Proceeding with Seminar",
            description: "Do you want to proceed with the seminar, even though the minimum attendance has not been met?",
            onSubmit
        });
    };

    const hendelDeleteSeminar = async () => {
        const onSubmit = async () => {
            try {
                const res = await api.seminars.deleteSeminar(event.id)
                if (res?.success) {
                    navigate('/my-seminars')
                } else {
                    toast.error(res?.message || "Failed to delete the seminar.");
                }
            } catch {
                console.error(error);
                toast.error("An error occurred while deleting the seminar.");
            }
        }
        setModalConfirmOpen({
            title: `Delete ${eventType === "plancamp" ? "camp" : "seminar"}`,
            description: "Are you sure you want to delete this Pending Seminar?",
            onSubmit
        });

    }
    const releases = event?.releases || []
    const isReleased = event?.status === "finished" && releases.find(item => item?.userId === user?.id);
    const isDeadline = event.eventStartStatus === "minimum_attendance_not_reached";
    const isWaitProfit = event.eventStartStatus === "waiting_confirm_by_profit"

    if (!(event.status === "active" || isDeadline || isWaitProfit || !isReleased)) {
        return;
    }


    return (
        <div>
            <DropDown
                dropDownClasses="ml-auto card-actions"
                dropDownListClasses="dropdown-menu"
                labelButton={(
                    <DotsIcon />
                )}
                buttonClasses={"btn btn--square btn--md rounded-full btn-border"}
            >
                <div className="dropdown-menu__body--item">
                    {
                        event.status === "active" && (
                            <>
                                {(isDeadline || isWaitProfit) && !!event.ticketsPurchased && (
                                    <>
                                        {
                                            !isWaitProfit ? (
                                                <>
                                                    {
                                                        user?.role === "academy" && (
                                                            <Button
                                                                className="btn btn--sm btn--thin active"
                                                                onClick={handleBuyRemainingTickets}
                                                            >
                                                                Buys Remaining Tickets
                                                            </Button>
                                                        )
                                                    }
                                                    {
                                                        user?.role === "instructor" && (
                                                            <Button
                                                                className="btn btn--sm btn--thin active"
                                                                onClick={handleReduceInstructorFee}
                                                            >
                                                                Reduce instructor fee
                                                            </Button>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <Button
                                                    className="btn btn--sm btn--thin active"
                                                    onClick={handleProceedWithSeminar}
                                                >
                                                    Proceed with a seminar
                                                </Button>
                                            )}
                                    </>
                                )}
                                <Button
                                    className="btn btn--sm btn--thin active"
                                    onClick={() => setCancelEventModalOpen(true)}
                                >
                                    Cancel Event
                                </Button>
                            </>
                        )
                    }
                    {
                        event.status === "pending" && (
                            <Button
                                className="btn btn--sm btn--thin active"
                                onClick={hendelDeleteSeminar}
                            >
                                Delete
                            </Button>
                        )
                    }
                    {
                        event.status === "finished" && !isReleased && (
                            <Button
                                className="btn btn--sm btn--thin active"
                                onClick={() => setConfirmComplateModal(event)}
                            >
                                Completion Confirmation
                            </Button>
                        )
                    }
                </div>
            </DropDown>
            {
                modalConfirmOpen && (
                    <ConfirmModal
                        open={modalConfirmOpen}
                        onClose={() => setModalConfirmOpen(null)}
                        {...modalConfirmOpen}
                    />
                )
            }
            {
                confirmComplateModal && (
                    <ConfirmComplateModal
                        open={confirmComplateModal}
                        data={confirmComplateModal}
                        handleClose={() => setConfirmComplateModal(null)}
                    />
                )
            }
            {cancelEventModalOpen && (
                <CancelEventModal
                    open={cancelEventModalOpen}
                    handleClose={() => setCancelEventModalOpen(false)}
                    data={event}
                />
            )}
        </div>
    )
}

export default SemianrActionsDropdown