import Color from "color";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { toggle } from "../../../helpers/utils";
import RangeSlider from "./RangeSlider";

const getBoxShadowValue = (boxShadow, type) => {
    if (!boxShadow) return null;

    const regexp = /[^\s\(]+(\(.+\))?/g;
    const shadowMatch = boxShadow.match(regexp);

    switch (type) {

    case "offsetX":
        return shadowMatch[0];
    case "offsetY":
        return shadowMatch[1];
    case "blur":
        return shadowMatch[2];
    case "spread":
        return shadowMatch[3];
    case "color":
        return shadowMatch[4];
    default:
        return null;

    }
};

const getBackgroundOpacity = (color) => {
    return Color(color).alpha();
};

const adjustOpacity = (color, opacity) => {
    return Color(color).alpha(opacity)
        .hsl()
        .string();
};

const rgbaToHex = (rgba) => {
    return Color(rgba).hex();
};

const BoxShadowEditor = ({ currentStyles, handleChange }) => {
    const handleBoxShadowChange = (boxShadowString, property, newValue) => {
        if (!boxShadowString) return null;

        const regexp = /[^\s\(]+(\(.+\))?/g;
        const boxShadowMatch = currentStyles?.boxShadow.match(regexp);
        const boxShadowParts = [
            boxShadowMatch[0], // offset-x
            boxShadowMatch[1], // offset-y
            boxShadowMatch[2], // blur-radius
            boxShadowMatch[3], // spread-radius
            boxShadowMatch[4] // color
        ];

        switch (property) {

        case "offsetX":
            boxShadowParts[0] = newValue;
            break;
        case "offsetY":
            boxShadowParts[1] = newValue;
            break;
        case "blur":
            boxShadowParts[2] = newValue;
            break;
        case "spread":
            boxShadowParts[3] = newValue;
            break;
        case "color":
            boxShadowParts[4] = newValue;
            break;
        default:
            return;

        }

        const updatedBoxShadow = `${boxShadowParts[0]} ${boxShadowParts[1]} ${boxShadowParts[2]} ${boxShadowParts[3]} ${boxShadowParts[4]}`;

        handleChange("boxShadow", updatedBoxShadow);
    };

    return (
        <div>
            <label className="label-row">
                <label className="label">
                    Shadow
                </label>

                <CheckboxSwitch
                    checked={!!currentStyles?.boxShadow}
                    onChange={({ target }) => handleChange("boxShadow", toggle(currentStyles?.boxShadow, target.value))}
                    value="0px 0px 0px 0px #ffffff"
                />
            </label>

            {currentStyles?.boxShadow && (
                <div className="py-4 border-bottom">
                    <div className="row gutters-form-7">
                        <div className="col-12">
                            <div className="flex gap-12">
                                <label className="picker-custom btn btn--square btn--sm">
                                    <input
                                        onChange={({ target }) => handleBoxShadowChange(currentStyles?.boxShadow, "color", target.value)}
                                        type="color"
                                        value={rgbaToHex(getBoxShadowValue(currentStyles.boxShadow, "color")) || "#000000"}
                                    />
                                </label>

                                <RangeSlider
                                    label="Opacity"
                                    max={1}
                                    min={0}
                                    onChange={(value) => handleBoxShadowChange(currentStyles?.boxShadow, "color", adjustOpacity(getBoxShadowValue(currentStyles.boxShadow, "color"), value))}
                                    step={0.01}
                                    value={getBackgroundOpacity(getBoxShadowValue(currentStyles.boxShadow, "color"))}
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <RangeSlider
                                label="Offset X"
                                max={50}
                                min={-50}
                                onChange={(value) => handleBoxShadowChange(currentStyles?.boxShadow, "offsetX", value)}
                                postfix="px"
                                step={1}
                                value={getBoxShadowValue(currentStyles.boxShadow, "offsetX")}
                            />
                        </div>

                        <div className="col-12">
                            <RangeSlider
                                label="Offset Y"
                                max={50}
                                min={-50}
                                onChange={(value) => handleBoxShadowChange(currentStyles?.boxShadow, "offsetY", value)}
                                postfix="px"
                                step={1}
                                value={getBoxShadowValue(currentStyles.boxShadow, "offsetY")}

                            />
                        </div>

                        <div className="col-12">
                            <RangeSlider
                                label="Blur"
                                max={100}
                                min={0}
                                onChange={(value) => handleBoxShadowChange(currentStyles?.boxShadow, "blur", value)}
                                postfix="px"
                                step={1}
                                value={getBoxShadowValue(currentStyles.boxShadow, "blur")}
                            />
                        </div>

                        <div className="col-12">
                            <RangeSlider
                                label="Spread"
                                max={100}
                                min={0}
                                onChange={(value) => handleBoxShadowChange(currentStyles?.boxShadow, "spread", value)}
                                postfix="px"
                                step={1}
                                value={getBoxShadowValue(currentStyles.boxShadow, "spread")}

                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BoxShadowEditor;
