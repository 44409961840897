import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api";
export const createRequesToPrivateLessonFetch = createAsyncThunk("/createRequesToPrivateLessonFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.privateLessons.createRequesToPrivateLesson(payload);

        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }

        return res;
    } catch (err) {
        toast.error(err?.response?.data);
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const acceptRequestsPrivateLessonsStepFetch = createAsyncThunk("/acceptRequestsPrivateLessonsStepFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.privateLessons.acceptRequestsPrivateLessonsStep(payload);

        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }

        return res;
    } catch (err) {
        toast.error(err?.response?.data);
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const acceptRequestsPrivateLessonsFetch = createAsyncThunk("/acceptRequestsPrivateLessonsFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.privateLessons.acceptRequestsPrivateLessons(payload);

        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }

        return res;
    } catch (err) {
        toast.error(err?.response?.data);
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const rejectRequestsPrivateLessonFetch = createAsyncThunk("/rejectRequestsPrivateLessonFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.privateLessons.rejectRequestsPrivateLesson(payload);

        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }

        return res;
    } catch (err) {
        toast.error(err?.response?.data);
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
export const updateRequesToPrivateLessonFetch = createAsyncThunk("/updateRequesToPrivateLessonFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.privateLessons.updateRequesToPrivateLesson(payload);

        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }

        return res;
    } catch (err) {
        toast.error(err?.response?.data);
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});

export const getPrivateLessonsByEventIdAndEventTypeFetch = createAsyncThunk("/getPrivateLessonsByEventIdAndEventTypeFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.privateLessons.getPrivateLessonsByEventIdAndEventType(payload);

        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }

        return res;
    } catch (err) {
        toast.error(err?.response?.data);
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});
