import React, {
    useEffect, useMemo, useRef, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { uuidv4 } from "../../../helpers/utils";
import {
    addToHistory, clearHistory, setCurrentTemplate
} from "../../../redux/slices/editorSlice";
import ContentEditable from "../parts/ContentEditable";

const Template = ({ template: templateFromProps = {}, isEditable = false }) => {
    const templateId = useRef(`template-${uuidv4()}`);
    const dispatch = useDispatch();
    const { currentTemplate, history } = useSelector((state) => state.editor);
    const [template, setTemplate] = useState(templateFromProps);

    useEffect(() => {
        if (isEditable) {
            dispatch(addToHistory(templateFromProps));
            dispatch(setCurrentTemplate(templateFromProps));
        }

        return () => dispatch(clearHistory());
    }, [templateFromProps]);
    useEffect(() => {
        if (isEditable) {
            setTemplate(currentTemplate);
        }
    }, [currentTemplate]);

    const buildNestedBlocks = (blocks) => {
        if (!template.blocks) return;

        const blockMap = {};
        const roots = [];

        blocks.forEach((block) => {
            blockMap[block.id] = {
                ...block,
                children: []
            };
        });
        blocks.forEach((block) => {
            if (block.appendTo) {
                const parentId = block.appendTo;

                if (blockMap[parentId]) {
                    blockMap[parentId].children.push(blockMap[block.id]);
                }
            } else {
                roots.push(blockMap[block.id]);
            }
        });

        return roots;
    };

    const nestedBlocks = useMemo(() => buildNestedBlocks(template.blocks), [template]);

    if (!template.blocks) return;

    const renderBlock = (block, index) => {
        const blockId = `${block.id}-${templateId.current}`;
        const blockStyle = block.id === "card-editor"
            ? {
                ...block.attributes.style,
                position: "relative",
                height: "490px",
                width: "648px",
                transformOrigin: "top center",
                overflow: "hidden"
            }
            : block.attributes.style;
        const editableIndex = template.blocks.findIndex((item) => item.id === block.id);

        return (
            <React.Fragment key={index}>
                {block.tagName === "p"
                    ? (
                        <ContentEditable
                            id={blockId}
                            isEditable={isEditable}
                            index={editableIndex}
                            block={block}
                        >
                            <span dangerouslySetInnerHTML={{ __html: block.content }} />
                        </ContentEditable>
                    )


                    : React.createElement(block.tagName, {
                        id: blockId,
                        ...block.attributes,
                        style: blockStyle
                    }, block.tagName !== "img"
                        ? block.children.map((childBlock, childIndex) => renderBlock(childBlock, childIndex))
                        : null)}
            </React.Fragment>
        );
    };

    if (!nestedBlocks) return;

    return (
        <>
            {nestedBlocks.map((block, index) => renderBlock(block, index))}
        </>
    );
};

export default Template;
