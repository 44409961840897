const base = "api/reviews";

export const reviews = (instance) => ({
    async getReviews(userId) {
        const { data } = await instance.get(`${base}/by-user/${userId}`);

        return data;
    },
    async createReview(body) {
        const { data } = await instance.post(`${base}`, body);

        return data;
    },
    async hideReview(reviewId) {
        const { data } = await instance.get(`${base}/hide-review/${reviewId}`);

        return data;
    },
});
